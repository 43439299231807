<script lang="ts" setup></script>

<template>
	<svg
		fill="none"
		viewBox="0 0 400 110"
	>
		<path
			class="fill-current"
			d="M40.6506 29.8859V95.9037H22.1135V29.8839H0V13.3023H63.0886V29.8879H40.6486L40.6506 29.8859ZM115.773 38.341C115.773 32.162 113.497 29.5613 105.691 29.5613H91.0565V53.9509H105.691C113.497 53.9509 115.773 51.0256 115.773 44.8446V38.341ZM102.114 70.2119H91.0565V95.9017H72.5194V13.3023H106.991C127.155 13.3023 134.634 21.1083 134.634 36.7181V46.7981C134.634 57.2047 131.056 64.0349 122.601 67.6113L140.813 95.9037H118.7L102.114 70.2119ZM152.845 13.3003H171.382V95.9017H152.845V13.3003ZM381.463 38.9922C381.463 32.4886 377.235 28.5856 368.129 28.5856C358.698 28.5856 354.795 32.4886 354.795 38.9922V70.2119C354.795 76.7155 359.023 80.6185 368.129 80.6185C377.56 80.6185 381.463 76.7155 381.463 70.2119V38.9922ZM400 71.8368C400 84.5194 391.218 97.2021 368.129 97.2021C344.715 97.2021 336.26 84.5194 336.26 71.8368V37.3653C336.26 24.6826 345.04 12 368.129 12C391.543 12 400 24.6826 400 37.3653V71.8368ZM200.975 95.9017H182.113L203.251 62.406L212.682 77.0401L200.975 95.9017ZM203.251 46.7961L182.113 13.3023H200.975L212.682 31.8394L203.251 46.7961ZM253.332 95.9057H234.471L208.131 54.6L234.471 13.3003H253.332L226.991 54.602L253.332 95.9017"
		/>
		<path
			class="fill-lima"
			d="M279.998 95.901H261.137L282.274 62.4053L291.705 77.0394L279.998 95.901ZM282.274 46.7954L261.137 13.3016H279.998L291.705 31.8387L282.274 46.7954ZM332.356 95.905H313.494L287.151 54.5993L313.494 13.2996H332.356L306.015 54.6013L332.356 95.901"
		/>
	</svg>
</template>
