<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="fill-current"
			d="M16.754 11C16.9516 11.0008 17.1446 11.0602 17.3085 11.1705C17.4724 11.2809 17.6 11.4373 17.675 11.6201C17.7501 11.8029 17.7693 12.0038 17.7303 12.1976C17.6913 12.3913 17.5957 12.5691 17.4557 12.7085C17.3157 12.848 17.1375 12.9428 16.9436 12.9811C16.7498 13.0193 16.5489 12.9993 16.3664 12.9235C16.1839 12.8477 16.028 12.7195 15.9183 12.5552C15.8086 12.3908 15.75 12.1976 15.75 12C15.7493 11.8682 15.7748 11.7375 15.8251 11.6156C15.8753 11.4937 15.9493 11.383 16.0427 11.2899C16.1362 11.1969 16.2472 11.1233 16.3693 11.0736C16.4914 11.0238 16.6222 10.9988 16.754 11Z"
		/>
		<path
			class="fill-current"
			d="M12.004 11C12.2016 11.0008 12.3946 11.0602 12.5585 11.1705C12.7224 11.2809 12.85 11.4373 12.925 11.6201C13.0001 11.8029 13.0193 12.0038 12.9803 12.1976C12.9413 12.3913 12.8457 12.5691 12.7057 12.7085C12.5657 12.848 12.3875 12.9428 12.1936 12.9811C11.9998 13.0193 11.7989 12.9993 11.6164 12.9235C11.4339 12.8477 11.278 12.7195 11.1683 12.5552C11.0586 12.3908 11 12.1976 11 12C10.9993 11.8682 11.0248 11.7375 11.0751 11.6156C11.1253 11.4937 11.1993 11.383 11.2927 11.2899C11.3862 11.1969 11.4972 11.1233 11.6193 11.0736C11.7414 11.0238 11.8722 10.9988 12.004 11Z"
		/>
		<path
			class="fill-current"
			d="M7.004 11C7.20161 11.0008 7.39456 11.0602 7.55848 11.1705C7.7224 11.2809 7.84995 11.4373 7.92503 11.6201C8.0001 11.8029 8.01933 12.0038 7.98029 12.1976C7.94126 12.3913 7.84571 12.5691 7.7057 12.7085C7.56569 12.848 7.3875 12.9428 7.19363 12.9811C6.99976 13.0193 6.7989 12.9993 6.61641 12.9235C6.43392 12.8477 6.27797 12.7195 6.16827 12.5552C6.05856 12.3908 6.00002 12.1976 6.00001 12C5.99931 11.8682 6.02482 11.7375 6.07508 11.6156C6.12533 11.4937 6.19932 11.383 6.29275 11.2899C6.38617 11.1969 6.49716 11.1233 6.61927 11.0736C6.74137 11.0238 6.87215 10.9988 7.004 11Z"
		/>

		<defs>
			<clipPath id="clip0_1366_9203">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
