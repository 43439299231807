<template>
	<svg
		fill="none"
		viewBox="0 0 20 20"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#clip0_2762_7856)">
			<path
				d="M14.1665 17.5H5.83317C4.91234 17.5 4.1665 16.7542 4.1665 15.8333V9.16667C4.1665 8.24583 4.91234 7.5 5.83317 7.5H14.1665C15.0873 7.5 15.8332 8.24583 15.8332 9.16667V15.8333C15.8332 16.7542 15.0873 17.5 14.1665 17.5Z"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M10 14.2423V12.084"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M10.4419 11.017C10.686 11.2611 10.686 11.6568 10.4419 11.9009C10.1979 12.145 9.80214 12.145 9.55806 11.9009C9.31398 11.6568 9.31398 11.2611 9.55806 11.017C9.80214 10.773 10.1979 10.773 10.4419 11.017Z"
				fill="currentColor"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M6.6665 7.5V5.83333C6.6665 3.9925 8.159 2.5 9.99984 2.5C11.8407 2.5 13.3332 3.9925 13.3332 5.83333V7.5"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</g>
	</svg>
</template>
