<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M9.2251 6.45176C9.2251 4.91976 10.4671 3.67676 12.0001 3.67676C13.5321 3.67676 14.7751 4.91876 14.7751 6.45176C14.7751 7.98376 13.5331 9.22676 12.0001 9.22676C10.4671 9.22476 9.2251 7.98276 9.2251 6.45176Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M2.5 17.5479C2.5 16.0159 3.742 14.7729 5.275 14.7729C6.807 14.7729 8.05 16.0149 8.05 17.5479C8.049 19.0799 6.808 20.3229 5.275 20.3229C3.742 20.3229 2.5 19.0809 2.5 17.5479Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M15.9512 17.5479C15.9512 16.0159 17.1932 14.7729 18.7262 14.7729C20.2582 14.7729 21.5012 16.0149 21.5012 17.5479C21.5012 19.0799 20.2592 20.3229 18.7262 20.3229C17.1922 20.3229 15.9512 19.0809 15.9512 17.5479Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M8.48998 11.9199L6.47998 15.0599"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M13.5 8.77979L15.51 11.9198"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M15.95 17.5498H12"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_9232">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
