<script setup lang="ts"></script>

<template>
	<svg
		fill="none"
		viewBox="0 0 32 32"
	>
		<path
			class="fill-current"
			d="M18.0398 9H20.8466V16.5057C20.8466 17.3731 20.6402 18.1288 20.2273 18.7727C19.8144 19.4129 19.2386 19.9091 18.5 20.2614C17.7614 20.6098 16.9034 20.7841 15.9261 20.7841C14.9375 20.7841 14.0739 20.6098 13.3352 20.2614C12.5966 19.9091 12.0227 19.4129 11.6136 18.7727C11.2045 18.1288 11 17.3731 11 16.5057V9H13.8125V16.2614C13.8125 16.6629 13.8996 17.0208 14.0739 17.3352C14.2519 17.6496 14.5 17.8958 14.8182 18.0739C15.1364 18.2519 15.5057 18.3409 15.9261 18.3409C16.3466 18.3409 16.714 18.2519 17.0284 18.0739C17.3466 17.8958 17.5947 17.6496 17.7727 17.3352C17.9508 17.0208 18.0398 16.6629 18.0398 16.2614V9Z"
		/>
		<path
			class="stroke-current"
			d="M10 23H21.5"
		/>
	</svg>
</template>
