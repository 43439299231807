<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M14.1569 7.88916C13.2945 8.78676 11.9369 8.99956 11.2793 8.99956"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M11.2792 8.99961C11.2792 8.99961 10.884 6.50681 11.856 5.49561"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M14.1569 7.8892C14.7921 7.2284 14.7921 6.1564 14.1569 5.4956C13.5217 4.8348 12.4921 4.8348 11.8569 5.4956"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M8.24316 7.88916C9.10556 8.78676 10.4632 8.99956 11.1208 8.99956"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M11.1207 8.99961C11.1207 8.99961 11.5159 6.50681 10.5439 5.49561"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M8.24349 7.8892C7.60829 7.2284 7.60829 6.1564 8.24349 5.4956C8.87869 4.8348 9.90829 4.8348 10.5435 5.4956"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M16.8001 12.1997V18.5997C16.8001 19.0413 16.4417 19.3997 16.0001 19.3997H6.4001C5.9585 19.3997 5.6001 19.0413 5.6001 18.5997V12.1997"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			clip-rule="evenodd"
			d="M17.6 9H4.8C4.3584 9 4 9.3584 4 9.8V11.4C4 11.8416 4.3584 12.2 4.8 12.2H17.6C18.0416 12.2 18.4 11.8416 18.4 11.4V9.8C18.4 9.3584 18.0416 9 17.6 9Z"
			fill-rule="evenodd"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M11.2002 19.4V9"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
