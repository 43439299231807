<script setup lang="ts"></script>
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="fill-current"
			d="M19.25 12C19.25 12.69 18.69 13.25 18 13.25C17.67 13.25 17.36 13.13 17.12 12.89C16.88 12.65 16.75 12.34 16.75 12C16.75 11.31 17.31 10.75 18 10.75C18.69 10.75 19.25 11.31 19.25 12Z"
		/>
		<path
			class="fill-current"
			d="M13.25 12C13.25 12.69 12.69 13.25 12 13.25C11.67 13.25 11.36 13.13 11.12 12.89C10.88 12.65 10.75 12.34 10.75 12C10.75 11.31 11.31 10.75 12 10.75C12.69 10.75 13.25 11.31 13.25 12Z"
		/>
		<path
			class="fill-current"
			d="M7.25 12C7.25 12.69 6.69 13.25 6 13.25C5.67 13.25 5.36 13.13 5.12 12.89C4.88 12.65 4.75 12.34 4.75 12C4.75 11.31 5.31 10.75 6 10.75C6.69 10.75 7.25 11.31 7.25 12Z"
		/>
	</svg>
</template>
