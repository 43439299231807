<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M16 3H8C5.23858 3 3 5.23858 3 8V16C3 18.7614 5.23858 21 8 21H16C18.7614 21 21 18.7614 21 16V8C21 5.23858 18.7614 3 16 3Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M20.9084 15.9942L18.2071 13.2929C18.0195 13.1054 17.7652 13 17.4999 13C17.2347 13 16.9804 13.1054 16.7928 13.2929L14.5 15.5858L10.2071 11.2929C10.0195 11.1054 9.76517 11 9.49995 11C9.23474 11 8.98039 11.1054 8.79285 11.2929L3.3125 16.7732"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="fill-current"
			d="M14.284 7.30194C14.4695 7.11256 14.7243 7.00286 14.9894 7.00006C15.2545 6.99725 15.5099 7.09983 15.6994 7.28524C15.8889 7.47065 15.997 7.72372 15.9999 7.98881C16.0029 8.25391 15.9005 8.50933 15.7152 8.69894C15.5299 8.88854 15.2769 8.9968 15.0118 8.99993C14.7467 9.00306 14.4912 8.90079 14.3015 8.71562C14.1118 8.53044 14.0034 8.2775 14.0001 8.01241C13.9968 7.74732 14.0989 7.49177 14.284 7.30194Z"
		/>
	</svg>
</template>
