<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M8.25 11.5H6.25"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M17.8799 8.28998L19.9999 7.22998"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M5.12 8.28998L3 7.22998"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M17.9498 8.65977L16.9898 4.54977C16.7798 3.64977 15.9698 3.00977 15.0398 3.00977H7.9598C7.0298 3.00977 6.2198 3.64977 6.0098 4.54977L5.0498 8.65977"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M20 12V11.5C20 9.84 18.66 8.5 17 8.5H6C4.34 8.5 3 9.84 3 11.5V16C3 16.55 3.45 17 4 17H6.24C6.79 17 7.24 16.55 7.24 16V14.5H11.98"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M14.75 11.5H16.75"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M13.6299 17.8398H17.6299"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M18.9402 20.87C17.7302 21.4 16.2702 21.18 15.2802 20.19C13.9802 18.89 13.9802 16.78 15.2802 15.48C16.2702 14.49 17.7302 14.27 18.9402 14.8"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_9299">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
