<script lang="ts" setup></script>

<template>
	<svg
		fill="none"
		viewBox="0 0 12 12"
	>
		<path
			class="fill-vivid-red"
			d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z"
		/>
		<path
			d="M4.44434 4.44421L7.55558 7.55545"
			stroke="white"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="1.5"
		/>
		<path
			d="M7.55558 4.44421L4.44434 7.55545"
			stroke="white"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="1.5"
		/>
	</svg>
</template>
