<script setup lang="ts"></script>

<template>
	<svg
		fill="none"
		viewBox="0 0 32 32"
	>
		<path
			class="fill-current"
			d="M25 23V9C25 7.9 24.1 7 23 7H9C7.9 7 7 7.9 7 9V23C7 24.1 7.9 25 9 25H23C24.1 25 25 24.1 25 23ZM12.5 17.5L15 20.51L18.5 16L23 22H9L12.5 17.5Z"
		/>
	</svg>
</template>
