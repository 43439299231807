<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M10.0801 17.0044V17.4352C10.0801 18.5773 11.0101 19.5091 12.1501 19.5091C13.2901 19.5091 14.2201 18.5773 14.2201 17.4352V17.0044"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M17.0998 17.0044C18.0398 17.0044 18.8098 16.243 18.8098 15.2912C18.8098 14.8103 18.6198 14.3494 18.2798 14.0088L17.1498 12.8767V9.50035C17.1498 6.73517 14.9198 4.50098 12.1598 4.50098C9.39977 4.50098 7.16977 6.73517 7.16977 9.50035V12.8767L6.03977 14.0088C5.69977 14.3494 5.50977 14.8103 5.50977 15.2912C5.50977 16.233 6.26977 17.0044 7.21977 17.0044H17.1098H17.0998Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_8983">
				<rect fill="white" />
			</clipPath>
			<clipPath id="clip1_1366_8983">
				<rect
					fill="white"
					height="16"
					transform="translate(5 4)"
					width="14.31"
				/>
			</clipPath>
		</defs>
	</svg>
</template>
