<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M17.657 6.34277C20.781 9.46677 20.781 14.5328 17.657 17.6568C14.533 20.7808 9.467 20.7808 6.343 17.6568C3.219 14.5328 3.219 9.46677 6.343 6.34277"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M12 4V12"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_9322">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
