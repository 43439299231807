<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M4 12H20"
			stroke-linecap="round"
		/>
	</svg>
</template>
