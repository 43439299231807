<script lang="ts" setup>
defineProps<{
	strokeWidth?: number
}>()

const DEFAULT_STROKE_WIDTH = 1
</script>

<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M18.364 5.63574C19.6226 6.89441 20.4798 8.49806 20.8271 10.2439C21.1743 11.9897 20.9961 13.7993 20.3149 15.4438C19.6337 17.0884 18.4802 18.494 17.0001 19.4829C15.5201 20.4719 13.78 20.9997 12 20.9997C10.22 20.9997 8.47991 20.4719 6.99987 19.4829C5.51983 18.494 4.36628 17.0884 3.68509 15.4438C3.0039 13.7993 2.82567 11.9897 3.17294 10.2439C3.5202 8.49806 4.37737 6.89441 5.63604 5.63574"
			stroke-linecap="round"
			stroke-linejoin="round"
			:stroke-width="strokeWidth ?? DEFAULT_STROKE_WIDTH"
		/>
		<path
			class="stroke-current"
			d="M12 4V13"
			stroke-linecap="round"
			stroke-linejoin="round"
			:stroke-width="strokeWidth ?? DEFAULT_STROKE_WIDTH"
		/>
		<path
			class="stroke-current"
			d="M9.00049 10L12.0005 13L15.0005 10"
			stroke-linecap="round"
			stroke-linejoin="round"
			:stroke-width="strokeWidth ?? DEFAULT_STROKE_WIDTH"
		/>
	</svg>
</template>
