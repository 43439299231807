<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M18.7983 10.6201C17.0312 10.6201 15.5967 12.0546 15.5967 13.8218C15.5967 15.5889 17.0312 17.0234 18.7983 17.0234C20.5655 17.0234 22 15.5889 22 13.8218C22 12.0546 20.5655 10.6201 18.7983 10.6201Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M5.20166 10.6202C3.43451 10.6202 2 12.0478 2 13.8149C2 15.5821 3.43451 17.0166 5.20166 17.0166C6.9688 17.0166 8.40331 15.5821 8.40331 13.8149C8.40331 12.0478 6.9688 10.6133 5.20166 10.6133V10.6202Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M13.1226 6.23562L15.0352 6L18.7982 13.8149"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M3.45215 8.36096L7.39575 6.38916L11.6745 8.49467L15.8394 8.50853"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M19.2347 7.85034C18.9506 7.7949 18.6664 7.76025 18.3685 7.76025C16.9339 7.76025 15.9984 8.03052 14.8757 9.54126C13.7531 11.052 12.3879 12.7706 12.3879 12.7706L8.08435 8.86212C6.96169 8.01666 6.34492 7.91964 5.30542 7.91964C4.67479 7.91964 4.07188 8.05131 3.52441 8.28"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M5.20166 13.8151L11.2585 11.7383"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
