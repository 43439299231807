import axios from 'axios'

import { OAuth2VueClient } from './oAuth2VueClient'

const VITE_CLIENT_ID = import.meta.env.VITE_CLIENT_ID
const VITE_CLIENT_SECRET = import.meta.env.VITE_CLIENT_SECRET
const API_BASE_URL = import.meta.env.VITE_BASE_URL

export const oAuthClient = new OAuth2VueClient({
	axios: axios,
	clientId: VITE_CLIENT_ID,
	clientSecret: VITE_CLIENT_SECRET,
	tokenEndpoint: `${API_BASE_URL}/auth/token`,
})
