import type { RouteRecordRaw } from 'vue-router'

import { Permission } from '@/constants/permission.enum'
import { permissionMiddleware } from '@/middleware/permission.middleware'
import ROUTE_NAME from '@/router/routeName'

export const todosRoutes: RouteRecordRaw[] = [
	{
		children: [
			{
				component: async () => import('@/modules/todos/views/TodosPersonalView.vue'),
				name: ROUTE_NAME.TODOS.PERSONAL,
				path: 'personal',
			},
			{
				component: async () => import('@/modules/todos/views/TodosFollowUpView.vue'),
				name: ROUTE_NAME.TODOS.FOLLOW_UP,
				path: 'follow-up',
			},
		],
		component: async () => import('@/modules/todos/views/TodosView.vue'),
		name: ROUTE_NAME.TODOS.INDEX,
		path: '/todos',
		redirect: { name: ROUTE_NAME.TODOS.PERSONAL },
		beforeEnter: permissionMiddleware([Permission.TODO_READ]),
	},
]
