<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M4.5 7.98C4.5 8.47 4.74 8.93 5.15 9.21L10.12 12.58C11.25 13.34 12.73 13.34 13.85 12.58L18.81 9.22C19.22 8.95 19.46 8.49 19.46 7.99C19.46 7.16 18.8 6.5 17.98 6.5H5.98C5.16 6.5 4.5 7.16 4.5 7.98Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M4.5 8.16016V16.4802C4.5 17.4002 5.24 18.1402 6.16 18.1402H17.8C18.72 18.1402 19.46 17.4002 19.46 16.4802V8.16016"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M4.99023 17.6598L10.1002 12.5498"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M13.8799 12.5601L18.9799 17.6601"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_8964">
				<rect fill="white" />
			</clipPath>
			<clipPath id="clip1_1366_8964">
				<rect
					fill="white"
					height="12.64"
					transform="translate(4 6)"
					width="15.97"
				/>
			</clipPath>
		</defs>
	</svg>
</template>
