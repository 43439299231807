<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M19.168 8.21338H4"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="fill-lima stroke-lima"
			d="M16.2134 12.4268C18.3041 12.4268 20.0001 14.1228 20.0001 16.2134C20.0001 18.3041 18.3041 20.0001 16.2134 20.0001C14.1228 20.0001 12.4268 18.3041 12.4268 16.2134C12.4268 14.1228 14.1228 12.4268 16.2134 12.4268Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M13.8347 19.168H6.528C5.13067 19.168 4 18.0373 4 16.64V6.528C4 5.13067 5.13067 4 6.528 4H16.64C18.0373 4 19.168 5.13067 19.168 6.528V13.8347"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M16.2129 14.5811V16.2557"
			stroke="white"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M14.5386 16.2559H16.2132"
			stroke="white"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M16.2129 17.9305V16.2559"
			stroke="white"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M17.8876 16.2559H16.2129"
			stroke="white"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M7.20008 11.1569C7.20008 11.1569 7.17875 11.1996 7.15741 11.1996C7.13608 11.1996 7.11475 11.1783 7.11475 11.1569C7.11475 11.1356 7.13608 11.1143 7.15741 11.1143"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M7.15723 11.1143C7.15723 11.1143 7.19989 11.1356 7.19989 11.1569"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M10.1547 11.1569C10.1547 11.1569 10.1333 11.1996 10.112 11.1996C10.0907 11.1996 10.0693 11.1783 10.0693 11.1569C10.0693 11.1356 10.0907 11.1143 10.112 11.1143"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M10.1118 11.1143C10.1118 11.1143 10.1545 11.1356 10.1545 11.1569"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M7.20008 13.6852C7.20008 13.6852 7.17875 13.7279 7.15741 13.7279C7.13608 13.7279 7.11475 13.7066 7.11475 13.6852C7.11475 13.6639 7.13608 13.6426 7.15741 13.6426"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M7.15723 13.6426C7.15723 13.6426 7.19989 13.6639 7.19989 13.6852"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M10.1547 13.6852C10.1547 13.6852 10.1333 13.7279 10.112 13.7279C10.0907 13.7279 10.0693 13.7066 10.0693 13.6852C10.0693 13.6639 10.0907 13.6426 10.112 13.6426"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M10.1118 13.6426C10.1118 13.6426 10.1545 13.6639 10.1545 13.6852"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M13.099 11.1569C13.099 11.1569 13.0777 11.1996 13.0563 11.1996C13.035 11.1996 13.0137 11.1783 13.0137 11.1569C13.0137 11.1356 13.035 11.1143 13.0563 11.1143"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M13.0562 11.1143C13.0562 11.1143 13.0988 11.1356 13.0988 11.1569"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M7.20008 16.2136C7.20008 16.2136 7.17875 16.2562 7.15741 16.2562C7.13608 16.2562 7.11475 16.2349 7.11475 16.2136C7.11475 16.1922 7.13608 16.1709 7.15741 16.1709"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M7.15723 16.1709C7.15723 16.1709 7.19989 16.1922 7.19989 16.2136"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M10.1547 16.2136C10.1547 16.2136 10.1333 16.2562 10.112 16.2562C10.0907 16.2562 10.0693 16.2349 10.0693 16.2136C10.0693 16.1922 10.0907 16.1709 10.112 16.1709"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M10.1118 16.1709C10.1118 16.1709 10.1545 16.1922 10.1545 16.2136"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
