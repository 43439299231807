<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M15.85 16.062H8.45996"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M17.4199 13.562C17.1901 13.562 16.9625 13.6073 16.7502 13.6952C16.5379 13.7832 16.345 13.9121 16.1825 14.0746C16.02 14.2371 15.8911 14.43 15.8031 14.6423C15.7152 14.8546 15.6699 15.0822 15.6699 15.312C15.6699 15.5418 15.7152 15.7694 15.8031 15.9817C15.8911 16.194 16.02 16.3869 16.1825 16.5494C16.345 16.712 16.5379 16.8409 16.7502 16.9288C16.9625 17.0167 17.1901 17.062 17.4199 17.062C17.6497 17.062 17.8773 17.0167 18.0896 16.9288C18.3019 16.8409 18.4949 16.712 18.6574 16.5494C18.8199 16.3869 18.9488 16.194 19.0367 15.9817C19.1247 15.7694 19.1699 15.5418 19.1699 15.312C19.1699 15.0822 19.1247 14.8546 19.0367 14.6423C18.9488 14.43 18.8199 14.2371 18.6574 14.0746C18.4949 13.9121 18.3019 13.7832 18.0896 13.6952C17.8773 13.6073 17.6497 13.562 17.4199 13.562Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M6.70996 13.562C6.48015 13.562 6.25258 13.6073 6.04026 13.6952C5.82794 13.7832 5.63503 13.9121 5.47252 14.0746C5.31002 14.2371 5.18112 14.43 5.09317 14.6423C5.00523 14.8546 4.95996 15.0822 4.95996 15.312C4.95996 15.5418 5.00523 15.7694 5.09317 15.9817C5.18112 16.194 5.31002 16.3869 5.47252 16.5494C5.63503 16.712 5.82794 16.8409 6.04026 16.9288C6.25258 17.0167 6.48015 17.062 6.70996 17.062C6.93977 17.062 7.16734 17.0167 7.37966 16.9288C7.59198 16.8409 7.7849 16.712 7.9474 16.5494C8.1099 16.3869 8.2388 16.194 8.32675 15.9817C8.4147 15.7694 8.45996 15.5418 8.45996 15.312C8.45996 15.0822 8.4147 14.8546 8.32675 14.6423C8.2388 14.43 8.1099 14.2371 7.9474 14.0746C7.7849 13.9121 7.59198 13.7832 7.37966 13.6952C7.16734 13.6073 6.93977 13.562 6.70996 13.562Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M19.0001 16.0523H20.2301C20.9601 16.0423 21.5901 15.5013 21.7001 14.7713L22.0301 12.5813V12.5713C22.1901 11.5413 21.5401 10.5713 20.5401 10.3113C19.0401 9.95125 17.4901 9.79125 15.9501 9.83125C15.0501 9.04125 14.0601 8.37125 12.9901 7.84125C10.7701 6.72125 8.16011 6.72125 5.94011 7.83125L3.68011 8.94225V8.93225C3.36011 9.08225 3.03011 9.21225 2.69011 9.32225V9.31225C2.28011 9.44225 2.00011 9.82225 2.01011 10.2523V14.4523H2.00011C1.99011 15.2723 2.66011 15.9423 3.49011 15.9423H5.04011"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_8939">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
