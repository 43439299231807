<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<g clip-path="url(#clip0_2281_5135)">
			<path
				class="stroke-current"
				d="M13.5 13L10.5 10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				class="stroke-current"
				d="M13.5 10L10.5 13"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				class="stroke-current"
				d="M4 11.138V6.87505C4 6.00027 4.56852 5.22695 5.40349 4.96604L11.4035 3.09104C11.7919 2.96965 12.2082 2.96965 12.5966 3.09104L18.5966 4.96604C19.4315 5.22698 20 6.00027 20 6.87505V11.1381C20 15.3065 17.4143 19.0377 13.5112 20.5013L12.3511 20.9363C12.1247 21.0212 11.8753 21.0212 11.6489 20.9363L10.4888 20.5013C6.58573 19.0377 4 15.3065 4 11.138Z"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</g>
	</svg>
</template>
