<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M8.80851 14.0852C8.80851 15.9576 7.2766 17.4895 5.40426 17.4895C3.53191 17.4895 2 15.9576 2 14.0852C2 12.2129 3.5234 10.681 5.40426 10.681H5.39574C5.98298 10.6725 6.5617 10.8342 7.08085 11.132"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M17.3193 10.6807C15.4384 10.6807 13.915 12.2041 13.915 14.0849C13.915 15.9658 15.4384 17.4892 17.3193 17.4892C19.2001 17.4892 20.7235 15.9658 20.7235 14.0849C20.7235 12.2041 19.2001 10.6807 17.3193 10.6807Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M17.532 8.55316H17.5235C18.1107 8.55316 18.5873 8.07657 18.5873 7.48933C18.5873 6.88508 18.1107 6.41699 17.5235 6.41699H14.749L17.3022 14.0766"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M5.4043 14.0854H10.5107L14.766 8.12793H8.80855L5.4043 14.0854Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M7.10645 6H9.65964"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M10.5107 14.0851L7.95752 6"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_8930">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
