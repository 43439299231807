<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M12.5 18L8.80839 20.8926C8.73459 20.9505 8.64602 20.9864 8.5528 20.9963C8.45957 21.0062 8.36543 20.9897 8.28114 20.9486C8.19684 20.9076 8.12578 20.8437 8.07607 20.7642C8.02636 20.6847 8 20.5928 8 20.4991V18H6C5.20435 18 4.44129 17.6839 3.87868 17.1213C3.31607 16.5587 3 15.7956 3 15V6C3 5.20435 3.31607 4.44129 3.87868 3.87868C4.44129 3.31607 5.20435 3 6 3H18C18.7956 3 19.5587 3.31607 20.1213 3.87868C20.6839 4.44129 21 5.20435 21 6V15C21 15.7956 20.6839 16.5587 20.1213 17.1213C19.5587 17.6839 18.7956 18 18 18H12.5Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M12 14.0003C11.1179 14.0101 10.248 13.7939 9.4731 13.3723C8.69822 12.9507 8.04414 12.3378 7.57314 11.592L7.54224 11.5413C7.35111 11.2277 7.25 10.8675 7.25 10.5003C7.25 10.1331 7.35111 9.77294 7.54224 9.45937L7.57314 9.40868C8.04414 8.66279 8.69822 8.04989 9.4731 7.62832C10.248 7.20676 11.1179 6.99056 12 7.00032"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M12 7.00032C12.8821 6.99056 13.752 7.20676 14.5269 7.62832C15.3018 8.04989 15.9558 8.66279 16.4268 9.40868L16.4577 9.45936C16.6489 9.77293 16.75 10.1331 16.75 10.5003C16.75 10.8675 16.6489 11.2277 16.4577 11.5413L16.4268 11.592C15.9558 12.3378 15.3018 12.9507 14.5269 13.3723C13.752 13.7939 12.8821 14.0101 12 14.0003"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="fill-current"
			d="M11.9961 11.5C11.7985 11.4992 11.6056 11.4399 11.4416 11.3296C11.2777 11.2192 11.1501 11.0628 11.075 10.88C10.9999 10.6972 10.9807 10.4963 11.0197 10.3025C11.0587 10.1088 11.1542 9.931 11.2942 9.79154C11.4342 9.65207 11.6124 9.55721 11.8063 9.51894C12.0002 9.48066 12.201 9.50069 12.3835 9.57648C12.566 9.65228 12.722 9.78045 12.8317 9.94482C12.9414 10.1092 13 10.3024 13 10.5C13.0007 10.6318 12.9752 10.7625 12.9249 10.8844C12.8747 11.0063 12.8007 11.117 12.7073 11.21C12.6139 11.3031 12.5029 11.3766 12.3808 11.4264C12.2587 11.4762 12.128 11.5012 11.9961 11.5Z"
		/>

		<defs>
			<clipPath id="clip0_1366_9261">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
