import './assets/css/style.css'

import countries from 'i18n-iso-countries'
import french from 'i18n-iso-countries/langs/fr.json'
import dutch from 'i18n-iso-countries/langs/nl.json'
import { createApp } from 'vue'
import VueMatomo from 'vue-matomo'

import { i18nPlugin } from '@/plugins/i18n.plugin'
import { piniaPlugin } from '@/plugins/pinia.plugin'
import { primeVuePlugin } from '@/plugins/primeVue.plugin'
import { useSentryIntegration } from '@/plugins/sentry.plugin'
import { VueQueryPlugin, vueQueryPluginOptions } from '@/plugins/vueQuery.plugin'
import router from '@/router/appRouter.composable'

import App from './App.vue'
import { toastPlugin, toastPluginConfig } from './plugins/toast.plugin'

const app = createApp(App)

useSentryIntegration(app)

app.use(i18nPlugin)
app.use(router)
app.use(toastPlugin, toastPluginConfig)
app.use(primeVuePlugin)
app.use(piniaPlugin)
app.use(VueQueryPlugin, vueQueryPluginOptions)

const VITE_MATOMO_URL = import.meta.env.VITE_MATOMO_URL

if (VITE_MATOMO_URL) {
	app.use(VueMatomo, {
		host: import.meta.env.VITE_MATOMO_URL,
		siteId: 1,
		router: router,
	})
}

countries.registerLocale(french)
countries.registerLocale(dutch)

app.mount('#app')
