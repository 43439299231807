<script lang="ts" setup></script>

<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<g clip-path="url(#clip0_1944_4331)">
			<path
				class="stroke-current"
				d="M6.5 6.5H9.14"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				class="stroke-current"
				d="M6.5 10H10.02"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				class="stroke-current"
				d="M6.5 13.5H10.9"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				class="stroke-current"
				d="M17.4999 13.5H14.3999"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				class="stroke-current"
				d="M17.4999 10H13.8999"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				class="stroke-current"
				d="M11.182 20.718L14.193 17.707C14.638 17.262 14.848 16.633 14.759 16.01L13.145 4.717C13.004 3.732 12.16 3 11.165 3H5C3.895 3 3 3.895 3 5V15C3 16.105 3.895 17 5 17H14.658"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				class="stroke-current"
				d="M9.8999 17L10.2259 19.283C10.3659 20.268 11.2099 21 12.2059 21H18.9999C20.1049 21 20.9999 20.105 20.9999 19V8C20.9999 6.895 20.1049 6 18.9999 6H13.8999"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</g>
	</svg>
</template>
