<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M4 10.7899L10.61 4.77992C11.12 4.29992 11.87 4.29992 12.38 4.77992L18.99 10.7799"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M18.1698 19V4.44C18.1698 4.19 17.9798 4 17.7498 4H15.2398C15.0098 4 14.8198 4.19 14.8198 4.44V6.73"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M5.06982 9.81006V18.9901"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M19 19H4"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M10.0098 10.0498V10.9298"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M13.3501 10.0498V10.9298"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M8.64014 15.3797C8.64014 15.3797 9.73014 14.2197 11.5601 14.2197C13.3901 14.2197 14.4801 15.3797 14.4801 15.3797"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_8862">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
