<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<g clip-path="url(#clip0_1904_7328)">
			<path
				class="stroke-current"
				d="M19.5 8.66992H4.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				class="stroke-current"
				d="M17 4.5H7C5.61929 4.5 4.5 5.61929 4.5 7V17C4.5 18.3807 5.61929 19.5 7 19.5H17C18.3807 19.5 19.5 18.3807 19.5 17V7C19.5 5.61929 18.3807 4.5 17 4.5Z"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				class="stroke-current"
				d="M12.0498 14.78L10.7998 16.03L12.0498 17.28"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				class="stroke-current"
				d="M10.81 16.02C11.12 16.06 11.44 16.08 11.75 16.09C14.1 16.09 16 14.97 16 13.59C16 12.21 14.1 11.09 11.75 11.09C10.37 11.01 9.02 11.5 8 12.44"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</g>
	</svg>
</template>
