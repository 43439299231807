<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M7.50928 17.2637H15.1926"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M16.5446 15.9287C15.7961 15.9287 15.1919 16.5329 15.1919 17.2814C15.1919 18.0299 15.7961 18.6341 16.5446 18.6341C17.2931 18.6341 17.8973 18.0299 17.8973 17.2814C17.8973 16.5329 17.2931 15.9287 16.5446 15.9287Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M6.14714 15.9287C5.39864 15.9287 4.79443 16.5329 4.79443 17.2814C4.79443 18.0299 5.39864 18.6341 6.14714 18.6341C6.89564 18.6341 7.49984 18.0299 7.49984 17.2814C7.49984 16.5329 6.89564 15.9287 6.14714 15.9287Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M3.01807 11.4199H20.8557"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M14.7417 11.4201V6.00928"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M8.4292 11.4201V6.00928"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M20.6307 14.1255H19.251"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M4.79459 17.2816H3.78457C3.34269 17.2725 3 16.9299 3 16.497V7.56914C3 6.69439 3.69439 6 4.56012 6H17.2124H17.2034C18.3938 6 19.4399 6.77555 19.7826 7.91182L20.8828 11.5281C20.9549 11.7806 21 12.0421 21 12.3126V15.4329C21 16.4248 20.1884 17.2365 19.1964 17.2365H17.8437"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_8936">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
