<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M12.1111 12C9.91822 12 8.12622 10.2089 8.12622 8.01511C8.12622 5.82133 9.91822 4 12.1111 4C14.304 4 16.096 5.79111 16.096 7.98489C16.096 10.1787 14.304 12 12.1111 12ZM18.3333 20H5.88889C5.4 20 5 19.6 5 19.1111V18.2222C5 16.2667 6.6 14.6667 8.55556 14.6667H15.6667C17.6222 14.6667 19.2222 16.2667 19.2222 18.2222V19.1111C19.2222 19.6 18.8222 20 18.3333 20Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
