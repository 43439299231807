<script lang="ts" setup></script>
<template>
	<svg
		fill="none"
		viewBox="0 0 25 24"
	>
		<path
			class="stroke-current"
			d="M4.5 11.9059V8.0909C4.5 6.8429 5.273 5.7249 6.44 5.2839L14.793 2.1299C16.102 1.6369 17.5 2.6029 17.5 4.0019V7.3519"
			stroke="#87BD25"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M17.2978 15.28C16.9666 15.2824 16.7002 15.5512 16.7002 15.8824C16.7002 16.2136 16.969 16.4824 17.3002 16.48C17.6314 16.48 17.9002 16.2112 17.9002 15.88C17.9002 15.5488 17.6314 15.28 17.2978 15.28Z"
			fill="#87BD25"
		/>
		<path
			class="stroke-current"
			d="M11.162 21.0001H18.5C20.15 21.0001 21.5 19.6501 21.5 18.0001V10.3511C21.5 8.69407 20.157 7.35107 18.5 7.35107H6.5C5.395 7.35107 4.5 8.24607 4.5 9.35107V12.2571"
			stroke="#87BD25"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M8 22C4.963 22 2.5 19.537 2.5 16.5C2.5 13.463 4.963 11 8 11C11.038 11 13.5 13.463 13.5 16.5C13.5 19.537 11.038 22 8 22Z"
			stroke="#87BD25"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M10.4442 15.2781L7.38815 18.3341L5.55615 16.5001"
			stroke="#87BD25"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
