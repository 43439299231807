<script lang="ts" setup></script>
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M13.5641 5.62755C13.2453 5.57774 12.9066 5.54785 12.5778 5.54785H9.70859C6.55044 5.54785 4 8.10825 4 11.2564V11.3162C4 14.4744 6.5604 17.0248 9.70859 17.0248H10.5455C10.5455 17.0248 10.5554 17.0248 10.5554 17.0348V18.8878C10.5554 19.2166 10.8244 19.4856 11.1532 19.4856C11.2528 19.4856 11.3524 19.4557 11.4421 19.4158L15.5567 17.1543C17.5293 16.0684 18.7646 13.9962 18.7646 11.7347C18.7646 11.5254 18.7547 11.3063 18.7347 11.107"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="fill-vivid-red"
			d="M17.5 9C18.8807 9 20 7.88071 20 6.5C20 5.11929 18.8807 4 17.5 4C16.1193 4 15 5.11929 15 6.5C15 7.88071 16.1193 9 17.5 9Z"
		/>

		<defs>
			<clipPath id="clip0_1366_9002">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
