<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M11 15.5H13.31"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M12.1588 15.5V11.25H11.0088"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<circle
			class="fill-current"
			cx="11.9167"
			cy="8.66667"
			r="0.666667"
		/>
		<circle
			class="fill-current"
			cx="11.9167"
			cy="8.66667"
			r="0.666667"
		/>
	</svg>
</template>
