<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M18.4098 16.3501H4.58984"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M19 19L15.98 5.42C15.79 4.59 15.13 4 14.36 4H8.63C7.87 4 7.2 4.59 7.01 5.42L4 19"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M8.62988 4H14.0599C14.7499 4 15.3099 4.63 15.3099 5.41C15.3099 5.52 15.2999 5.64 15.2699 5.75L12.3199 19"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M10.1699 9.99035L10.6399 7.86035"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M9.61978 12.3401C9.56978 12.3401 9.52979 12.3901 9.52979 12.4401C9.52979 12.5001 9.56978 12.5501 9.61978 12.5401C9.66978 12.5301 9.70978 12.4901 9.70978 12.4301C9.70978 12.3701 9.66978 12.3301 9.61978 12.3301"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_8865">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
