<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M10.6504 7.13281L7.57535 9.43422L7 13.2169"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M7.81104 21.0001L10.2202 16.7988L11.0232 12.1568L10.6499 7.13281L14.3003 7.74122L12.4751 14.4337"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M14.3013 7.74121L15.5181 12L17.9517 13.8253"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M13.1 5.72252C13.8518 5.72252 14.4613 5.11306 14.4613 4.36126C14.4613 3.60946 13.8518 3 13.1 3C12.3482 3 11.7388 3.60946 11.7388 4.36126C11.7388 5.11306 12.3482 5.72252 13.1 5.72252Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M14.667 21L13.9619 16.8673L11.0239 12.1567"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
