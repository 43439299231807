<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M19.5 16.4038V19.5H16.4038L12.3565 15.3854L12 15.023L11.6435 15.3854L7.5962 19.5H4.5V16.4038L8.61464 12.3565L8.97702 12L8.61464 11.6435L4.5 7.5962V4.5H7.5962L11.6435 8.61464L12 8.97702L12.3565 8.61464L16.4038 4.5H19.5V7.5962L15.3854 11.6435L15.023 12L15.3854 12.3565L19.5 16.4038Z"
		/>
	</svg>
</template>
