<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M5.55796 9.76295L5.27096 8.69596C4.98196 7.62696 5.61696 6.52795 6.68696 6.24295L16.73 3.56695C17.79 3.28495 18.89 3.91996 19.176 4.97896L20.93 11.488C21.218 12.557 20.582 13.656 19.514 13.941L17.4 14.504"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M5 20.5002H15.4C16.505 20.5002 17.4 19.6052 17.4 18.5002V11.7632C17.4 10.6582 16.505 9.76318 15.4 9.76318H5C3.895 9.76318 3 10.6582 3 11.7632V18.5002C3 19.6052 3.896 20.5002 5 20.5002Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M3 13.8398H17.4"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_9247">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
