<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M12 3C16.971 3 21 7.029 21 12C21 16.971 16.971 21 12 21"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M8.9248 20.4491C7.9288 20.0861 7.0128 19.5601 6.2168 18.8921"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M6.2168 5.10778C7.0128 4.43878 7.9288 3.91278 8.9248 3.55078"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M3.14404 10.4379C3.32904 9.38493 3.69504 8.39593 4.21104 7.50293"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M3.14404 13.561C3.32904 14.614 3.69504 15.603 4.21104 16.496"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M11.7178 7.98584V12.6368L15.3738 14.8658"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_9255">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
