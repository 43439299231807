<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M3 16.792C5.20914 16.792 7 18.5829 7 20.792C7 18.5829 8.79086 16.792 11 16.792C8.79086 16.792 7 15.0011 7 12.792C7 15.0011 5.20914 16.792 3 16.792Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M13 13.792C15.2091 13.792 17 15.5829 17 17.792C17 15.5829 18.7909 13.792 21 13.792C18.7909 13.792 17 12.0011 17 9.79199C17 12.0011 15.2091 13.792 13 13.792Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M7 6.79199C9.20914 6.79199 11 8.58285 11 10.792C11 8.58285 12.7909 6.79199 15 6.79199C12.7909 6.79199 11 5.00113 11 2.79199C11 5.00113 9.20914 6.79199 7 6.79199Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_9290">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
