<script lang="ts" setup></script>

<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M12 13.1199V9.37988"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="1.5"
		/>
		<path
			class="fill-current stroke-current"
			d="M12 16.8799C11.7261 16.8799 11.5 16.6537 11.5 16.3799C11.5 16.2523 11.5478 16.1245 11.6525 16.0143C11.7343 15.9282 11.8456 15.8799 12 15.8799C12.2739 15.8799 12.5 16.106 12.5 16.3799C12.5 16.6537 12.2739 16.8799 12 16.8799Z"
		/>
		<path
			class="stroke-current"
			d="M14.0299 4.1399L21.6899 17.5399C22.5799 19.0999 21.4499 21.0399 19.6599 21.0399H4.33991C2.53991 21.0399 1.41991 19.0999 2.30991 17.5399L9.96991 4.1399C10.8699 2.5699 13.1299 2.5699 14.0299 4.1399Z"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="1.5"
		/>
	</svg>
</template>
