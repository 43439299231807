<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M17 4H6C4.89543 4 4 4.89543 4 6V16.98C4 18.0846 4.89543 18.98 6 18.98H17C18.1046 18.98 19 18.0846 19 16.98V6C19 4.89543 18.1046 4 17 4Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M10.9399 15.4102H16.4999"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M10.9399 12.0801H16.4999"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M6.5 8.17023L7.51 9.08023L9.54 7.24023"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M10.9399 8.74023H16.4999"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M6.5 11.5003L7.51 12.4103L9.54 10.5703"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M6.5 14.8304L7.51 15.7404L9.54 13.9004"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_8818">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
