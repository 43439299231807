<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M20 11.2721V8.94C20 8.40957 19.7893 7.90086 19.4142 7.52579C19.0391 7.15071 18.5304 6.94 18 6.94H12.5291C12.3661 6.94 12.2055 6.90014 12.0614 6.82389C11.9173 6.74764 11.7941 6.63732 11.7024 6.50253L10.2974 4.43747C10.2056 4.30267 10.0824 4.19235 9.93826 4.1161C9.79415 4.03985 9.63358 3.99999 9.47054 4H5C4.46957 4 3.96086 4.21071 3.58579 4.58579C3.21071 4.96086 3 5.46957 3 6V18.3353C3 18.5539 3.04306 18.7704 3.12671 18.9724C3.21037 19.1743 3.33299 19.3579 3.48757 19.5124C3.64215 19.667 3.82566 19.7896 4.02762 19.8733C4.22959 19.9569 4.44605 20 4.66466 20M4.66466 20C5.04341 20 5.41083 19.8708 5.70627 19.6338C6.00171 19.3968 6.2075 19.0662 6.28966 18.6965L7.652 12.5661C7.75071 12.1219 7.99795 11.7247 8.35291 11.4399C8.70786 11.1552 9.14929 11 9.60434 11H18.9992C19.2987 11 19.5944 11.0673 19.8644 11.1968C20.1344 11.3264 20.3719 11.5149 20.5593 11.7485C20.7467 11.9822 20.8792 12.2549 20.9471 12.5466C21.015 12.8383 21.0165 13.1415 20.9516 13.4339L19.8405 18.4339C19.7417 18.8781 19.4945 19.2753 19.1395 19.5601C18.7846 19.8448 18.3431 20 17.8881 20H4.66466Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_9198">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
