<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M13.87 6.5H5.48C4.66 6.5 4 7.16 4 7.98C4 8.47 4.24 8.93 4.65 9.21L9.62 12.58C10.75 13.34 12.23 13.34 13.35 12.58L15.07 11.41"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M4 8.16016V16.4802C4 17.4002 4.74 18.1402 5.66 18.1402H17.3C18.22 18.1402 18.96 17.4002 18.96 16.4802V11.6602"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M4.49023 17.6598L9.60023 12.5498"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M13.3799 12.5601L18.4799 17.6601"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="fill-vivid-red"
			d="M17.5 10C18.8807 10 20 8.88071 20 7.5C20 6.11929 18.8807 5 17.5 5C16.1193 5 15 6.11929 15 7.5C15 8.88071 16.1193 10 17.5 10Z"
		/>

		<defs>
			<clipPath id="clip0_1366_8971">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
