import type { RouteRecordRaw } from 'vue-router'

import { accidentsRoutes } from '@/modules/accidents/routes/accidents.routes'
import { anomaliesRoute } from '@/modules/anomalies/routes/anomalies.route'
import { referralPremiumRoutes } from '@/modules/application-premium/routes/referralPremiums.routes'
import { AuthenticationRoutes } from '@/modules/authentication/routes/authentication.routes'
import { claimsRoutes } from '@/modules/claims/routes/claims.routes'
import { compensationsRoutes } from '@/modules/compensations/routes/compensations.routes'
import { customersRoutes } from '@/modules/customers/routes/customers.routes'
import { dashboardRoutes } from '@/modules/dashboard/routes/dashboard.routes'
import { debtorsRoutes } from '@/modules/debtors/routes/debtors.routes'
import { employeesRoutes } from '@/modules/employees/routes/employees.routes'
import { logbooksRoutes } from '@/modules/logbooks/routes/logbooks.routes'
import { matchingCenterRoutes } from '@/modules/matching-center/routes/matchingCenter.routes'
import { newsRoutes } from '@/modules/news/routes/news.routes'
import { publicTermsOfServiceRoutes } from '@/modules/public/terms-of-service/routes/publicTermsOfService.routes'
import { remittanceRoutes } from '@/modules/remittance/routes/remittance.routes'
import { settingsRoutes } from '@/modules/settings/routes/settings.routes'
import { subsidiesRoutes } from '@/modules/subsidies/routes/subsidies.routes'
import { todosRoutes } from '@/modules/todos/routes/todos.routes'
import ROUTE_NAME from '@/router/routeName'

const routes: RouteRecordRaw[] = [
	...AuthenticationRoutes,
	{
		path: '/',
		name: ROUTE_NAME.BASE,
		component: async () => import('@/components/layout/AppLayout.vue'),
		meta: { requiresAuth: true },
		redirect: { name: ROUTE_NAME.DASHBOARD },
		children: [
			...anomaliesRoute,
			...todosRoutes,
			...logbooksRoutes,
			...customersRoutes,
			...employeesRoutes,
			...newsRoutes,
			...remittanceRoutes,
			...subsidiesRoutes,
			...referralPremiumRoutes,
			...debtorsRoutes,
			...matchingCenterRoutes,
			...claimsRoutes,
			...accidentsRoutes,
			...dashboardRoutes,
			...compensationsRoutes,
			...settingsRoutes,
			{
				component: async () => import('@/views/GlobalSearchView.vue'),
				name: ROUTE_NAME.GLOBAL_SEARCH,
				path: '/global-search',
			},
		],
	},
	{
		path: '/public',
		name: ROUTE_NAME.PUBLIC,
		component: async () => import('@/components/layout/PublicLayout.vue'),
		meta: { requiresAuth: false },
		children: [...publicTermsOfServiceRoutes],
	},
	{
		name: ROUTE_NAME.ERROR,
		path: '/:pathMatch(.*)*',
		component: async () => import('@/views/Error404View.vue'),
	},
]

export default routes
