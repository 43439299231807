<script lang="ts" setup></script>

<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M11.6502 12.3901L7.66016 8.39014"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M11.3901 4.6499L15.3901 8.6499"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M3 21H13.59"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M3.8999 21L4.4449 19.368C4.71646 18.5527 5.47853 18.0019 6.3379 18H10.2479C11.1099 17.9989 11.8755 18.5502 12.1479 19.368L12.6889 21"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M8.89188 7.30012L6.69188 9.05512C5.90043 9.6881 5.77196 10.8428 6.40495 11.6343C6.44593 11.6855 6.48964 11.7346 6.53588 11.7811L8.21188 13.4651C8.91844 14.1754 10.067 14.1784 10.7772 13.4718C10.8249 13.4244 10.8698 13.3745 10.9119 13.3221L12.6879 11.1161C13.1081 10.5937 13.5842 10.1188 14.1079 9.70012L16.3079 7.94512C17.0993 7.31214 17.2278 6.1574 16.5948 5.36595C16.5538 5.3147 16.5101 5.26569 16.4639 5.21912L14.7909 3.53612C14.0843 2.82586 12.9358 2.82287 12.2255 3.52944C12.1779 3.5768 12.1329 3.62677 12.0909 3.67912L10.3129 5.88512C9.89258 6.4075 9.41604 6.88203 8.89188 7.30012Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M14.376 9.4873L20.3 14.5003C21.1359 15.2105 21.2377 16.4639 20.5275 17.2997C19.8172 18.1355 18.5639 18.2374 17.7281 17.5272C17.6637 17.4725 17.6029 17.4137 17.546 17.3513L12.312 11.5873"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
