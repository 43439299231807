<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M15 8.5H9"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M6 3H18C18.7956 3 19.5587 3.31607 20.1213 3.87868C20.6839 4.44129 21 5.20435 21 6V15C21 15.7956 20.6839 16.5587 20.1213 17.1213C19.5587 17.6839 18.7956 18 18 18H7.5L3.80841 20.8926C3.73462 20.9505 3.64605 20.9864 3.55283 20.9963C3.4596 21.0062 3.36547 20.9897 3.28117 20.9487C3.19688 20.9076 3.12582 20.8437 3.0761 20.7642C3.02638 20.6848 3.00001 20.5929 3 20.4991V6C3 5.20435 3.31607 4.44129 3.87868 3.87868C4.44129 3.31607 5.20435 3 6 3Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M12 12.5H9"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_9074">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
