<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 20 20"
	>
		<g clip-path="url(#clip0_873_4976)">
			<path
				class="stroke-current"
				d="M4.27249 8.37083V8.2275C4.27249 5.06417 6.83666 2.5 9.99999 2.5C13.1633 2.5 15.7275 5.06417 15.7275 8.2275V8.37083C15.7275 11.255 12.0758 15.5967 10.6042 17.23C10.28 17.59 9.71999 17.59 9.39582 17.23C7.92416 15.5967 4.27249 11.255 4.27249 8.37083Z"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				class="stroke-current"
				d="M8.33334 8.29583C8.33334 9.21667 9.07918 9.9625 10 9.9625C10.9208 9.9625 11.6667 9.21667 11.6667 8.29583V8.265C11.6667 7.34417 10.9208 6.59834 10 6.59834C9.07918 6.59834 8.33334 7.34417 8.33334 8.265"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</g>
	</svg>
</template>
