<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M6 9V19C6 20.1046 6.89543 21 8 21H16C17.1046 21 18 20.1046 18 19V9M14 10V17M10 10V17M4.5 6H19.5M8 6L8.544 4.368C8.81614 3.55092 9.58079 2.99979 10.442 3H13.558C14.42 2.99893 15.1856 3.55022 15.458 4.368L16 6"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_9129">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
