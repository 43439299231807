<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M13.7678 10.9822C14.7441 11.9585 14.7441 13.5415 13.7678 14.5178C12.7915 15.4941 11.2085 15.4941 10.2322 14.5178C9.25592 13.5415 9.25592 11.9585 10.2322 10.9822C11.2085 10.0059 12.7915 10.0059 13.7678 10.9822Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M19.2536 4.98223C20.2299 5.95854 20.2299 7.54145 19.2536 8.51776C18.2773 9.49407 16.6944 9.49407 15.7181 8.51776C14.7418 7.54145 14.7418 5.95854 15.7181 4.98223C16.6944 4.00592 18.2773 4.00592 19.2536 4.98223Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M18 12.25C19.533 12.25 21 12.783 22 13.583"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M8.28192 4.98223C9.25823 5.95854 9.25823 7.54145 8.28192 8.51776C7.30561 9.49407 5.7227 9.49407 4.74639 8.51776C3.77008 7.54145 3.77008 5.95854 4.74639 4.98223C5.7227 4.00592 7.30561 4.00592 8.28192 4.98223Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M2 13.583C3 12.783 4.467 12.25 6 12.25"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M16.5912 19.75C15.4262 18.849 13.7522 18.25 12.0002 18.25C10.2482 18.25 8.57418 18.849 7.40918 19.75"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_9270">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
