<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<g clip-path="url(#clip0_235_3199)">
			<path
				class="stroke-current"
				d="M3.5 7.8501H21"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				class="stroke-current"
				d="M21 14V6C21 4.895 20.105 4 19 4H5C3.895 4 3 4.895 3 6V15C3 16.105 3.895 17 5 17H12"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				class="stroke-current"
				d="M20.5362 13.4639C22.4892 15.4169 22.4892 18.5819 20.5362 20.5349C18.5832 22.4879 15.4182 22.4879 13.4652 20.5349C11.5122 18.5819 11.5122 15.4169 13.4652 13.4639C15.4182 11.5109 18.5832 11.5119 20.5362 13.4639Z"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				class="fill-current"
				d="M16.9248 19C16.5873 19 16.2998 19.2875 16.2998 19.625C16.2998 19.9625 16.5873 20.25 16.9248 20.25C17.2623 20.25 17.5498 19.9625 17.5498 19.625C17.5498 19.2875 17.2623 19 16.9248 19Z"
			/>
			<path
				class="stroke-current"
				d="M16.9998 17.625C17.8628 17.625 18.5628 16.925 18.5628 16.062C18.5628 15.199 17.8638 14.499 16.9998 14.499C16.1578 14.499 15.4768 15.165 15.4438 15.999"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_235_3199">
				<rect
					class="fill-current"
					height="24"
					width="24"
				/>
			</clipPath>
		</defs>
	</svg>
</template>
