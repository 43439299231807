<script lang="ts" setup></script>

<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="fill-current"
			d="M11.6605 11.6229V10.4579C11.6605 9.43434 12.2935 8.88215 12.9265 8.45791C13.546 8.0404 14.1656 7.50168 14.1656 6.49832C14.1656 5.11785 13.0477 4 11.6673 4C10.2868 4 9.16895 5.11785 9.16895 6.49832"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="fill-current"
			d="M3.93682 16.2556L11.6675 11.6226L19.3981 16.2556C21.0614 17.2522 20.3543 19.8111 18.4149 19.8111H4.91999C2.97386 19.8111 2.26679 17.2522 3.93682 16.2556Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
