<script lang="ts" setup></script>

<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M12 19V12"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M9.83301 14L12 11.833L14.167 14"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M15.9999 19H18.5599C20.4879 19 22.0599 17.428 22.0599 15.5C22.0599 13.572 20.4879 12 18.5599 12H18.1249V11C18.1249 7.69 15.4349 5 12.1249 5C9.14794 5 6.67994 7.178 6.21194 10.023C3.83494 10.144 1.93994 12.093 1.93994 14.5C1.93994 16.985 3.95494 19 6.43994 19H7.99994"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
