<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M19.9947 12.1094V19.2133C19.9947 20.1976 19.2029 20.9893 18.2187 20.9893H5.776C4.79171 20.9893 4 20.1976 4 19.2133V6.78137C4 5.79708 4.79171 5.00537 5.776 5.00537H11.992"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M9.32812 15.6613L12.1312 15.319C12.3238 15.2976 12.5057 15.2013 12.6554 15.0622L19.4385 8.27917C20.1874 7.53026 20.1874 6.3106 19.4385 5.56169C18.6895 4.81277 17.4699 4.81277 16.721 5.56169L10.0021 12.2805C9.86306 12.4196 9.77747 12.5908 9.75608 12.7834L9.34952 15.6613H9.32812Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_9085">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
