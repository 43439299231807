<script setup lang="ts"></script>

<template>
	<svg
		fill="none"
		viewBox="0 0 20 20"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			class="stroke-current"
			d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="1.66667"
		/>
	</svg>
</template>
