<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M10.6412 14.3594L8.15967 16.8409"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M10.6412 16.8409L8.15967 14.3594"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M6.59961 11.925V8.87302C6.59961 7.87464 7.218 6.98025 8.15158 6.62746L14.8338 4.10431C15.881 3.70992 16.9994 4.4827 16.9994 5.60188V8.28183"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="fill-current"
			d="M16.7968 15.2812C16.4656 15.2836 16.1992 15.5524 16.1992 15.8836C16.1992 16.2148 16.468 16.4836 16.7992 16.4812C17.1304 16.4812 17.3992 16.2124 17.3992 15.8812C17.3992 15.55 17.1304 15.2812 16.7968 15.2812Z"
		/>
		<path
			class="stroke-current"
			d="M11.9291 19.2002H17.7994C19.1194 19.2002 20.1993 18.1203 20.1993 16.8003V10.6812C20.1993 9.35563 19.125 8.28125 17.7994 8.28125H8.19958C7.3156 8.28125 6.59961 8.99724 6.59961 9.88122V12.206"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M9.39991 20C6.97036 20 5 18.0297 5 15.6001C5 13.1706 6.97036 11.2002 9.39991 11.2002C11.8303 11.2002 13.7998 13.1706 13.7998 15.6001C13.7998 18.0297 11.8303 20 9.39991 20Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
