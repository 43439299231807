<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M6.80641 12.3718C6.88175 13.4575 7.29553 14.4926 7.99091 15.3288C8.68629 16.1662 9.62747 16.7628 10.681 17.0357V15.3485C10.681 14.5593 10.3673 13.8009 9.80904 13.2426C9.25075 12.6843 8.49361 12.3706 7.70312 12.3706H6.80518L6.80641 12.3718Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M7.13477 10.1388C7.44355 9.32612 7.95243 8.6048 8.61199 8.03911C9.27156 7.47341 10.0633 7.08187 10.9131 6.90031C11.7628 6.71874 12.6447 6.75333 13.4784 7.00035C14.3122 7.24738 15.0705 7.69944 15.6844 8.31454C16.2019 8.8333 16.6046 9.45458 16.864 10.1388H7.13477Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M17.1939 12.3716C17.1186 13.4597 16.7023 14.4973 16.0045 15.3359C15.3054 16.1746 14.3605 16.7712 13.3032 17.0404V15.3495C13.3032 14.5602 13.6169 13.8019 14.1752 13.2436C14.7335 12.6853 15.4907 12.3716 16.2811 12.3716H17.1939Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M11.9999 19.444C16.1116 19.444 19.444 16.1116 19.444 11.9999C19.444 7.88807 16.1116 4.55566 11.9999 4.55566C7.88807 4.55566 4.55566 7.88807 4.55566 11.9999C4.55566 16.1116 7.88807 19.444 11.9999 19.444Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_8896">
				<rect fill="white" />
			</clipPath>
			<clipPath id="clip1_1366_8896">
				<rect
					fill="white"
					height="16"
					transform="translate(4 4)"
					width="16"
				/>
			</clipPath>
		</defs>
	</svg>
</template>
