<script lang="ts" setup></script>
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M16.5928 7.97241L12.6204 4L8.64795 7.97241"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="1.10345"
		/>
		<path
			class="stroke-current"
			d="M5.99991 20.0001L6.55164 20.0001C9.90344 20.0001 12.6206 17.2829 12.6206 13.9311V13.9311L12.6206 5.10352"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="1.10345"
		/>
		<path
			class="stroke-current"
			d="M19.2413 20.0001L18.6896 20.0001C15.3378 20.0001 12.6206 17.2829 12.6206 13.9311V13.9311L12.6206 5.10352"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="1.10345"
		/>
	</svg>
</template>
