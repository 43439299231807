<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M10.03 5H4.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M13.4411 3.58579C14.2221 4.36684 14.2221 5.63317 13.4411 6.41422C12.66 7.19527 11.3937 7.19527 10.6126 6.41422C9.83159 5.63317 9.83159 4.36684 10.6126 3.58579C11.3937 2.80474 12.66 2.80474 13.4411 3.58579Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M19.4998 5H14.0298"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M4.5 20.75H19.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M12.03 7L12 20.75"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M8.81406 13.227C8.92806 13.407 8.99306 13.618 8.97306 13.83C8.80606 15.608 7.31006 17 5.48806 17C3.66706 17 2.17106 15.609 2.00306 13.831C1.98306 13.619 2.04906 13.404 2.16406 13.224L5.48806 8C5.48806 8 8.10706 12.115 8.81406 13.227Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M8.94004 13.5H2.04004"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M21.8409 13.227C21.9549 13.407 22.0199 13.618 21.9999 13.83C21.8329 15.608 20.3369 17 18.5149 17C16.6939 17 15.1979 15.609 15.0299 13.831C15.0099 13.619 15.0759 13.404 15.1909 13.224L18.5149 8C18.5149 8 21.1329 12.115 21.8409 13.227Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M21.9701 13.5H15.0601"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
