<script setup lang="ts"></script>
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M6 17.3135L17.3137 5.99977"
			stroke-linecap="round"
			stroke-width="2"
		/>
		<path
			class="stroke-current"
			d="M6 6L17.3137 17.3137"
			stroke-linecap="round"
			stroke-width="2"
		/>
	</svg>
</template>
