<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M17.65 4.75012L16.25 3.35012C15.86 2.96012 15.23 2.96012 14.84 3.35012L4 14.1801V17.0001H6.82L17.65 6.17012C18.04 5.78012 18.04 5.15012 17.65 4.75012Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M3 21H21"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M15.8698 7.94988L13.0498 5.12988"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_9310">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
