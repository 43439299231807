<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M5.58008 11.29V6.58C5.58008 5.71 6.29008 5 7.16008 5H17.4201C18.2901 5 19.0001 5.71 19.0001 6.58V17.63C19.0001 18.5 18.2901 19.21 17.4201 19.21H13.5901"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M10.71 13.29H5.18C4.5283 13.29 4 13.8183 4 14.47V18.43C4 19.0817 4.5283 19.61 5.18 19.61H10.71C11.3617 19.61 11.89 19.0817 11.89 18.43V14.47C11.89 13.8183 11.3617 13.29 10.71 13.29Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M4 14.8701L5.53 15.8601C5.72 15.9801 5.95 16.0501 6.18 16.0501H9.73C9.96 16.0501 10.18 15.9801 10.38 15.8601L11.91 14.8701"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M15.8402 10.5303H8.74023"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M15.8398 13.29H14.2598"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M15.8402 7.75977H8.74023"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M15.8398 16.0498H14.2598"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_8868">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
