<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M13.11 5.5H10.23C7.06 5.5 4.5 8.07 4.5 11.23V11.29C4.5 14.46 7.07 17.02 10.23 17.02H11.07C11.07 17.02 11.08 17.02 11.08 17.03V18.89C11.08 19.22 11.35 19.49 11.68 19.49C11.78 19.49 11.88 19.46 11.97 19.42L16.1 17.15C18.08 16.06 19.32 13.98 19.32 11.71C19.32 8.28 16.54 5.5 13.11 5.5Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_8998">
				<rect fill="white" />
			</clipPath>
			<clipPath id="clip1_1366_8998">
				<rect
					fill="white"
					height="14.99"
					transform="translate(4 5)"
					width="15.81"
				/>
			</clipPath>
		</defs>
	</svg>
</template>
