<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="fill-current"
			d="M19.6464 20.3536C19.8417 20.5488 20.1583 20.5488 20.3536 20.3536C20.5488 20.1583 20.5488 19.8417 20.3536 19.6464L19.6464 20.3536ZM20.3536 19.6464L15.3536 14.6464L14.6464 15.3536L19.6464 20.3536L20.3536 19.6464Z"
		/>
		<path
			class="stroke-current"
			d="M4 10.5C4 11.7856 4.38122 13.0423 5.09545 14.1112C5.80968 15.1801 6.82484 16.0132 8.01256 16.5052C9.20028 16.9972 10.5072 17.1259 11.7681 16.8751C13.029 16.6243 14.1872 16.0052 15.0962 15.0962C16.0052 14.1872 16.6243 13.029 16.8751 11.7681C17.1259 10.5072 16.9972 9.20028 16.5052 8.01256C16.0132 6.82484 15.1801 5.80968 14.1112 5.09545C13.0423 4.38122 11.7856 4 10.5 4C8.77613 4.00013 7.1229 4.68499 5.90394 5.90394C4.68499 7.1229 4.00013 8.77613 4 10.5Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_9139">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
