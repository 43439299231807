<script lang="ts" setup></script>

<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M20 10.533V7.53296"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M13.028 19.5H5C3.343 19.5 2 18.157 2 16.5L2 8"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="fill-current"
			clip-rule="evenodd"
			d="M4.93699 6C4.97812 5.84019 5 5.67265 5 5.5C5 5.32735 4.97812 5.15981 4.93699 5H17.967C19.3661 5 20.5 6.13386 20.5 7.533V7.553C20.5 8.48037 19.9864 9.3328 19.165 9.76539L19.165 9.7654L13.097 12.9614L13.0968 12.9615C11.7841 13.6522 10.2159 13.6522 8.903 12.9604L8.90289 12.9603L2.852 9.77339L2.85164 9.7732C2.02096 9.33482 1.5 8.47281 1.5 7.533V7.5C1.5 7.29336 1.52505 7.09259 1.57227 6.90056C1.82345 7.15659 2.14297 7.34535 2.50128 7.43732C2.50043 7.45811 2.5 7.479 2.5 7.5V7.533C2.5 8.10112 2.81496 8.62304 3.31816 8.88869L3.31836 8.8888L9.369 12.0756L9.36911 12.0757C10.3902 12.6138 11.6099 12.6138 12.6312 12.0765L18.699 8.88061L18.699 8.8806C19.1916 8.62118 19.5 8.10962 19.5 7.553V7.533C19.5 6.68614 18.8139 6 17.967 6H4.93699Z"
			fill-rule="evenodd"
		/>
		<circle
			class="fill-vivid-red stroke-vivid-red"
			cx="3"
			cy="5.5"
			r="2"
		/>
		<path
			class="stroke-current"
			d="M19 13.5L16 16.5L19 19.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M16.5 16.5L22.5 16.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
