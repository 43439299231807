<script setup lang="ts"></script>

<template>
	<svg
		fill="none"
		viewBox="0 0 32 32"
	>
		<path
			class="fill-current"
			d="M7 21H9V21.5H8V22.5H9V23H7V24H10V20H7V21ZM8 12H9V8H7V9H8V12ZM7 15H8.8L7 17.1V18H10V17H8.2L10 14.9V14H7V15ZM12 9V11H26V9H12ZM12 23H26V21H12V23ZM12 17H26V15H12V17Z"
		/>
	</svg>
</template>
