<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M16.1026 7.26953C17.273 8.30986 17.999 9.82702 17.999 11.5067V13.2406C17.999 13.2406 17.9882 15.874 19.1477 17.0768C20.2748 18.2472 19.2561 20.3387 17.4247 19.4501C17.1321 20.0461 16.5252 20.4579 15.81 20.4579C15.0623 20.4579 14.412 19.9919 14.152 19.3417C14.0436 20.2737 13.2633 20.9998 12.3097 20.9998C11.3561 20.9998 10.5758 20.2737 10.4674 19.3417C10.1965 19.9919 9.55716 20.4579 8.80942 20.4579C8.09419 20.4579 7.48732 20.0461 7.19473 19.4501C5.36331 20.3387 4.34465 18.2472 5.47168 17.0768C6.63122 15.874 6.62038 13.2406 6.62038 13.2406V11.5067C6.62038 9.81618 7.35728 8.30986 8.51682 7.26953"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="fill-current"
			d="M10.3267 11.4093C10.11 11.4093 9.91493 11.3226 9.76322 11.1817C9.6115 11.03 9.53564 10.835 9.53564 10.6182C9.53564 10.1848 9.89326 9.82715 10.3267 9.82715C10.7602 9.82715 11.1178 10.1848 11.1178 10.6182C11.1178 11.0625 10.7602 11.4093 10.3267 11.4093Z"
		/>
		<path
			class="fill-current"
			d="M14.315 11.4093C13.8815 11.4093 13.5239 11.0517 13.5239 10.6182C13.5239 10.1848 13.8815 9.82715 14.315 9.82715C14.7485 9.82715 15.1061 10.1848 15.1061 10.6182C15.1061 11.0625 14.7485 11.4093 14.315 11.4093Z"
		/>
		<path
			class="stroke-current"
			d="M12.3207 16.535L14.2063 12.8396C13.6319 12.547 13.0034 12.3628 12.3207 12.3628C11.6379 12.3628 10.9986 12.547 10.4351 12.8396C11.0636 14.075 12.3207 16.535 12.3207 16.535Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M11.1505 14.2373C10.8904 14.3348 10.6629 14.5299 10.5328 14.8008L9.88261 16.2313C9.63336 16.7731 9.88261 17.4125 10.4244 17.6617C10.9663 17.911 11.6057 17.6617 11.8549 17.1199L12.2125 16.318C12.0066 15.917 11.5298 14.9959 11.1505 14.2373Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M14.7586 16.2313L14.1084 14.8008C13.9784 14.5299 13.7616 14.3348 13.4907 14.2373L12.4287 16.318L12.7863 17.1199C13.0356 17.6617 13.6749 17.9001 14.2168 17.6617C14.7586 17.4233 14.997 16.7731 14.7586 16.2313Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M7 18.8003C7 18.8003 7.10837 19.5914 7.54184 19.9165"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M17.6414 18.8003C17.6414 18.8003 17.5331 19.5914 17.0996 19.9165"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M15.1167 4.87477C14.7374 4.87477 14.4015 5.02648 14.1522 5.26489C14.1739 5.13485 14.1956 5.00481 14.1956 4.87477C14.1956 3.84527 13.3611 3 12.3208 3C11.2805 3 10.446 3.83443 10.446 4.87477C10.446 5.00481 10.4569 5.13485 10.4894 5.26489C10.2401 5.02648 9.90419 4.87477 9.5249 4.87477C8.75549 4.87477 8.12695 5.5033 8.12695 6.27272C8.12695 7.04213 8.75549 7.67066 9.5249 7.67066C9.89335 7.67066 10.2293 7.51895 10.4785 7.29138C10.6194 8.18 11.3888 8.86271 12.3208 8.86271C13.2528 8.86271 14.0222 8.18 14.1631 7.29138C14.4123 7.52979 14.7482 7.67066 15.1167 7.67066C15.8861 7.67066 16.5146 7.04213 16.5146 6.27272C16.5146 5.5033 15.8861 4.87477 15.1167 4.87477Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_8917">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
