<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="fill-current"
			d="M10.7077 8.92268C10.7077 9.83005 9.30123 9.83005 9.31257 8.88865C9.31257 7.99262 10.7417 8.02665 10.7077 8.92268Z"
		/>
		<path
			class="fill-current"
			d="M9.60753 16.5448C9.60753 17.4068 8.30318 17.3954 8.31453 16.5448C8.31453 15.7622 9.61887 15.6714 9.60753 16.5448Z"
		/>
		<path
			class="fill-current"
			d="M14.3712 16.5448C14.3712 17.4068 13.0669 17.3954 13.0782 16.5448C13.0782 15.7622 14.3825 15.6714 14.3712 16.5448Z"
		/>
		<path
			class="fill-current"
			d="M12.3069 14.0154C12.3069 14.9114 10.9345 14.8433 10.9459 13.9813C10.9572 13.1193 12.3296 13.0966 12.3069 14.0154Z"
		/>
		<path
			class="fill-current"
			d="M16.1409 14.2421C16.1409 15.1381 14.7685 15.0701 14.7799 14.2081C14.7912 13.3347 16.1523 13.3687 16.1409 14.2421Z"
		/>
		<path
			class="stroke-current"
			d="M9.24492 18.9717H10.1409C10.2771 19.4027 10.3791 20.5029 10.572 20.8658C10.6967 20.8658 16.8442 19.6635 16.9349 19.5955C16.8328 16.1928 19.623 14.2193 19.6003 10.8393C20.2582 1.7883 4.51524 1.55012 6.42072 11.1456C6.0918 12.5293 3.5625 13.6409 6.15985 14.242C5.6608 15.3082 6.35267 16.4083 6.26193 17.4405C5.96703 19.0624 8.23547 18.7902 9.25626 18.9717H9.24492Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M8.2124 11.4858C8.2124 11.4858 8.99501 12.0416 10.322 12.0416"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="fill-current"
			d="M13.8045 10.9532C13.8045 11.9059 12.33 11.9059 12.3414 10.9532C12.3527 9.98908 13.7932 9.95505 13.8045 10.9532Z"
		/>
	</svg>
</template>
