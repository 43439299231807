<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="fill-current"
			d="M10.4256 10.8799C10.2007 10.8799 9.9984 10.79 9.84103 10.6439C9.68367 10.4865 9.60498 10.2842 9.60498 10.0593C9.60498 9.60971 9.97592 9.23877 10.4256 9.23877C10.8752 9.23877 11.2461 9.60971 11.2461 10.0593C11.2461 10.5202 10.8752 10.8799 10.4256 10.8799Z"
		/>
		<path
			class="stroke-current"
			d="M13.427 13.1053C13.427 13.6224 13.0111 14.0383 12.494 14.0383C11.9769 14.0383 11.561 13.6224 11.561 13.1053C11.561 12.5883 11.9769 12.1724 12.494 12.1724C13.0111 12.1724 13.427 12.5883 13.427 13.1053Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="fill-current"
			d="M14.5628 10.8799C14.1131 10.8799 13.7422 10.509 13.7422 10.0593C13.7422 9.60971 14.1131 9.23877 14.5628 9.23877C15.0124 9.23877 15.3833 9.60971 15.3833 10.0593C15.3833 10.5202 15.0124 10.8799 14.5628 10.8799Z"
		/>
		<path
			class="stroke-current"
			d="M12.4941 13.1055V14.8815"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M11.2798 16.9722V18.4447H13.7078V16.9722"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M18.0248 10.3288V8.11442C18.0248 6.82174 16.9794 5.7876 15.6979 5.7876H9.31324C8.02056 5.7876 6.98641 6.83298 6.98641 8.11442V10.3288C4.62587 10.9358 3.05218 13.3189 3.61421 15.8143L3.88399 17.0058C4.35609 19.119 6.23329 20.6141 8.3915 20.6141H16.6084C18.7667 20.6141 20.6438 19.119 21.116 17.0058L21.3857 15.8143C21.9478 13.3189 20.3741 10.9358 18.036 10.3401L18.0248 10.3288Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M18.0469 8.53042C19.5757 8.53042 20.8122 7.29394 20.8122 5.76521C20.8122 4.23648 19.5757 3 18.0469 3C16.5182 3 15.2817 4.23648 15.2817 5.76521"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M6.94099 8.53041C5.41226 8.53041 4.17578 7.29393 4.17578 5.7652C4.17578 4.23647 5.4235 3.01123 6.94099 3.01123C8.45848 3.01123 9.7062 4.24771 9.7062 5.77644"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M16.3384 13.5103H18.6652"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M8.65006 13.5103H6.32324"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M6.57031 15.9269L9.21187 15.2075C9.21187 16.118 9.94252 16.8487 10.853 16.8487C11.7635 16.8487 12.4942 16.118 12.4942 15.2075C12.4942 16.118 13.2248 16.8487 14.1353 16.8487C15.0458 16.8487 15.7764 16.118 15.7764 15.2075L18.418 15.9269"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_8914">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
