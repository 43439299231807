<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M13.5 4.50977H6C4.9 4.50977 4 5.40977 4 6.50977V17.4898C4 18.5898 4.9 19.4898 6 19.4898H17C18.1 19.4898 19 18.5898 19 17.4898V9.99976"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M10.9399 15.9199H16.4999"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M10.9399 12.5898H16.4999"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M6.5 8.68L7.51 9.59L9.54 7.75"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M10.9399 9.25H14"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M6.5 12.0101L7.51 12.9201L9.54 11.0801"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M6.5 15.3402L7.51 16.2502L9.54 14.4102"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="fill-vivid-red"
			d="M17 9C18.3807 9 19.5 7.88071 19.5 6.5C19.5 5.11929 18.3807 4 17 4C15.6193 4 14.5 5.11929 14.5 6.5C14.5 7.88071 15.6193 9 17 9Z"
		/>

		<defs>
			<clipPath id="clip0_1366_8829">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
