<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M5.98 21H4C3.45 21 3 20.55 3 20V14C3 13.45 3.45 13 4 13H5.98C6.53 13 6.98 13.45 6.98 14V20C6.98 20.55 6.53 21 5.98 21Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M12.9602 17.0001H15.2802C15.7102 17.0001 16.1302 16.8601 16.4702 16.6001L18.8802 14.7901C19.4802 14.3401 20.3202 14.4001 20.8502 14.9301C21.4402 15.5201 21.4402 16.4801 20.8502 17.0701L18.7902 19.1401C18.2302 19.7001 17.5302 20.0801 16.7502 20.2301L13.8302 20.8201C13.2502 20.9401 12.6502 20.9201 12.0802 20.7801L9.44021 20.1201C9.13021 20.0401 8.80022 20.0001 8.47022 20.0001H6.97021"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M12.96 17H14.44C15.27 17 15.95 16.32 15.95 15.48V15.18C15.95 14.49 15.48 13.88 14.81 13.71L12.53 13.14C12.16 13.05 11.78 13 11.4 13C10.48 13 9.57 13.27 8.81 13.79L7 15"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M15.0498 10.5203H8.93979C8.43979 10.5203 8.02979 10.1103 8.02979 9.61027V6.44027C8.02979 5.94027 8.43979 5.53027 8.93979 5.53027H15.0498C15.5498 5.53027 15.9598 5.94027 15.9598 6.44027V9.61027C15.9598 10.1103 15.5498 10.5203 15.0598 10.5203H15.0498Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M12 5.54004V10.52"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M12.0597 5.54012C12.5797 5.54012 13.6597 5.37012 14.3397 4.66012C14.8397 4.14012 14.8397 3.29012 14.3397 2.76012C13.8397 2.24012 13.0197 2.24012 12.5197 2.76012C11.7497 3.56012 12.0597 5.54012 12.0597 5.54012Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M11.9397 5.54012C11.4197 5.54012 10.3397 5.37012 9.65967 4.66012C9.15967 4.14012 9.15967 3.29012 9.65967 2.76012C10.1597 2.24012 10.9797 2.24012 11.4797 2.76012C12.2497 3.56012 11.9397 5.54012 11.9397 5.54012Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_9284">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
