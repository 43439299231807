<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M9.70316 4.09524L3.52916 7.52524C2.23116 8.24624 2.23116 10.1122 3.52916 10.8332L9.70316 14.2632C11.1312 15.0562 12.8682 15.0562 14.2972 14.2632L20.4712 10.8332C21.7692 10.1122 21.7692 8.24624 20.4712 7.52524L14.2972 4.09524C12.8682 3.30224 11.1322 3.30224 9.70316 4.09524Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M5.99121 12.2002V16.1242C5.99121 17.0732 6.46621 17.9582 7.25521 18.4832L8.86221 19.5522C10.7632 20.8162 13.2372 20.8162 15.1372 19.5522L16.7442 18.4832C17.5342 17.9582 18.0082 17.0722 18.0082 16.1242V12.2002"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_9276">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
