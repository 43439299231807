<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M10.1811 19.1522L10.4824 18.8509C10.8848 18.4485 11.4307 18.2224 11.9998 18.2224C12.2816 18.2224 12.5606 18.2779 12.821 18.3857C13.0814 18.4936 13.3179 18.6517 13.5172 18.8509L13.8185 19.1523C14.3615 19.6952 15.0978 20.0002 15.8656 20.0002H16.2158C16.9837 20.0002 17.72 19.6951 18.263 19.1522C18.8059 18.6093 19.1109 17.8729 19.1109 17.1051C19.1109 16.6285 18.9932 16.1593 18.7683 15.7391C18.5434 15.3188 18.2183 14.9606 17.8217 14.6963C16.9156 14.0922 16.1607 13.2875 15.6157 12.3446L14.798 10.9299C14.5174 10.4445 14.1141 10.0415 13.6284 9.76124C13.1428 9.48102 12.592 9.3335 12.0313 9.3335C11.4795 9.3335 10.9371 9.4764 10.4569 9.74828C9.97665 10.0202 9.57503 10.4118 9.29112 10.885L8.37578 12.4105C7.83557 13.3109 7.10216 14.0801 6.22851 14.6625L6.17784 14.6963C5.78129 14.9606 5.45613 15.3188 5.23124 15.7391C5.00635 16.1593 4.88868 16.6285 4.88867 17.1051C4.88867 17.8729 5.19369 18.6093 5.73661 19.1522C6.27954 19.6951 7.01591 20.0002 7.78373 20.0002H8.13395C8.90176 20.0002 9.63813 19.6951 10.1811 19.1522Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M5.33333 11.111C6.06971 11.111 6.66667 10.514 6.66667 9.77767C6.66667 9.04129 6.06971 8.44434 5.33333 8.44434C4.59695 8.44434 4 9.04129 4 9.77767C4 10.514 4.59695 11.111 5.33333 11.111Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M18.6663 11.111C19.4027 11.111 19.9997 10.514 19.9997 9.77767C19.9997 9.04129 19.4027 8.44434 18.6663 8.44434C17.93 8.44434 17.333 9.04129 17.333 9.77767C17.333 10.514 17.93 11.111 18.6663 11.111Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M9.14242 6.66667C9.8788 6.66667 10.4757 6.06971 10.4757 5.33333C10.4757 4.59695 9.8788 4 9.14242 4C8.40604 4 7.80908 4.59695 7.80908 5.33333C7.80908 6.06971 8.40604 6.66667 9.14242 6.66667Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M14.8573 6.66667C15.5936 6.66667 16.1906 6.06971 16.1906 5.33333C16.1906 4.59695 15.5936 4 14.8573 4C14.1209 4 13.5239 4.59695 13.5239 5.33333C13.5239 6.06971 14.1209 6.66667 14.8573 6.66667Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_8920">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
