import * as Sentry from '@sentry/vue'
import { useLocalStorage } from '@vueuse/core'
import type { App } from 'vue'

import { EnvironmentMode } from '@/composables/environment.composable'

export function useSentryIntegration(app: App<Element>): void {
	if (import.meta.env.VITE_SENTRY_DSN === undefined) {
		return
	}

	if (import.meta.env.MODE === EnvironmentMode.DEVELOPMENT) {
		return
	}

	const importErrorOccurrences = useLocalStorage<number>('importErrorOccurrences', 0)

	return Sentry.init({
		app: app,
		dsn: import.meta.env.VITE_SENTRY_DSN,
		environment: import.meta.env.VITE_ENVIRONMENT,
		release: import.meta.env.VITE_APP_VERSION,
		integrations: [],
		tracesSampleRate: 0.1,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 0.1,
		sampleRate: 0.1,
		enabled: false,
		attachStacktrace: false,
		beforeSend(event, hint) {
			const originalException = hint.originalException as Error

			if (
				importErrorOccurrences.value < 3 &&
				originalException &&
				originalException.message &&
				(originalException.message.includes('Failed to fetch dynamically imported module') ||
					originalException.message.includes('Importing a module script failed'))
			) {
				importErrorOccurrences.value++
				window.location.reload()
				return null
			}

			importErrorOccurrences.value = 0

			return event
		},
	})
}
