<script setup lang="ts"></script>

<template>
	<svg
		fill="none"
		viewBox="0 0 32 32"
	>
		<path
			class="fill-current"
			d="M20.748 22H18.0215V17.25H13.9824V22H11.2402V10.625H13.9824V15.1406H18.0215V10.625H20.748V22Z"
		/>
	</svg>
</template>
