<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M10 17V9.00641C10 7.9013 10.8902 7.00402 11.9915 7.00002C13.0988 6.99502 14 7.8953 14 9.00641V16.0008"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M17 8V17"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M7 8V15"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M10 17V17.5C10 19.433 11.567 21 13.5 21C15.433 21 17 19.433 17 17.5V17"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M7 8C7 5.239 9.239 3 12 3C14.761 3 17 5.239 17 8"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<circle
			class="fill-vivid-red stroke-vivid-red"
			cx="17"
			cy="18"
			r="3.5"
		/>
		<path
			d="M15.5859 19.4141L18.4144 16.5856"
			stroke="white"
			stroke-linecap="round"
		/>
		<path
			d="M15.5859 16.5859L18.4144 19.4144"
			stroke="white"
			stroke-linecap="round"
		/>
	</svg>
</template>
