/* eslint-disable camelcase */
import type { Axios, AxiosInstance } from 'axios'

import { logError } from '@/utils/logger.util'

interface OAuth2ClientOptions {
	axios: Axios | AxiosInstance
	clientId: string
	clientSecret: string
	tokenEndpoint: string
	scopes?: string[]
}

export interface OAuth2ClientTokens {
	access_token: string
	expires_in: number
	refresh_token: string
	scope: string
	token_type: string
}

interface ClientOptions {
	client_id: string
	code?: string
	state?: string
	username?: string
	password?: string
	client_secret: string
	grant_type: GrantType
	scope?: string
}

export interface OAuth2ClientTokensWithExpiration extends OAuth2ClientTokens {
	expires_at: number
}

export type GrantType = 'ad' | 'password' | 'refresh_token'

export class TokenStore {
	private _promise: Promise<void> | null = null
	private isLoading = false

	constructor(
		private readonly options: OAuth2ClientOptions,
		tokens?: OAuth2ClientTokensWithExpiration
	) {
		this.setTokens(tokens)
	}

	public setTokens(tokens?: OAuth2ClientTokensWithExpiration): void {
		if (!tokens) {
			return
		}

		localStorage.setItem('tokens', JSON.stringify(tokens))
	}

	public async getAccessToken(): Promise<string> {
		if (this.accessTokenExpired()) {
			await this.refreshToken()
		}

		return this.getTokens().access_token
	}

	public clearTokens(): void {
		localStorage.removeItem('tokens')
	}

	public getTokens(): OAuth2ClientTokensWithExpiration {
		return JSON.parse(localStorage.getItem('tokens') as string) as OAuth2ClientTokensWithExpiration
	}

	public getRefreshToken(): string {
		return this.getTokens().refresh_token
	}

	private async refreshToken(): Promise<void> {
		if (this._promise != null) {
			return this._promise
		}

		this._promise = new Promise((resolve, reject) => {
			this.getNewAccessToken(this.getRefreshToken())
				.then((tokens) => {
					this.setTokens(tokens)
					resolve()
				})
				.catch(() => {
					logError('Failed to refresh access token, trying again...')

					setTimeout(() => {
						this.getNewAccessToken(this.getRefreshToken())
							.then((tokens) => {
								this.setTokens(tokens)
								resolve()
							})
							.catch(() => {
								reject(new Error('Failed to refresh access token'))
							})
					}, 1000)
				})
				.finally(() => {
					this._promise = null
				})
		})

		return this._promise
	}

	private async getNewAccessToken(refreshToken: string): Promise<OAuth2ClientTokensWithExpiration> {
		const response = await this.options.axios.post<OAuth2ClientTokens>(
			this.options.tokenEndpoint,
			{
				grant_type: 'refresh_token',
				refresh_token: refreshToken,
				client_id: this.options.clientId,
				client_secret: this.options.clientSecret,
				scope: this.options.scopes?.join(' '),
			},
			{
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			}
		)

		return {
			refresh_token: response.data.refresh_token,
			token_type: response.data.token_type,
			expires_in: response.data.expires_in,
			scope: response.data.scope,
			access_token: response.data.access_token,
			expires_at: Date.now() + response.data.expires_in * 1000,
		}
	}

	private accessTokenExpired(): boolean {
		return Date.now() >= this.getTokens().expires_at
	}
}

export class OAuth2Client {
	constructor(private readonly options: OAuth2ClientOptions) {}

	public async loginPassword(username: string, password: string): Promise<TokenStore> {
		return this.login({
			grant_type: 'password',
			username,
			password,
			client_id: this.options.clientId,
			client_secret: this.options.clientSecret,
			scope: this.options.scopes?.join(' '),
		})
	}

	public async loginAuthorization(code: string, state: string, grantType: GrantType): Promise<TokenStore> {
		return this.login({
			grant_type: grantType,
			code: code,
			state: state,
			client_id: this.options.clientId,
			client_secret: this.options.clientSecret,
			scope: this.options.scopes?.join(' '),
		})
	}

	private async login(clientOptions: ClientOptions): Promise<TokenStore> {
		const { data } = await this.options.axios.post<OAuth2ClientTokens>(this.options.tokenEndpoint, clientOptions, {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		})

		return new TokenStore(this.options, {
			...data,
			expires_at: Date.now() + data.expires_in * 1000,
		})
	}
}
