<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M8.55691 3.68684C13.1479 1.78484 18.4109 3.96584 20.3129 8.55684C22.2149 13.1478 20.0339 18.4108 15.4429 20.3128C10.8519 22.2148 5.58891 20.0338 3.68691 15.4428C1.78591 10.8518 3.96591 5.58884 8.55691 3.68684Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M11.7178 7.98486V12.6359L15.3738 14.8649"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_9144">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
