<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M11.9999 13.6647C11.7186 13.6647 11.5049 13.4397 11.5049 13.1697C11.5049 13.0797 11.5836 13.001 11.6736 13.001H12.3374C12.4274 13.001 12.5061 13.0797 12.5061 13.1697C12.5061 13.451 12.2811 13.6647 12.0111 13.6647H11.9999Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M17.9963 13.0014C17.9963 13.5526 18.4463 14.0026 18.9976 14.0026C20.1001 14.0026 21.0001 13.1026 21.0001 12.0001V8.9964C21.0001 7.34267 19.6613 5.99268 17.9963 5.99268H17.5801"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M8.05113 17.8496L7.00488 20.9996"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M15.9487 17.8496L16.995 20.9996"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M6.41998 6.00342H6.00373C4.34999 6.00342 3 7.34216 3 9.00715V12.0109C3 13.1134 3.89999 14.0134 5.00249 14.0134C5.55373 14.0134 6.00373 13.5634 6.00373 13.0121"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M14.002 17.8271V18.5021C14.002 19.3346 13.327 19.9984 12.5058 19.9984H11.5045C10.672 19.9984 10.0083 19.3234 10.0083 18.5021V17.8271"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M12.0001 13.6649V15.0037C12.0001 16.6574 10.6614 18.0074 8.9964 18.0074C7.34267 18.0074 5.99268 16.6687 5.99268 15.0037V8.00621C6.00393 5.23873 8.24266 3 10.9989 3H13.0014C15.7576 3 17.9963 5.23873 17.9963 7.99496V14.9924C17.9963 16.6462 16.6576 17.9961 14.9926 17.9961C13.3389 17.9961 11.9889 16.6574 11.9889 14.9924V13.6537L12.0001 13.6649Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="fill-current"
			d="M14.0024 10.3121C13.7774 10.3121 13.5749 10.2221 13.4286 10.0759C13.2711 9.91839 13.1924 9.71589 13.1924 9.50214C13.1924 9.05214 13.5636 8.68089 14.0024 8.69214C14.4411 8.69214 14.8124 9.05214 14.8124 9.50214C14.8124 9.95214 14.4411 10.3234 14.0024 10.3234V10.3121Z"
		/>
		<path
			class="fill-current"
			d="M9.99749 10.3121C9.7725 10.3121 9.57 10.2221 9.42375 10.0759C9.26625 9.91839 9.1875 9.71589 9.1875 9.50214C9.1875 9.05214 9.525 8.68089 9.99749 8.69214C10.2112 8.69214 10.4137 8.77089 10.5712 8.92839C10.7287 9.08589 10.8075 9.28839 10.8075 9.50214C10.8075 9.95214 10.4475 10.3121 9.99749 10.3121Z"
		/>

		<defs>
			<clipPath id="clip0_1366_8908">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
