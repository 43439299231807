<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M6.00676 12L3.75338 14.2534L1.5 12"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M3.75391 12C3.75391 12.6886 3.83492 13.3637 3.98344 14.0118"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M21.754 12C21.754 7.03449 17.7195 3 12.7539 3C7.7884 3 3.75391 7.03449 3.75391 12"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M12.7539 21C17.7194 21 21.7539 16.9655 21.7539 12"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M5.38672 17.1543C7.01941 19.4751 9.70457 20.9999 12.7541 20.9999"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M16.1269 14.8743L12.4702 12.7018V7.99268"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_9094">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
