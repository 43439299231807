<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="fill-current stroke-current"
			d="M12 2L15.0895 8.30842L22 9.32632L17 14.2337L18.18 21.1663L12 17.8916L5.82 21.1663L7 14.2337L2 9.32632L8.90947 8.30842L12 2Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_9220">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
