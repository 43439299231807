<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M13.5747 7.49316L9.49072 10.5511"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M16.4664 4.59867C17.2646 5.39691 17.2646 6.6911 16.4664 7.48933C15.6681 8.28756 14.374 8.28756 13.5757 7.48933C12.7775 6.6911 12.7775 5.3969 13.5757 4.59867C14.374 3.80044 15.6682 3.80044 16.4664 4.59867Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M13.5747 16.5062L9.49072 13.4482"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M16.4664 16.5108C17.2646 17.309 17.2646 18.6032 16.4664 19.4014C15.6681 20.1997 14.374 20.1997 13.5757 19.4014C12.7775 18.6032 12.7775 17.309 13.5757 16.5108C14.374 15.7126 15.6682 15.7126 16.4664 16.5108Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M9.48589 10.5561C10.2833 11.3535 10.2833 12.6465 9.48589 13.4439C8.68845 14.2413 7.39553 14.2413 6.59809 13.4439C5.80064 12.6465 5.80064 11.3535 6.59809 10.5561C7.39553 9.75865 8.68845 9.75865 9.48589 10.5561Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
