<script lang="ts" setup></script>

<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M21 21L21.578 19.844C21.8556 19.2887 22.0001 18.6765 22 18.0557V11.5C22 11.1022 21.842 10.7206 21.5607 10.4393C21.2794 10.158 20.8978 10 20.5 10C20.1022 10 19.7206 10.158 19.4393 10.4393C19.158 10.7206 19 11.1022 19 11.5V15.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M14 21V18.0866C14 17.6539 14.1404 17.2328 14.4 16.8866L16.0779 14.6493C16.2175 14.4633 16.3954 14.3094 16.5996 14.198C16.8038 14.0867 17.0295 14.0206 17.2615 14.0041C17.4935 13.9876 17.7264 14.0212 17.9443 14.1025C18.1622 14.1838 18.36 14.311 18.5245 14.4755C18.8008 14.7518 18.9682 15.1185 18.9959 15.5083C19.0236 15.8981 18.9098 16.2848 18.6753 16.5974L17.6234 18"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M3 21L2.422 19.844C2.14439 19.2887 1.99991 18.6765 2 18.0557V11.5C2 11.1022 2.15804 10.7206 2.43934 10.4393C2.72064 10.158 3.10218 10 3.5 10C3.89782 10 4.27936 10.158 4.56066 10.4393C4.84196 10.7206 5 11.1022 5 11.5V15.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M9.99998 21V18.0866C9.99998 17.6539 9.85962 17.2328 9.59998 16.8866L7.92204 14.6493C7.78249 14.4633 7.60459 14.3094 7.40038 14.198C7.19618 14.0867 6.97043 14.0206 6.73843 14.0041C6.50644 13.9876 6.27361 14.0212 6.05571 14.1025C5.83781 14.1838 5.63993 14.311 5.47547 14.4755C5.19916 14.7518 5.03179 15.1185 5.00409 15.5083C4.97639 15.8981 5.09022 16.2848 5.32468 16.5974L6.37663 18"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M16.5 6.7C16.5 6.43478 16.3946 6.18043 16.2071 5.99289C16.0196 5.80536 15.7652 5.7 15.5 5.7H13.8V4C13.8 3.73478 13.6946 3.48043 13.5071 3.29289C13.3196 3.10536 13.0652 3 12.8 3H11.2C10.9348 3 10.6804 3.10536 10.4929 3.29289C10.3054 3.48043 10.2 3.73478 10.2 4V5.7H8.5C8.23478 5.7 7.98043 5.80536 7.79289 5.99289C7.60536 6.18043 7.5 6.43478 7.5 6.7V8.3C7.5 8.56522 7.60536 8.81957 7.79289 9.00711C7.98043 9.19464 8.23478 9.3 8.5 9.3H10.2V11C10.2 11.2652 10.3054 11.5196 10.4929 11.7071C10.6804 11.8946 10.9348 12 11.2 12H12.8C13.0652 12 13.3196 11.8946 13.5071 11.7071C13.6946 11.5196 13.8 11.2652 13.8 11V9.3H15.5C15.6313 9.3 15.7614 9.27413 15.8827 9.22388C16.004 9.17363 16.1142 9.09997 16.2071 9.00711C16.3 8.91425 16.3736 8.80401 16.4239 8.68268C16.4741 8.56136 16.5 8.43132 16.5 8.3V6.7Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
