<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M10.5 21V3.5C10.5 3.10218 10.658 2.72064 10.9393 2.43934C11.2206 2.15804 11.6022 2 12 2C12.3978 2 12.7794 2.15804 13.0607 2.43934C13.342 2.72064 13.5 3.10218 13.5 3.5V21"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M20 21H4"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M10.5 11H5.50001C5.36868 11 5.23865 10.9741 5.11732 10.9239C4.996 10.8736 4.88576 10.8 4.7929 10.7071L3.2929 9.20711C3.20004 9.11425 3.12638 9.00401 3.07612 8.88269C3.02587 8.76136 3 8.63132 3 8.5C3 8.36868 3.02587 8.23864 3.07612 8.11731C3.12638 7.99599 3.20004 7.88575 3.2929 7.79289L4.7929 6.29289C4.88576 6.20003 4.996 6.12637 5.11732 6.07612C5.23865 6.02586 5.36868 6 5.50001 6H10.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M13.5 11H18.5C18.6313 11 18.7614 10.9741 18.8827 10.9239C19.004 10.8736 19.1143 10.8 19.2071 10.7071L20.7071 9.20711C20.8 9.11425 20.8736 9.00401 20.9239 8.88269C20.9741 8.76136 21 8.63132 21 8.5C21 8.36868 20.9741 8.23864 20.9239 8.11731C20.8736 7.99599 20.8 7.88575 20.7071 7.79289L19.2071 6.29289C19.1143 6.20003 19.004 6.12637 18.8827 6.07612C18.7614 6.02586 18.6313 6 18.5 6H13.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_9273">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
