<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M6 18L9.08303 14.917"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M7.0272 11.8579C6.75135 12.1338 6.75135 12.5804 7.0272 12.8555L9.08584 14.9141L11.1445 16.9728C11.4203 17.2486 11.8669 17.2486 12.1421 16.9728L12.971 16.1438C13.1037 16.0112 13.1777 15.832 13.1777 15.645V12.8682L15.2237 10.8223L16.8527 10.909C17.0523 10.9196 17.2478 10.8448 17.3889 10.7037L17.7938 10.2988C18.0697 10.0229 18.0697 9.57635 17.7938 9.3012L14.6995 6.20689C14.4236 5.93104 13.9771 5.93104 13.7019 6.20689L13.297 6.61184C13.1552 6.75365 13.0804 6.94837 13.0917 7.14802L13.1784 8.77702L11.1325 10.823H8.35495C8.16799 10.823 7.98809 10.897 7.85616 11.0297L7.0272 11.8579Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M15.2207 10.8252L13.1748 8.7793"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_9030">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
