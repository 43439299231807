<script lang="ts" setup></script>

<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<g clip-path="url(#clip0_1766_7019)">
			<path
				class="stroke-current"
				d="M17.4452 12.7781L19.7782 10.4451C21.4962 8.72709 21.4962 5.94109 19.7782 4.22209C18.0602 2.50409 15.2742 2.50409 13.5552 4.22209L11.2222 6.55509"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				class="stroke-current"
				d="M8.89014 15.1099L15.1101 8.88989"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				class="stroke-current"
				d="M6.55509 11.2219L4.22209 13.5549C2.50409 15.2729 2.50409 18.0589 4.22209 19.7779C5.94009 21.4959 8.72609 21.4959 10.4451 19.7779L12.7781 17.4449"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</g>
	</svg>
</template>
