<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M10.8561 13.1439C9.68615 11.9739 8.80415 10.6639 8.21815 9.33487C8.09415 9.05387 8.16715 8.72487 8.38415 8.50787L9.20315 7.68987C9.87415 7.01887 9.87415 6.06987 9.28815 5.48387L8.11415 4.30987C7.33315 3.52887 6.06715 3.52887 5.28615 4.30987L4.63415 4.96187C3.89315 5.70287 3.58415 6.77187 3.78415 7.83187C4.27815 10.4449 5.79615 13.3059 8.24515 15.7549C10.6941 18.2039 13.5551 19.7219 16.1681 20.2159C17.2281 20.4159 18.2971 20.1069 19.0381 19.3659L19.6891 18.7149C20.4701 17.9339 20.4701 16.6679 19.6891 15.8869L18.5161 14.7139C17.9301 14.1279 16.9801 14.1279 16.3951 14.7139L15.4921 15.6179C15.2751 15.8349 14.9461 15.9079 14.6651 15.7839C13.3361 15.1969 12.0261 14.3139 10.8561 13.1439Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_9108">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
