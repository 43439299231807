<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M20 10.5498V20.9998"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M22 12.0002L12.625 5.18517C12.2751 4.93177 11.8019 4.93177 11.452 5.18517L2 12.0002"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M19.5 9.743V4.5C19.5 4.22386 19.2761 4 19 4H16C15.7239 4 15.5 4.22386 15.5 4.5V7.109"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M22 21H2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M4 10.5601V21.0001"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M17.2239 15H13.7761C13.6236 15 13.5 15.1236 13.5 15.2761V17.7239C13.5 17.8764 13.6236 18 13.7761 18H17.2239C17.3764 18 17.5 17.8764 17.5 17.7239V15.2761C17.5 15.1236 17.3764 15 17.2239 15Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M7 21V15.5C7 15.2239 7.22386 15 7.5 15H10C10.2761 15 10.5 15.2239 10.5 15.5V21"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_9238">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
