<script lang="ts" setup></script>

<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="fill-vivid-red"
			d="M12 3C16.97 3 21 7.03 21 12C21 16.97 16.97 21 12 21C7.03 21 3 16.97 3 12C3 7.03 7.03 3 12 3Z"
		/>
		<path
			class="fill-white"
			d="M12 12.5V7.5Z"
		/>
		<path
			class="stroke-white"
			d="M12 12.5V7.5"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="1.5"
		/>
		<path
			class="fill-white stroke-white"
			d="M12 16.75C11.7261 16.75 11.5 16.5239 11.5 16.25C11.5 16.1224 11.5478 15.9946 11.6525 15.8844C11.7343 15.7983 11.8456 15.75 12 15.75C12.2739 15.75 12.5 15.9761 12.5 16.25C12.5 16.5239 12.2739 16.75 12 16.75Z"
		/>
	</svg>
</template>
