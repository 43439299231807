<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M20 12.7582V5.0332"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M13.028 17.0002H5C3.343 17.0002 2 15.6572 2 14.0002V5.0332"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M9.136 10.018L3.085 6.831C2.418 6.479 2 5.787 2 5.033V5C2 3.895 2.895 3 4 3H17.967C19.09 3 20 3.91 20 5.033V5.053C20 5.795 19.589 6.477 18.932 6.823L12.864 10.019C11.697 10.633 10.303 10.633 9.136 10.018Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M19.25 15.6519L17.062 17.8399L15.75 16.5269"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M20.6818 13.3218C22.4388 15.0838 22.4388 17.9418 20.6828 19.7048C18.9628 21.4308 16.0378 21.4308 14.3168 19.7048C12.5598 17.9418 12.5608 15.0838 14.3178 13.3218C16.0748 11.5588 18.9248 11.5588 20.6818 13.3218Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_9333">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
