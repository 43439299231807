<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M9.76389 19.1394H6.39721C5.47031 19.1394 4.71387 18.383 4.71387 17.4561V5.68334C4.71387 4.75644 5.47031 4 6.39721 4H16.6038C17.5307 4 18.2871 4.75644 18.2871 5.68334L18.2871 9.07495"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M14.9316 9.8916H8.43262"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M11.9911 12.832H8.43262"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M14.9316 6.94043H8.43262"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M9.88157 15.7729H8.43262"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M5.44895 15.7729H4"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M5.44895 12.832H4"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M5.44895 9.8916H4"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M5.44895 6.94043H4"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M17.3608 11.5648L12.3321 16.5936C12.0125 16.9132 11.7994 17.3393 11.7355 17.7868L11.5544 19.076C11.5224 19.3103 11.6822 19.5234 11.9166 19.5554C11.9592 19.5554 11.9912 19.5554 12.0338 19.5554L13.3229 19.3743C13.7704 19.3103 14.1966 19.0973 14.5162 18.7776L19.5449 13.7489C20.1522 13.1416 20.1522 12.1721 19.5449 11.5648C18.9376 10.9576 17.9681 10.9576 17.3608 11.5648Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
