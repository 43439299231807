<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<circle
			class="stroke-current"
			cx="12"
			cy="9"
			r="6.5"
		/>
		<path
			class="stroke-current"
			d="M9 19H15"
			stroke-linecap="round"
		/>
		<path
			class="stroke-current"
			d="M12 22L12 16"
			stroke-linecap="round"
		/>
	</svg>
</template>
