<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M19 20.5H5C3.895 20.5 3 19.605 3 18.5V9.5C3 8.395 3.895 7.5 5 7.5H19C20.105 7.5 21 8.395 21 9.5V18.5C21 19.605 20.105 20.5 19 20.5Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M16.1742 7.5V5.5C16.1742 4.395 15.2792 3.5 14.1742 3.5H9.82617C8.72117 3.5 7.82617 4.395 7.82617 5.5V7.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M3 9.5L9.351 13.691C9.678 13.907 10.061 14.022 10.453 14.022H13.547C13.939 14.022 14.322 13.907 14.649 13.691L21 9.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_9241">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
