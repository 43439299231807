<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M18.9984 8.44497V11.1561C18.6056 11.0768 18.1997 11.1032 17.8205 11.2327C17.1157 11.4736 16.581 12.0552 16.4001 12.7777C16.0956 13.9689 16.8144 15.1815 18.0057 15.486C18.1851 15.5318 18.3695 15.5551 18.5546 15.5553C18.7039 15.5551 18.8528 15.5401 18.9992 15.5107V18.2218C18.9992 19.2033 18.2035 19.9992 17.222 19.9992M17.222 19.9992C17.2219 19.9992 17.222 19.9992 17.222 19.9992ZM17.222 19.9992H14.5106C14.5899 19.6064 14.5634 19.2005 14.434 18.8213C14.193 18.1165 13.6114 17.5818 12.889 17.4009C11.6977 17.0964 10.4852 17.8152 10.1807 19.0065C10.1348 19.1859 10.1116 19.3703 10.1114 19.5554C10.1116 19.7047 10.1265 19.8536 10.156 20L7.44585 19.9992C6.46449 19.9993 5.66876 19.204 5.66848 18.2226V15.5115C5.52212 15.541 5.37321 15.5559 5.22392 15.5561C3.99439 15.5548 2.9987 14.557 3 13.3275C3.0002 13.1423 3.02349 12.9579 3.06935 12.7785C3.25032 12.0561 3.78499 11.4745 4.48973 11.2335C4.86893 11.1041 5.27571 11.0776 5.66847 11.1569L5.66763 8.44581C5.66763 7.46428 6.46333 6.66844 7.44487 6.66844M7.44487 6.66844C7.44496 6.66844 7.44478 6.66844 7.44487 6.66844ZM7.44487 6.66844H10.1562C10.1268 6.52208 10.1119 6.37318 10.1116 6.22389C10.1129 4.99435 11.1107 3.99873 12.3402 4C12.5253 4.00019 12.7097 4.02347 12.889 4.06929C13.6112 4.25078 14.1926 4.78526 14.434 5.48968C14.5635 5.86888 14.5899 6.27565 14.5106 6.66842H17.2217C18.2033 6.6683 18.9991 7.46389 18.9992 8.44544M18.9992 8.44544C18.9992 8.44556 18.9992 8.44531 18.9992 8.44544Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
