<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M12.2002 10.5996H14.6002"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M12.2002 8.2002H14.6002"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M9.40039 8.2002H7.80039C7.57948 8.2002 7.40039 8.37928 7.40039 8.6002V10.2002C7.40039 10.4211 7.57948 10.6002 7.80039 10.6002H9.40039C9.6213 10.6002 9.80039 10.4211 9.80039 10.2002V8.6002C9.80039 8.37928 9.6213 8.2002 9.40039 8.2002Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M7.40039 13.3999H14.6004"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M14.6004 16.2002H7.40039"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M17 9H17.8C18.2243 9 18.6313 9.16857 18.9314 9.46863C19.2314 9.76869 19.4 10.1757 19.4 10.6V17.8C19.4 18.2243 19.2314 18.6313 18.9314 18.9314C18.6313 19.2314 18.2243 19.4 17.8 19.4H6.6C6.17565 19.4 5.76869 19.2314 5.46863 18.9314C5.16857 18.6313 5 18.2243 5 17.8V6.6C5 6.17565 5.16857 5.76869 5.46863 5.46863C5.76869 5.16857 6.17565 5 6.6 5H15.4C15.8243 5 16.2313 5.16857 16.5314 5.46863C16.8314 5.76869 17 6.17565 17 6.6V19.4"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_8880">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
