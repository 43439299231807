<script setup lang="ts"></script>

<template>
	<svg
		fill="none"
		viewBox="0 0 32 32"
	>
		<path
			class="fill-current"
			d="M8.25 14.5C7.42 14.5 6.75 15.17 6.75 16C6.75 16.83 7.42 17.5 8.25 17.5C9.08 17.5 9.75 16.83 9.75 16C9.75 15.17 9.08 14.5 8.25 14.5ZM8.25 8.5C7.42 8.5 6.75 9.17 6.75 10C6.75 10.83 7.42 11.5 8.25 11.5C9.08 11.5 9.75 10.83 9.75 10C9.75 9.17 9.08 8.5 8.25 8.5ZM8.25 20.5C7.42 20.5 6.75 21.18 6.75 22C6.75 22.82 7.43 23.5 8.25 23.5C9.07 23.5 9.75 22.82 9.75 22C9.75 21.18 9.08 20.5 8.25 20.5ZM11.25 23H25.25V21H11.25V23ZM11.25 17H25.25V15H11.25V17ZM11.25 9V11H25.25V9H11.25Z"
		/>
	</svg>
</template>
