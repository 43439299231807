<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M8.64226 21.0965L21.096 8.64274C21.9712 7.76757 21.9712 6.34864 21.096 5.47347L18.5268 2.90421C17.6516 2.02904 16.2327 2.02904 15.3575 2.9042L2.90373 15.358C2.02856 16.2331 2.02856 17.6521 2.90373 18.5272L5.473 21.0965C6.34817 21.9717 7.76709 21.9717 8.64226 21.0965Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M17.74 12.0003L12 6.26025"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M6.26025 12L12.0003 17.74"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M4.0002 9.95028C3.97258 9.95028 3.9502 9.97267 3.9502 10.0003C3.9502 10.0279 3.97258 10.0503 4.0002 10.0503C4.02781 10.0503 4.0502 10.0279 4.0502 10.0003C4.05177 9.97424 4.03193 9.95185 4.00589 9.95028C4.00399 9.95017 4.00209 9.95017 4.0002 9.95028Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M10.0502 4.0002C10.0502 4.02781 10.0278 4.0502 10.0002 4.0502C9.97258 4.0502 9.9502 4.02781 9.9502 4.0002C9.9502 3.97258 9.97258 3.9502 10.0002 3.9502"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M10 3.9502C10.0276 3.9502 10.05 3.97258 10.05 4.0002"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M7.0502 3.0002C7.0502 3.02781 7.02781 3.0502 7.0002 3.0502C6.97258 3.0502 6.9502 3.02781 6.9502 3.0002C6.9502 2.97258 6.97258 2.9502 7.0002 2.9502"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M7 2.9502C7.02761 2.9502 7.05 2.97258 7.05 3.0002"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M4.0502 4.0002C4.0502 4.02781 4.02781 4.0502 4.0002 4.0502C3.97258 4.0502 3.9502 4.02781 3.9502 4.0002C3.9502 3.97258 3.97258 3.9502 4.0002 3.9502"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M4 3.9502C4.02761 3.9502 4.05 3.97258 4.05 4.0002"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M3.0502 7.0002C3.0502 7.02781 3.02781 7.0502 3.0002 7.0502C2.97258 7.0502 2.9502 7.02781 2.9502 7.0002C2.9502 6.97258 2.97258 6.9502 3.0002 6.9502"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M3 6.9502C3.02761 6.9502 3.05 6.97258 3.05 7.0002"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M4.0502 10.0002C4.0502 10.0278 4.02781 10.0502 4.0002 10.0502C3.97258 10.0502 3.9502 10.0278 3.9502 10.0002C3.9502 9.97258 3.97258 9.9502 4.0002 9.9502"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M4 9.9502C4.02761 9.9502 4.05 9.97258 4.05 10.0002"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M20.0003 14.0502C20.0279 14.0502 20.0503 14.0278 20.0503 14.0002C20.0503 13.9726 20.0279 13.9502 20.0003 13.9502C19.9727 13.9502 19.9503 13.9726 19.9503 14.0002C19.9487 14.0262 19.9685 14.0486 19.9946 14.0502C19.9965 14.0503 19.9984 14.0503 20.0003 14.0502Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M13.9502 20.0002C13.9502 19.9726 13.9726 19.9502 14.0002 19.9502C14.0278 19.9502 14.0502 19.9726 14.0502 20.0002C14.0502 20.0278 14.0278 20.0502 14.0002 20.0502"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M14.0002 20.05C13.9726 20.05 13.9502 20.0276 13.9502 20"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M16.9502 21.0002C16.9502 20.9726 16.9726 20.9502 17.0002 20.9502C17.0278 20.9502 17.0502 20.9726 17.0502 21.0002C17.0502 21.0278 17.0278 21.0502 17.0002 21.0502"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M17.0002 21.05C16.9726 21.05 16.9502 21.0276 16.9502 21"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M19.9502 20.0002C19.9502 19.9726 19.9726 19.9502 20.0002 19.9502C20.0278 19.9502 20.0502 19.9726 20.0502 20.0002C20.0502 20.0278 20.0278 20.0502 20.0002 20.0502"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M20.0002 20.05C19.9726 20.05 19.9502 20.0276 19.9502 20"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M20.9502 17.0002C20.9502 16.9726 20.9726 16.9502 21.0002 16.9502C21.0278 16.9502 21.0502 16.9726 21.0502 17.0002C21.0502 17.0278 21.0278 17.0502 21.0002 17.0502"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M21.0002 17.05C20.9726 17.05 20.9502 17.0276 20.9502 17"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M19.9502 14.0002C19.9502 13.9726 19.9726 13.9502 20.0002 13.9502C20.0278 13.9502 20.0502 13.9726 20.0502 14.0002C20.0502 14.0278 20.0278 14.0502 20.0002 14.0502"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M20.0002 14.05C19.9726 14.05 19.9502 14.0276 19.9502 14"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_9226">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
