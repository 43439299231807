<script setup lang="ts"></script>

<template>
	<svg
		fill="none"
		viewBox="0 0 32 32"
	>
		<path
			class="fill-current"
			d="M21.7822 22H18.8525V15.9062H13.1396V22H10.21V7.78125H13.1396V13.543H18.8525V7.78125H21.7822V22Z"
		/>
	</svg>
</template>
