<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M14.8901 10.614V9.41525C14.8901 8.99679 14.5491 8.65586 14.1307 8.65586H9.05498C8.47529 8.65586 8.00391 8.18448 8.00391 7.60479C8.00391 7.0251 8.47529 6.55371 9.05498 6.55371H14.1307C15.7085 6.55371 16.9922 7.8374 16.9922 9.41525V10.614"
			stroke-miterlimit="10"
		/>
		<path
			class="stroke-current"
			d="M4.64725 18.1747C4.59556 18.1747 4.55371 18.1329 4.55371 18.0812C4.55371 14.2043 7.70817 11.0498 11.5851 11.0498H18.2337C18.2756 11.0498 18.3125 11.0781 18.3236 11.1175L19.4386 17.6258C19.462 17.7649 19.4251 17.9003 19.3352 18.0073C19.2442 18.1144 19.1174 18.1747 18.9759 18.1747H4.64725Z"
			stroke-miterlimit="10"
		/>

		<defs>
			<clipPath id="clip0_1366_8899">
				<rect fill="white" />
			</clipPath>
			<clipPath id="clip1_1366_8899">
				<rect
					fill="white"
					height="12.7286"
					transform="translate(4 6)"
					width="16"
				/>
			</clipPath>
		</defs>
	</svg>
</template>
