<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M5.98 21H4C3.45 21 3 20.55 3 20V14C3 13.45 3.45 13 4 13H5.98C6.53 13 6.98 13.45 6.98 14V20C6.98 20.55 6.53 21 5.98 21Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M12.9602 17.0001H15.2802C15.7102 17.0001 16.1302 16.8601 16.4702 16.6001L18.8802 14.7901C19.4802 14.3401 20.3202 14.4001 20.8502 14.9301C21.4402 15.5201 21.4402 16.4801 20.8502 17.0701L18.7902 19.1401C18.2302 19.7001 17.5302 20.0801 16.7502 20.2301L13.8302 20.8201C13.2502 20.9401 12.6502 20.9201 12.0802 20.7801L9.44021 20.1201C9.13021 20.0401 8.80022 20.0001 8.47022 20.0001H6.97021"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M12.96 17H14.44C15.27 17 15.95 16.32 15.95 15.48V15.18C15.95 14.49 15.48 13.88 14.81 13.71L12.53 13.14C12.16 13.05 11.78 13 11.4 13C10.48 13 9.57 13.27 8.81 13.79L7 15"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M17.1197 12.2496C17.1197 10.7896 15.9397 9.59961 14.4697 9.59961"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M14.4697 9.59971C15.9297 9.59971 17.1197 8.41971 17.1197 6.94971"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M17.1196 6.95947C17.1196 8.41947 18.2996 9.60947 19.7696 9.60947"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M19.7599 9.59961C18.2999 9.59961 17.1099 10.7796 17.1099 12.2496"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M13.2497 6.09961C11.2897 6.09961 9.69971 7.68961 9.69971 9.64961"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M9.6999 9.64961C9.6999 7.68961 8.1099 6.09961 6.1499 6.09961"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M6.1499 6.0998C8.1099 6.0998 9.6999 4.5098 9.6999 2.5498"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M9.69971 2.5498C9.69971 4.5098 11.2897 6.0998 13.2497 6.0998"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_9281">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
