<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M12.1875 9.187C9.84842 7.51624 7.53322 6.96727 6.49893 8.00156C5.17028 9.33021 6.45915 12.7513 9.3631 15.6553C12.2671 18.5592 15.6961 19.8401 17.0168 18.5194C18.0511 17.4851 17.4942 15.1779 15.8314 12.8309"
			stroke-miterlimit="10"
		/>
		<path
			class="stroke-current"
			d="M18.7586 6.25856C20.1827 7.68269 20.1906 9.99789 18.7586 11.43C17.1037 13.0848 15.6796 12.2495 14.0009 13.9282C13.2609 14.3817 12.338 13.8407 11.7573 13.2599C11.1765 12.6791 10.6355 11.7562 11.0889 11.0163C12.7677 9.33755 11.9323 7.91342 13.5871 6.25856C15.0113 4.83443 17.3265 4.82648 18.7586 6.25856Z"
			stroke-miterlimit="10"
		/>
		<path
			class="stroke-current"
			d="M10.3494 16.6418C10.5164 17.4613 10.2857 18.3285 9.64922 18.965C8.65472 19.9595 7.03964 19.9675 6.03718 18.965C5.03472 17.9625 5.03472 16.3554 6.03718 15.353C6.65775 14.7324 7.52496 14.5017 8.32852 14.6528"
			stroke-miterlimit="10"
		/>

		<defs>
			<clipPath id="clip0_1366_9293">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
