<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M16 3H8C5.23858 3 3 5.23858 3 8V16C3 18.7614 5.23858 21 8 21H16C18.7614 21 21 18.7614 21 16V8C21 5.23858 18.7614 3 16 3Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M15.25 9.75V14.25"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M15.25 9.75H16.9C17.258 9.75 17.6014 9.89223 17.8546 10.1454C18.1078 10.3986 18.25 10.742 18.25 11.1C18.25 11.458 18.1078 11.8014 17.8546 12.0546C17.6014 12.3078 17.258 12.45 16.9 12.45H15.252"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M12.1499 9.75V14.25"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M6.25 9.75H9.25L6.25 14.25H9.25"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_9267">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
