<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M16.5532 5H21.0001V9.44684"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M14.7764 11.2234L20.9997 5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M19.2233 14.7763V19.2231C19.2233 20.2052 18.4288 20.9996 17.4467 20.9996H6.77653C5.79447 20.9996 5 20.2052 5 19.2231V8.5529C5 7.57084 5.79447 6.77637 6.77653 6.77637H11.2234"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_9088">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
