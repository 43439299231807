<script lang="ts" setup></script>

<template>
	<svg
		fill="none"
		viewBox="0 0 12 12"
	>
		<path
			class="fill-lima"
			d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z"
		/>
		<path
			d="M8 5L5.50495 8L4 6.20635"
			stroke="white"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="1.5"
		/>
	</svg>
</template>
