<script lang="ts" setup></script>

<template>
	<svg
		fill="none"
		viewBox="0 0 25 24"
	>
		<g clip-path="url(#clip0_2017_4392)">
			<path
				class="stroke-current"
				d="M17.879 4.62095C18.7071 5.44954 18.7071 6.79237 17.879 7.62095L7.892 17.608C7.63571 17.8642 7.31461 18.046 6.963 18.134L3.5 19L4.366 15.537C4.45392 15.1853 4.63573 14.8642 4.892 14.608L14.88 4.62095C15.7082 3.79302 17.0508 3.79302 17.879 4.62095Z"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				class="stroke-current"
				d="M16 9.5L13 6.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				class="stroke-current"
				d="M21.4998 18L20.4058 19.094C19.1976 20.3019 17.239 20.3019 16.0308 19.094C14.8211 17.8887 12.8645 17.8887 11.6548 19.094"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_2017_4392">
				<rect
					fill="white"
					height="24"
					transform="translate(0.5)"
					width="24"
				/>
			</clipPath>
		</defs>
	</svg>
</template>
