<script setup lang="ts"></script>

<template>
	<svg
		fill="none"
		viewBox="0 0 28 28"
	>
		<path
			class="fill-current"
			d="M17.15 12.9412C17.9988 12.355 18.5938 11.3925 18.5938 10.5C18.5938 8.5225 17.0625 7 15.0938 7H9.625V19.25H15.785C17.6137 19.25 19.0312 17.7625 19.0312 15.9338C19.0312 14.6038 18.2788 13.4662 17.15 12.9412V12.9412ZM12.25 9.1875H14.875C15.6012 9.1875 16.1875 9.77375 16.1875 10.5C16.1875 11.2262 15.6012 11.8125 14.875 11.8125H12.25V9.1875ZM15.3125 17.0625H12.25V14.4375H15.3125C16.0387 14.4375 16.625 15.0238 16.625 15.75C16.625 16.4762 16.0387 17.0625 15.3125 17.0625Z"
		/>
	</svg>
</template>
