<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M19 8.1665H4"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M4 16.5V6.5C4 5.11814 5.11814 4 6.5 4H16.5C17.8819 4 19 5.11814 19 6.5V16.5C19 17.8819 17.8819 19 16.5 19H6.5C5.11814 19 4 17.8819 4 16.5Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M7.18546 11.0783C7.18546 11.0783 7.16437 11.1205 7.14327 11.1205C7.12217 11.1205 7.10107 11.0994 7.10107 11.0783C7.10107 11.0572 7.12217 11.0361 7.14327 11.0361"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M7.14355 11.0361C7.14355 11.0361 7.18575 11.0572 7.18575 11.0783"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M7.18546 13.5783C7.18546 13.5783 7.16437 13.6205 7.14327 13.6205C7.12217 13.6205 7.10107 13.5994 7.10107 13.5783C7.10107 13.5572 7.12217 13.5361 7.14327 13.5361"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M7.14355 13.5361C7.14355 13.5361 7.18575 13.5572 7.18575 13.5783"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M7.18546 16.0783C7.18546 16.0783 7.16437 16.1205 7.14327 16.1205C7.12217 16.1205 7.10107 16.0994 7.10107 16.0783C7.10107 16.0572 7.12217 16.0361 7.14327 16.0361"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M7.14355 16.0361C7.14355 16.0361 7.18575 16.0572 7.18575 16.0783"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M10.0863 11.0783C10.0863 11.0783 10.0652 11.1205 10.0441 11.1205C10.0231 11.1205 10.002 11.0994 10.002 11.0783C10.002 11.0572 10.0231 11.0361 10.0441 11.0361"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M10.0444 11.0361C10.0444 11.0361 10.0866 11.0572 10.0866 11.0783"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M10.0863 13.5783C10.0863 13.5783 10.0652 13.6205 10.0441 13.6205C10.0231 13.6205 10.002 13.5994 10.002 13.5783C10.002 13.5572 10.0231 13.5361 10.0441 13.5361"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M10.0444 13.5361C10.0444 13.5361 10.0866 13.5572 10.0866 13.5783"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M10.0863 16.0783C10.0863 16.0783 10.0652 16.1205 10.0441 16.1205C10.0231 16.1205 10.002 16.0994 10.002 16.0783C10.002 16.0572 10.0231 16.0361 10.0441 16.0361"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M10.0444 16.0361C10.0444 16.0361 10.0866 16.0572 10.0866 16.0783"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M12.9872 11.0783C12.9872 11.0783 12.9661 11.1205 12.945 11.1205C12.9239 11.1205 12.9028 11.0994 12.9028 11.0783C12.9028 11.0572 12.9239 11.0361 12.945 11.0361"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M12.9453 11.0361C12.9453 11.0361 12.9875 11.0572 12.9875 11.0783"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M12.9872 13.5783C12.9872 13.5783 12.9661 13.6205 12.945 13.6205C12.9239 13.6205 12.9028 13.5994 12.9028 13.5783C12.9028 13.5572 12.9239 13.5361 12.945 13.5361"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M12.9453 13.5361C12.9453 13.5361 12.9875 13.5572 12.9875 13.5783"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M12.9872 16.0783C12.9872 16.0783 12.9661 16.1205 12.945 16.1205C12.9239 16.1205 12.9028 16.0994 12.9028 16.0783C12.9028 16.0572 12.9239 16.0361 12.945 16.0361"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M12.9453 16.0361C12.9453 16.0361 12.9875 16.0572 12.9875 16.0783"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M15.8881 11.0783C15.8881 11.0783 15.867 11.1205 15.8459 11.1205C15.8248 11.1205 15.8037 11.0994 15.8037 11.0783C15.8037 11.0572 15.8248 11.0361 15.8459 11.0361"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M15.8462 11.0361C15.8462 11.0361 15.8884 11.0572 15.8884 11.0783"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M15.8881 13.5783C15.8881 13.5783 15.867 13.6205 15.8459 13.6205C15.8248 13.6205 15.8037 13.5994 15.8037 13.5783C15.8037 13.5572 15.8248 13.5361 15.8459 13.5361"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M15.8462 13.5361C15.8462 13.5361 15.8884 13.5572 15.8884 13.5783"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M15.8881 16.0783C15.8881 16.0783 15.867 16.1205 15.8459 16.1205C15.8248 16.1205 15.8037 16.0994 15.8037 16.0783C15.8037 16.0572 15.8248 16.0361 15.8459 16.0361"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M15.8462 16.0361C15.8462 16.0361 15.8884 16.0572 15.8884 16.0783"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
