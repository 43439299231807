<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M15.0711 6.41879L17.4562 4.37126C18.0974 3.82 19.0762 3.89875 19.6275 4.54001C19.8637 4.82126 19.9987 5.17002 19.9987 5.54127V13.0001C19.9987 17.4215 16.4212 20.999 11.9998 20.999C7.57853 20.999 4.00098 17.4215 4.00098 13.0001V5.54127C4.00098 4.69751 4.68724 4 5.54225 4C5.91351 4 6.26226 4.135 6.54352 4.37126L8.92855 6.41879C10.9198 5.71003 13.0911 5.71003 15.0824 6.41879H15.0711Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="fill-current"
			d="M9.99751 11.56C9.54751 11.56 9.1875 11.2 9.1875 10.75C9.1875 10.2999 9.54751 9.93994 9.99751 9.93994C10.4475 9.93994 10.8075 10.2999 10.8075 10.75C10.8075 10.9637 10.7175 11.1775 10.5713 11.3237C10.4138 11.4812 10.2113 11.56 9.99751 11.56Z"
		/>
		<path
			class="fill-current"
			d="M14.0024 11.56C13.7886 11.56 13.5861 11.47 13.4286 11.3237C13.2711 11.1662 13.1924 10.9637 13.1924 10.75C13.1924 10.2999 13.5524 9.93994 14.0024 9.93994C14.4524 9.93994 14.8124 10.2999 14.8124 10.75C14.8124 10.9637 14.7224 11.1775 14.5762 11.3237C14.4187 11.4812 14.2161 11.56 14.0024 11.56Z"
		/>
		<path
			class="stroke-current"
			d="M11.9999 14.4963C11.7186 14.4963 11.5049 14.2713 11.5049 14.0013C11.5049 13.9113 11.5836 13.8325 11.6736 13.8325H12.3374C12.4274 13.8325 12.5061 13.9113 12.5061 14.0013C12.5061 14.2825 12.2811 14.4963 12.0111 14.4963H11.9999Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M9.81787 17.1178C10.0541 17.3541 10.3804 17.4891 10.7291 17.4891C11.4379 17.4891 12.0117 16.9153 12.0117 16.2065C12.0117 16.9153 12.5854 17.4891 13.2942 17.4891C13.6317 17.4891 13.9579 17.3541 14.2054 17.1178"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M12 14.4961V16.2174"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M16.9951 12.8088L21.0002 12.1338"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M21.0002 15.9924L16.9951 15.3286"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M7.00506 12.8088L3 12.1338"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M3 15.9924L7.00506 15.3286"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_8911">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
