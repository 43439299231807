<script lang="ts" setup></script>

<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M17.07 21H6.93C5.86 21 5 20.13 5 19.07V4.93C5 3.86 5.87 3 6.93 3H17.06C18.13 3 18.99 3.87 18.99 4.93V19.06C18.99 20.13 18.12 20.99 17.06 20.99L17.07 21Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M19 8H5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<circle
			cx="8"
			cy="12"
			fill="#87BD25"
			r="0.5"
		/>
		<circle
			cx="8"
			cy="15"
			fill="#87BD25"
			r="0.5"
		/>
		<circle
			cx="8"
			cy="18"
			fill="#87BD25"
			r="0.5"
		/>
		<circle
			cx="12"
			cy="12"
			fill="#87BD25"
			r="0.5"
		/>
		<circle
			cx="12"
			cy="15"
			fill="#87BD25"
			r="0.5"
		/>
		<circle
			cx="12"
			cy="18"
			fill="#87BD25"
			r="0.5"
		/>
		<circle
			cx="16"
			cy="12"
			fill="#87BD25"
			r="0.5"
		/>
		<circle
			cx="16"
			cy="15"
			fill="#87BD25"
			r="0.5"
		/>
		<circle
			cx="16"
			cy="18"
			fill="#87BD25"
			r="0.5"
		/>
	</svg>
</template>
