<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M22.0084 12.002L20.0064 14.002L18.0054 12.002"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M6.34131 6.344C7.79031 4.896 9.79131 4 12.0023 4C16.4233 4 20.0073 7.582 20.0073 12.002C20.0073 12.61 19.9333 13.2 19.8053 13.769"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M1.99219 11.998L3.99419 9.99805L5.99519 11.998"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M17.6592 17.656C16.2102 19.104 14.2092 20 11.9982 20C7.57716 20 3.99316 16.418 3.99316 11.998C3.99316 11.39 4.06716 10.8 4.19516 10.231"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_9252">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
