<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 25 24"
	>
		<g clip-path="url(#clip0_2281_5134)">
			<path
				class="stroke-current"
				d="M15.1756 14.4754C13.8084 15.8425 11.5928 15.8425 10.2256 14.4754C8.8584 13.1082 8.8584 10.8926 10.2256 9.52538C11.5928 8.15821 13.8084 8.15821 15.1756 9.52538"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				class="stroke-current"
				d="M12.7002 11.0339H8.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				class="stroke-current"
				d="M12.7002 12.9661H8.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				class="stroke-current"
				d="M4.5 11.138V6.87505C4.5 6.00027 5.06852 5.22695 5.90349 4.96604L11.9035 3.09104C12.2919 2.96965 12.7082 2.96965 13.0966 3.09104L19.0966 4.96604C19.9315 5.22698 20.5 6.00027 20.5 6.87505V11.1381C20.5 15.3065 17.9143 19.0377 14.0112 20.5013L12.8511 20.9363C12.6247 21.0212 12.3753 21.0212 12.1489 20.9363L10.9888 20.5013C7.08573 19.0377 4.5 15.3065 4.5 11.138Z"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</g>
	</svg>
</template>
