import type { Component } from 'vue'

import AccidentsIcon from '@/icons/AccidentsIcon.vue'
import AddDateIcon from '@/icons/AddDateIcon.vue'
import AllergyIcon from '@/icons/AllergyIcon.vue'
import AnomalyIcon from '@/icons/AnomalyIcon.vue'
import ApplicationPremiumIcon from '@/icons/ApplicationPremiumIcon.vue'
import ArrowDownIcon from '@/icons/ArrowDownIcon.vue'
import ArrowLeftIcon from '@/icons/ArrowLeftIcon.vue'
import ArrowRightIcon from '@/icons/ArrowRightIcon.vue'
import ArrowUpIcon from '@/icons/ArrowUpIcon.vue'
import AssignIcon from '@/icons/AssignIcon.vue'
import AttachmentFalseIcon from '@/icons/AttachmentFalseIcon.vue'
import AttachmentTrueIcon from '@/icons/AttachmentTrueIcon.vue'
import BandageIcon from '@/icons/BandageIcon.vue'
import BikeIcon from '@/icons/BikeIcon.vue'
import BirdIcon from '@/icons/BirdIcon.vue'
import BlockIcon from '@/icons/BlockIcon.vue'
import BookmarkIcon from '@/icons/BookmarkIcon.vue'
import BorderelIcon from '@/icons/BorderelIcon.vue'
import BriefcaseIcon from '@/icons/BriefcaseIcon.vue'
import BurgerMenuIcon from '@/icons/BurgerMenuIcon.vue'
import BusIcon from '@/icons/BusIcon.vue'
import CalculatorIcon from '@/icons/CalculatorIcon.vue'
import CalendarIcon from '@/icons/CalendarIcon.vue'
import CalendarRecurrentIcon from '@/icons/CalendarRecurrentIcon.vue'
import CalendarSingleIcon from '@/icons/CalendarSingleIcon.vue'
import CallIcon from '@/icons/CallIcon.vue'
import CampaignIcon from '@/icons/CampaignIcon.vue'
import CarFrontIcon from '@/icons/CarFrontIcon.vue'
import CarSideIcon from '@/icons/CarSideIcon.vue'
import CatIcon from '@/icons/CatIcon.vue'
import ChatAppIcon from '@/icons/ChatAppIcon.vue'
import ChatBubbleAlertIcon from '@/icons/ChatBubbleAlertIcon.vue'
import ChatBubbleIcon from '@/icons/ChatBubbleIcon.vue'
import ChatIcon from '@/icons/ChatIcon.vue'
import CheckmarkFillIcon from '@/icons/CheckmarkFillIcon.vue'
import CheckmarkIcon from '@/icons/CheckmarkIcon.vue'
import CheckmarkSmallIcon from '@/icons/CheckmarkSmallIcon.vue'
import ChoresIcon from '@/icons/ChoresIcon.vue'
import CircleDashedIcon from '@/icons/CircleDashedIcon.vue'
import ClaimsIcon from '@/icons/ClaimsIcon.vue'
import CleaningIcon from '@/icons/CleaningIcon.vue'
import ClockIcon from '@/icons/ClockIcon.vue'
import CloseBoldIcon from '@/icons/CloseBoldIcon.vue'
import CloseIcon from '@/icons/CloseIcon.vue'
import ClothingIcon from '@/icons/ClothingIcon.vue'
import CommentIcon from '@/icons/CommentIcon.vue'
import ConeIcon from '@/icons/ConeIcon.vue'
import ConsultantIcon from '@/icons/ConsultantIcon.vue'
import ConvertIcon from '@/icons/ConvertIcon.vue'
import CopyIcon from '@/icons/CopyIcon.vue'
import CreditCardIcon from '@/icons/CreditCardIcon.vue'
import CrossSmallIcon from '@/icons/CrossSmallIcon.vue'
import CustomersIcon from '@/icons/CustomersIcon.vue'
import DashboardIcon from '@/icons/DashboardIcon.vue'
import DebtorsIcon from '@/icons/DebtorsIcon.vue'
import DeleteIcon from '@/icons/DeleteIcon.vue'
import DoctorIcon from '@/icons/DoctorIcon.vue'
import DogIcon from '@/icons/DogIcon.vue'
import DotsHorizontalIcon from '@/icons/DotsHorizontalIcon.vue'
import DotsIcon from '@/icons/DotsIcon.vue'
import DotsVerticalIcon from '@/icons/DotsVerticalIcon.vue'
import DoubleCheckmarkFullIcon from '@/icons/DoubleCheckmarkFullIcon.vue'
import DoubleCheckmarkHalfIcon from '@/icons/DoubleCheckmarkHalfIcon.vue'
import DoubleCheckmarkNoneIcon from '@/icons/DoubleCheckmarkNoneIcon.vue'
import DownloadIcon from '@/icons/DownloadIcon.vue'
import EditDateIcon from '@/icons/EditDateIcon.vue'
import EditedIcon from '@/icons/EditedIcon.vue'
import EditIcon from '@/icons/EditIcon.vue'
import ElectronicChecksIcon from '@/icons/ElectronicChecksIcon.vue'
import EmployeesIcon from '@/icons/EmployeesIcon.vue'
import EnrollmentApplicationIcon from '@/icons/EnrollmentApplicationIcon.vue'
import EyeIcon from '@/icons/EyeIcon.vue'
import FamilyIcon from '@/icons/FamilyIcon.vue'
import FilterIcon from '@/icons/FilterIcon.vue'
import FlagIcon from '@/icons/FlagIcon.vue'
import FolderIcon from '@/icons/FolderIcon.vue'
import FormatBlockQuoteIcon from '@/icons/FormatBlockQuoteIcon.vue'
import FormatBoldIcon from '@/icons/FormatBoldIcon.vue'
import FormatHeadingOneIcon from '@/icons/FormatHeadingOneIcon.vue'
import FormatHeadingTwoIcon from '@/icons/FormatHeadingTwoIcon.vue'
import FormatImageIcon from '@/icons/FormatImageIcon.vue'
import FormatItalicIcon from '@/icons/FormatItalicIcon.vue'
import FormatLinkIcon from '@/icons/FormatLinkIcon.vue'
import FormatOrderedListIcon from '@/icons/FormatOrderedListIcon.vue'
import FormatUnderlineIcon from '@/icons/FormatUnderlineIcon.vue'
import FormatUnorderedListIcon from '@/icons/FormatUnorderedListIcon.vue'
import GenderFemaleIcon from '@/icons/GenderFemaleIcon.vue'
import GenderMaleIcon from '@/icons/GenderMaleIcon.vue'
import GraduateCapIcon from '@/icons/GraduateCapIcon.vue'
import HamsterIcon from '@/icons/HamsterIcon.vue'
import HandCleaningIcon from '@/icons/HandCleaningIcon.vue'
import HandIcon from '@/icons/HandIcon.vue'
import HandInstallmentIcon from '@/icons/HandInstallmentIcon.vue'
import HandPremiumIcon from '@/icons/HandPremiumIcon.vue'
import HeartIcon from '@/icons/HeartIcon.vue'
import HistoryIcon from '@/icons/HistoryIcon.vue'
import HomeMaidLogoIcon from '@/icons/HomeMaidLogoIcon.vue'
import HouseIcon from '@/icons/HouseIcon.vue'
import ImageIcon from '@/icons/ImageIcon.vue'
import IncomingMailIcon from '@/icons/IncomingMailIcon.vue'
import InfoIcon from '@/icons/InfoIcon.vue'
import IroningIcon from '@/icons/IroningIcon.vue'
import LegalIcon from '@/icons/LegalIcon.vue'
import LetterEIcon from '@/icons/LetterEIcon.vue'
import LetterPIcon from '@/icons/LetterPIcon.vue'
import LinkIcon from '@/icons/LinkIcon.vue'
import LoadingIcon from '@/icons/LoadingIcon.vue'
import LockClosedIcon from '@/icons/LockClosedIcon.vue'
import LockOpenIcon from '@/icons/LockOpenIcon.vue'
import LogbookIcon from '@/icons/LogbookIcon.vue'
import MailAlertIcon from '@/icons/MailAlertIcon.vue'
import MailIcon from '@/icons/MailIcon.vue'
import MarkerIcon from '@/icons/MarkerIcon.vue'
import MatchingIcon from '@/icons/MatchingIcon.vue'
import MergeIcon from '@/icons/MergeIcon.vue'
import MinusBoldIcon from '@/icons/MinusBoldIcon.vue'
import MinusIcon from '@/icons/MinusIcon.vue'
import MotorcycleIcon from '@/icons/MotorcycleIcon.vue'
import MutualityIcon from '@/icons/MutualityIcon.vue'
import NewsItemsIcon from '@/icons/NewsItemsIcon.vue'
import NoteIcon from '@/icons/NoteIcon.vue'
import NotificationAlertIcon from '@/icons/NotificationAlertIcon.vue'
import NotificationIcon from '@/icons/NotificationIcon.vue'
import OfficeIcon from '@/icons/OfficeIcon.vue'
import OpenTabIcon from '@/icons/OpenTabIcon.vue'
import OutgoingMailIcon from '@/icons/OutgoingMailIcon.vue'
import PacifierIcon from '@/icons/PacifierIcon.vue'
import PaperChecksIcon from '@/icons/PaperChecksIcon.vue'
import ParttimeIcon from '@/icons/ParttimeIcon.vue'
import PawIcon from '@/icons/PawIcon.vue'
import PhoneIcon from '@/icons/PhoneIcon.vue'
import PinFalseIcon from '@/icons/PinFalseIcon.vue'
import PinTrueIcon from '@/icons/PinTrueIcon.vue'
import PlusBoldIcon from '@/icons/PlusBoldIcon.vue'
import PlusIcon from '@/icons/PlusIcon.vue'
import PluxeeIcon from '@/icons/PluxeeIcon.vue'
import PowerOutIcon from '@/icons/PowerOutIcon.vue'
import PrintIcon from '@/icons/PrintIcon.vue'
import QuestionMarkIcon from '@/icons/QuestionMarkIcon.vue'
import RedoIcon from '@/icons/RedoIcon.vue'
import ReferralIcon from '@/icons/ReferralIcon.vue'
import RemunerationIcon from '@/icons/RemunerationIcon.vue'
import ScaleIcon from '@/icons/ScaleIcon.vue'
import SearchIcon from '@/icons/SearchIcon.vue'
import SettingsIcon from '@/icons/SettingsIcon.vue'
import ShareIcon from '@/icons/ShareIcon.vue'
import ShieldCashIcon from '@/icons/ShieldCashIcon.vue'
import ShieldCrossIcon from '@/icons/ShieldCrossIcon.vue'
import SignIcon from '@/icons/SignIcon.vue'
import SignPostIcon from '@/icons/SignPostIcon.vue'
import SparkleIcon from '@/icons/SparkleIcon.vue'
import StarIcon from '@/icons/StarIcon.vue'
import StarRatingFalseIcon from '@/icons/StarRatingFalseIcon.vue'
import StarRatingTrueIcon from '@/icons/StarRatingTrueIcon.vue'
import SubsidiesIcon from '@/icons/SubsidiesIcon.vue'
import SwitchIcon from '@/icons/SwitchIcon.vue'
import TodoAlertIcon from '@/icons/TodoAlertIcon.vue'
import TodoIcon from '@/icons/TodoIcon.vue'
import TransportExpenseIcon from '@/icons/TransportExpenseIcon.vue'
import TransportIcon from '@/icons/TransportIcon.vue'
import TrixxaLogoIcon from '@/icons/TrixxaLogoIcon.vue'
import UndoDeleteIcon from '@/icons/UndoDeleteIcon.vue'
import UnlinkIcon from '@/icons/UnlinkIcon.vue'
import UploadIcon from '@/icons/UploadIcon.vue'
import UserIcon from '@/icons/UserIcon.vue'
import ViewGridIcon from '@/icons/ViewGridIcon.vue'
import ViewListIcon from '@/icons/ViewListIcon.vue'
import WageGarnishmentIcon from '@/icons/WageGarnishmentIcon.vue'
import WalkingIcon from '@/icons/WalkingIcon.vue'
import WalletIcon from '@/icons/WalletIcon.vue'
import WarningCircleFillIcon from '@/icons/WarningCircleFillIcon.vue'
import WarningCircleIcon from '@/icons/WarningCircleIcon.vue'
import WarningTriangleBoldIcon from '@/icons/WarningTriangleBoldIcon.vue'
import WarningTriangleIcon from '@/icons/WarningTriangleIcon.vue'
import WitnessIcon from '@/icons/WitnessIcon.vue'
import ZipIcon from '@/icons/ZipIcon.vue'
import type { DefaultThemeColor } from '@/themes/default/colors/defaultThemeColors.type'

export enum Icon {
	ADD_TIME = 'addTime',
	ACCIDENTS = 'accidents',
	ADD_DATE = 'add-date',
	ALLERGY = 'allergy',
	ANOMALY = 'anomaly',
	REFERRAL_PREMIUM = 'application-premium',
	ARROW_DOWN = 'arrow-down',
	ARROW_LEFT = 'arrow-left',
	ARROW_RIGHT = 'arrow-right',
	ARROW_UP = 'arrow-up',
	ASSIGN = 'assign',
	ATTACHMENT_FALSE = 'attachment-false',
	ATTACHMENT_TRUE = 'attachment-true',
	BANDAGE = 'bandage',
	BIKE = 'bike',
	BIRD = 'bird',
	BLOCK = 'block',
	BOOKMARK = 'bookmark',
	BORDEREL = 'borderel',
	BRIEFCASE = 'briefcase',
	BURGER_MENU = 'burger-menu',
	BUS = 'bus',
	CALCULATOR = 'calculator',
	CALENDAR = 'calendar',
	CALENDAR_RECURRENT = 'calendar_recurrent',
	CALENDAR_SINGLE = 'calendar_single',
	CALL = 'call',
	CAMPAIGN = 'campaign',
	CAR_FRONT = 'car-front',
	CAR_SIDE = 'car-side',
	CAT = 'cat',
	CHAT_BUBBLE_ALERT = 'chat-bubble-alert',
	CHAT_BUBBLE = 'chat-bubble',
	CHECKMARK_BOLD = 'checkmark-bold',
	CHECKMARK_CIRCLE = 'checkmark-circle',
	CHECKMARK_FILL = 'checkmark-fill',
	CHECK_MARK = 'checkmark',
	CHECKMARK_SMALL = 'checkmark-small',
	CHORES = 'chores',
	CIRCLE_DASHED = 'circle-dashed',
	CLAIMS = 'claims',
	CLEANING = 'cleaning',
	CLOCK = 'clock',
	CLOSE_BOLD = 'close-bold',
	CLOSE = 'close',
	CLOTHING = 'clothing',
	COMMENT = 'comment',
	CONE = 'cone',
	CONSULTANT = 'consultant',
	CONVERT = 'convert',
	COPY = 'copy',
	CREDIT_CARD = 'credit-card',
	CROSS_SMALL = 'cross-small',
	CUSTOMERS = 'customers',
	DASHBOARD = 'dashboard',
	DEBTORS = 'debtors',
	DELETE = 'delete',
	DOCTOR = 'doctor',
	DOG = 'dog',
	DOTS_HORIZONTAL = 'dots-horizontal',
	DOTS = 'dots',
	DOTS_VERTICAL = 'dots-vertical',
	DOUBLE_CHECKMARK_FULL = 'double-checkmark-full',
	DOUBLE_CHECKMARK_HALF = 'double-checkmark-half',
	DOUBLE_CHECKMARK_NONE = 'double-checkmark-none',
	DOWNLOAD = 'download',
	EDIT_DATE = 'edit-date',
	EDIT = 'edit',
	EDITED = 'edited',
	ELECTRONIC_CHECKS = 'electronic-checks',
	EYE = 'eye',
	PAPER_CHECKS = 'paper-checks',
	LETTER_P = 'letter-p',
	LETTER_E = 'letter-e',
	LINK = 'link',
	LOCK_ICON_OPEN = 'lock-icon-open',
	LOCK_ICON_CLOSED = 'lock-icon-closed',
	EMPLOYEE = 'employee',
	ENROLLMENT_APPLICATION = 'enrollment-application',
	FAMILY = 'family',
	FILTER = 'filter',
	FLAG = 'flag',
	FORMAT_BOLD = 'format-bold',
	FORMAT_ITALIC = 'format-italic',
	FORMAT_IMAGE = 'format-image',
	FORMAT_UNDERLINE = 'format-underline',
	FORMAT_HEADING_ONE = 'format-heading-one',
	FORMAT_HEADING_TWO = 'format-heading-two',
	FORMAT_UNORDERED_LIST = 'format-unordered-list',
	FORMAT_ORDERED_LIST = 'format-ordered-list',
	FORMAT_BLOCK_QUOTE = 'format-block-quote',
	FORMAT_LINK = 'format-link',
	FOLDER = 'folder',
	GENDER_FEMALE = 'gender-female',
	GENDER_MALE = 'gender-male',
	GRADUATE_CAP = 'graduate-cap',
	HAMSTER = 'hamster',
	HAND_CLEANING = 'hand-cleaning',
	HAND_INSTALLMENT = 'hand-installment',
	HAND_PREMIUM = 'hand-premium',
	SHIELD_CASH = 'shield-cash',
	SHIELD_CROSS = 'shield-cross',
	HAND = 'hand',
	SCALES = 'scales',
	HEART = 'heart',
	HISTORY = 'history',
	HOME_MAID_LOGO = 'home-maid-logo',
	HOUSE = 'house',
	IMAGE = 'image',
	INCOMING_MAIL = 'incoming-mail',
	INFO = 'info',
	IRONING = 'ironing',
	LEGAL = 'legal',
	LOGBOOK = 'logbook',
	MAIL_ALERT = 'mail-alert',
	MAIL = 'mail',
	MATCHING = 'matching',
	MERGE = 'merge',
	MINUS_BOLD = 'minus-bold',
	MINUS = 'minus',
	MOTORCYCLE = 'motorcycle',
	MUTUALITY = 'mutuality',
	NEWS_ITEMS = 'news-items',
	NOTE = 'note',
	NOTIFICATION_ALERT = 'notification-alert',
	NOTIFICATION = 'notification',
	OFFICE = 'office',
	OPEN_TAB = 'open-tab',
	OUTGOING_MAIL = 'outgoing-mail',
	PACIFIER = 'pacifier',
	PARTTIME = 'parttime',
	PAW = 'paw',
	PHONE = 'phone',
	PIN_FALSE = 'state-false',
	PIN_TRUE = 'state-true',
	PLUS_BOLD = 'plus-bold',
	PLUS = 'plus',
	PLUXEE = 'pluxee',
	POWER_OUT = 'power-out',
	PRINT = 'print',
	QUESTION_MARK = 'question-mark',
	REDO = 'redo',
	REFERRAL = 'referral',
	REMUNERATION = 'remuneration',
	SEARCH = 'search',
	SETTINGS = 'settings',
	SHARE = 'share',
	SIGN = 'sign',
	SIGN_POST = 'sign-post',
	SPARKLE = 'sparkle',
	STAR = 'star',
	STAR_RATING_FALSE = 'star-rating-false',
	STAR_RATING_TRUE = 'star-rating-true',
	SUBSIDIES = 'subsidies',
	SWITCH = 'switch',
	TODO_ALERT = 'todo-alert',
	TODO = 'todo',
	TRANSPORT_EXPENSE = 'transport-expense',
	TRANSPORT = 'transport',
	TRIXXA_LOGO = 'trixxa-logo',
	UNDO_DELETE = 'undo-delete',
	UNLINK = 'unlink',
	UPLOAD = 'upload',
	USER = 'user',
	VIEW_GRID = 'view-grid',
	VIEW_LIST = 'view-list',
	WAGE_GARNISHMENT = 'wage-garnishment',
	WALKING = 'walking',
	WALLET = 'wallet',
	WARNING_CIRCLE_FILL = 'warning-circle-fill',
	WARNING_CIRCLE = 'warning-circle',
	WARNING_TRIANGLE = 'warning-triangle',
	WARNING_TRIANGLE_BOLD = 'warning-circle-bold',
	WITNESS = 'witness',
	ZIP = 'zip',
	LOADING = 'loading',
	CHAT = 'chat',
	CHAT_APP = 'chat-app',
	MARKER = 'marker',
}

const icons = new Map<Icon, { color: DefaultThemeColor; icon: Component }>([
	[Icon.SHIELD_CASH, { color: 'lima', icon: ShieldCashIcon }],
	[Icon.SHIELD_CROSS, { color: 'lima', icon: ShieldCrossIcon }],
	[Icon.SCALES, { color: 'lima', icon: ScaleIcon }],
	[Icon.HAND, { color: 'lima', icon: HandIcon }],
	[Icon.PLUS_BOLD, { color: 'lima', icon: PlusBoldIcon }],
	[Icon.ADD_TIME, { color: 'lima', icon: AddDateIcon }],
	[Icon.ACCIDENTS, { color: 'lima', icon: AccidentsIcon }],
	[Icon.ADD_DATE, { color: 'lima', icon: AddDateIcon }],
	[Icon.ALLERGY, { color: 'lima', icon: AllergyIcon }],
	[Icon.ANOMALY, { color: 'lima', icon: AnomalyIcon }],
	[Icon.REFERRAL_PREMIUM, { color: 'lima', icon: ApplicationPremiumIcon }],
	[Icon.ARROW_DOWN, { color: 'lima', icon: ArrowDownIcon }],
	[Icon.ARROW_LEFT, { color: 'lima', icon: ArrowLeftIcon }],
	[Icon.ARROW_RIGHT, { color: 'lima', icon: ArrowRightIcon }],
	[Icon.ARROW_UP, { color: 'lima', icon: ArrowUpIcon }],
	[Icon.ASSIGN, { color: 'lima', icon: AssignIcon }],
	[Icon.ATTACHMENT_FALSE, { color: 'lima', icon: AttachmentFalseIcon }],
	[Icon.ATTACHMENT_TRUE, { color: 'lima', icon: AttachmentTrueIcon }],
	[Icon.BANDAGE, { color: 'lima', icon: BandageIcon }],
	[Icon.BIKE, { color: 'lima', icon: BikeIcon }],
	[Icon.BIRD, { color: 'lima', icon: BirdIcon }],
	[Icon.BLOCK, { color: 'lima', icon: BlockIcon }],
	[Icon.BOOKMARK, { color: 'lima', icon: BookmarkIcon }],
	[Icon.BORDEREL, { color: 'lima', icon: BorderelIcon }],
	[Icon.BRIEFCASE, { color: 'lima', icon: BriefcaseIcon }],
	[Icon.BURGER_MENU, { color: 'lima', icon: BurgerMenuIcon }],
	[Icon.BUS, { color: 'lima', icon: BusIcon }],
	[Icon.CALCULATOR, { color: 'lima', icon: CalculatorIcon }],
	[Icon.CALENDAR, { color: 'lima', icon: CalendarIcon }],
	[Icon.CALENDAR_RECURRENT, { color: 'lima', icon: CalendarRecurrentIcon }],
	[Icon.CALENDAR_SINGLE, { color: 'lima', icon: CalendarSingleIcon }],
	[Icon.CALL, { color: 'lima', icon: CallIcon }],
	[Icon.CAMPAIGN, { color: 'lima', icon: CampaignIcon }],
	[Icon.CAR_FRONT, { color: 'safety-orange', icon: CarFrontIcon }],
	[Icon.CAR_SIDE, { color: 'lima', icon: CarSideIcon }],
	[Icon.CAT, { color: 'lima', icon: CatIcon }],
	[Icon.CHAT_BUBBLE_ALERT, { color: 'lima', icon: ChatBubbleAlertIcon }],
	[Icon.CHAT_BUBBLE, { color: 'lima', icon: ChatBubbleIcon }],
	[Icon.CHECKMARK_FILL, { color: 'lima', icon: CheckmarkFillIcon }],
	[Icon.CHECK_MARK, { color: 'lima', icon: CheckmarkIcon }],
	[Icon.CHECKMARK_SMALL, { color: 'lima', icon: CheckmarkSmallIcon }],
	[Icon.CHORES, { color: 'schoolbus-yellow', icon: ChoresIcon }],
	[Icon.CIRCLE_DASHED, { color: 'lima', icon: CircleDashedIcon }],
	[Icon.CLAIMS, { color: 'lima', icon: ClaimsIcon }],
	[Icon.CLEANING, { color: 'lima', icon: CleaningIcon }],
	[Icon.CLOCK, { color: 'crayon-blue', icon: ClockIcon }],
	[Icon.CLOSE_BOLD, { color: 'safety-orange', icon: CloseBoldIcon }],
	[Icon.CLOSE, { color: 'lima', icon: CloseIcon }],
	[Icon.CLOTHING, { color: 'lima', icon: ClothingIcon }],
	[Icon.COMMENT, { color: 'lima', icon: CommentIcon }],
	[Icon.CONE, { color: 'lima', icon: ConeIcon }],
	[Icon.CONSULTANT, { color: 'crayon-blue', icon: ConsultantIcon }],
	[Icon.CONVERT, { color: 'lima', icon: ConvertIcon }],
	[Icon.COPY, { color: 'lima', icon: CopyIcon }],
	[Icon.CREDIT_CARD, { color: 'lima', icon: CreditCardIcon }],
	[Icon.CROSS_SMALL, { color: 'lima', icon: CrossSmallIcon }],
	[Icon.CUSTOMERS, { color: 'lima', icon: CustomersIcon }],
	[Icon.DASHBOARD, { color: 'lima', icon: DashboardIcon }],
	[Icon.DEBTORS, { color: 'lima', icon: DebtorsIcon }],
	[Icon.DELETE, { color: 'safety-orange', icon: DeleteIcon }],
	[Icon.DOCTOR, { color: 'lima', icon: DoctorIcon }],
	[Icon.DOG, { color: 'lima', icon: DogIcon }],
	[Icon.DOTS_HORIZONTAL, { color: 'waterloo', icon: DotsHorizontalIcon }],
	[Icon.DOTS, { color: 'lima', icon: DotsIcon }],
	[Icon.DOTS_VERTICAL, { color: 'lima', icon: DotsVerticalIcon }],
	[Icon.DOUBLE_CHECKMARK_FULL, { color: 'lima', icon: DoubleCheckmarkFullIcon }],
	[Icon.DOUBLE_CHECKMARK_HALF, { color: 'lima', icon: DoubleCheckmarkHalfIcon }],
	[Icon.DOUBLE_CHECKMARK_NONE, { color: 'lima', icon: DoubleCheckmarkNoneIcon }],
	[Icon.DOWNLOAD, { color: 'lima', icon: DownloadIcon }],
	[Icon.EDIT_DATE, { color: 'lima', icon: EditDateIcon }],
	[Icon.EDIT, { color: 'crayon-blue', icon: EditIcon }],
	[Icon.EDITED, { color: 'crayon-blue', icon: EditedIcon }],
	[Icon.ELECTRONIC_CHECKS, { color: 'crayon-blue', icon: ElectronicChecksIcon }],
	[Icon.EYE, { color: 'lima', icon: EyeIcon }],
	[Icon.PAPER_CHECKS, { color: 'crayon-blue', icon: PaperChecksIcon }],
	[Icon.LETTER_E, { color: 'lima', icon: LetterEIcon }],
	[Icon.LETTER_P, { color: 'lima', icon: LetterPIcon }],
	[Icon.LINK, { color: 'lima', icon: LinkIcon }],
	[Icon.LOCK_ICON_OPEN, { color: 'lima', icon: LockOpenIcon }],
	[Icon.LOCK_ICON_CLOSED, { color: 'lima', icon: LockClosedIcon }],
	[Icon.EMPLOYEE, { color: 'crayon-blue', icon: EmployeesIcon }],
	[Icon.ENROLLMENT_APPLICATION, { color: 'lima', icon: EnrollmentApplicationIcon }],
	[Icon.FAMILY, { color: 'lima', icon: FamilyIcon }],
	[Icon.FILTER, { color: 'lima', icon: FilterIcon }],
	[Icon.FLAG, { color: 'lima', icon: FlagIcon }],
	[Icon.FOLDER, { color: 'crayon-blue', icon: FolderIcon }],
	[Icon.FORMAT_BOLD, { color: 'waterloo', icon: FormatBoldIcon }],
	[Icon.FORMAT_ITALIC, { color: 'waterloo', icon: FormatItalicIcon }],
	[Icon.FORMAT_IMAGE, { color: 'waterloo', icon: FormatImageIcon }],
	[Icon.FORMAT_UNDERLINE, { color: 'waterloo', icon: FormatUnderlineIcon }],
	[Icon.FORMAT_HEADING_ONE, { color: 'waterloo', icon: FormatHeadingOneIcon }],
	[Icon.FORMAT_HEADING_TWO, { color: 'waterloo', icon: FormatHeadingTwoIcon }],
	[Icon.FORMAT_UNORDERED_LIST, { color: 'waterloo', icon: FormatUnorderedListIcon }],
	[Icon.FORMAT_ORDERED_LIST, { color: 'waterloo', icon: FormatOrderedListIcon }],
	[Icon.FORMAT_BLOCK_QUOTE, { color: 'waterloo', icon: FormatBlockQuoteIcon }],
	[Icon.FORMAT_LINK, { color: 'waterloo', icon: FormatLinkIcon }],
	[Icon.GENDER_FEMALE, { color: 'lima', icon: GenderFemaleIcon }],
	[Icon.GENDER_MALE, { color: 'lima', icon: GenderMaleIcon }],
	[Icon.GRADUATE_CAP, { color: 'lima', icon: GraduateCapIcon }],
	[Icon.HAMSTER, { color: 'lima', icon: HamsterIcon }],
	[Icon.HAND_CLEANING, { color: 'lima', icon: HandCleaningIcon }],
	[Icon.HAND_INSTALLMENT, { color: 'lima', icon: HandInstallmentIcon }],
	[Icon.HAND_PREMIUM, { color: 'lima', icon: HandPremiumIcon }],
	[Icon.HEART, { color: 'lima', icon: HeartIcon }],
	[Icon.HISTORY, { color: 'lima', icon: HistoryIcon }],
	[Icon.HOME_MAID_LOGO, { color: 'lima', icon: HomeMaidLogoIcon }],
	[Icon.HOUSE, { color: 'lima', icon: HouseIcon }],
	[Icon.IMAGE, { color: 'lima', icon: ImageIcon }],
	[Icon.INCOMING_MAIL, { color: 'lima', icon: IncomingMailIcon }],
	[Icon.INFO, { color: 'lima', icon: InfoIcon }],
	[Icon.IRONING, { color: 'lavender-indigo', icon: IroningIcon }],
	[Icon.LEGAL, { color: 'lima', icon: LegalIcon }],
	[Icon.LOGBOOK, { color: 'lima', icon: LogbookIcon }],
	[Icon.MAIL_ALERT, { color: 'lavender-indigo', icon: MailAlertIcon }],
	[Icon.MAIL, { color: 'lavender-indigo', icon: MailIcon }],
	[Icon.MATCHING, { color: 'lima', icon: MatchingIcon }],
	[Icon.MERGE, { color: 'lima', icon: MergeIcon }],
	[Icon.MINUS_BOLD, { color: 'lima', icon: MinusBoldIcon }],
	[Icon.MINUS, { color: 'lima', icon: MinusIcon }],
	[Icon.MOTORCYCLE, { color: 'lima', icon: MotorcycleIcon }],
	[Icon.MUTUALITY, { color: 'lima', icon: MutualityIcon }],
	[Icon.NEWS_ITEMS, { color: 'lima', icon: NewsItemsIcon }],
	[Icon.NOTE, { color: 'lima', icon: NoteIcon }],
	[Icon.NOTIFICATION_ALERT, { color: 'lima', icon: NotificationAlertIcon }],
	[Icon.NOTIFICATION, { color: 'lima', icon: NotificationIcon }],
	[Icon.OFFICE, { color: 'lima', icon: OfficeIcon }],
	[Icon.OPEN_TAB, { color: 'lima', icon: OpenTabIcon }],
	[Icon.OUTGOING_MAIL, { color: 'lima', icon: OutgoingMailIcon }],
	[Icon.PACIFIER, { color: 'lima', icon: PacifierIcon }],
	[Icon.PARTTIME, { color: 'lima', icon: ParttimeIcon }],
	[Icon.PAW, { color: 'lima', icon: PawIcon }],
	[Icon.PHONE, { color: 'safety-orange', icon: PhoneIcon }],
	[Icon.PIN_FALSE, { color: 'lima', icon: PinFalseIcon }],
	[Icon.PIN_TRUE, { color: 'lima', icon: PinTrueIcon }],
	[Icon.PLUS_BOLD, { color: 'lima', icon: PlusBoldIcon }],
	[Icon.PLUS, { color: 'lima', icon: PlusIcon }],
	[Icon.PLUXEE, { color: 'lima', icon: PluxeeIcon }],
	[Icon.POWER_OUT, { color: 'lima', icon: PowerOutIcon }],
	[Icon.PRINT, { color: 'lima', icon: PrintIcon }],
	[Icon.QUESTION_MARK, { color: 'lima', icon: QuestionMarkIcon }],
	[Icon.REDO, { color: 'lima', icon: RedoIcon }],
	[Icon.REFERRAL, { color: 'lima', icon: ReferralIcon }],
	[Icon.REMUNERATION, { color: 'lima', icon: RemunerationIcon }],
	[Icon.SEARCH, { color: 'lima', icon: SearchIcon }],
	[Icon.SETTINGS, { color: 'lima', icon: SettingsIcon }],
	[Icon.SHARE, { color: 'crayon-blue', icon: ShareIcon }],
	[Icon.SIGN, { color: 'crayon-blue', icon: SignIcon }],
	[Icon.SIGN_POST, { color: 'lima', icon: SignPostIcon }],
	[Icon.SPARKLE, { color: 'lima', icon: SparkleIcon }],
	[Icon.STAR, { color: 'lima', icon: StarIcon }],
	[Icon.STAR_RATING_FALSE, { color: 'lima', icon: StarRatingFalseIcon }],
	[Icon.STAR_RATING_TRUE, { color: 'lima', icon: StarRatingTrueIcon }],
	[Icon.SUBSIDIES, { color: 'lima', icon: SubsidiesIcon }],
	[Icon.SWITCH, { color: 'lima', icon: SwitchIcon }],
	[Icon.TODO_ALERT, { color: 'lima', icon: TodoAlertIcon }],
	[Icon.TODO, { color: 'lima', icon: TodoIcon }],
	[Icon.TRANSPORT_EXPENSE, { color: 'safety-orange', icon: TransportExpenseIcon }],
	[Icon.TRANSPORT, { color: 'lima', icon: TransportIcon }],
	[Icon.TRIXXA_LOGO, { color: 'lima', icon: TrixxaLogoIcon }],
	[Icon.UNDO_DELETE, { color: 'lima', icon: UndoDeleteIcon }],
	[Icon.UNLINK, { color: 'lima', icon: UnlinkIcon }],
	[Icon.UPLOAD, { color: 'lima', icon: UploadIcon }],
	[Icon.USER, { color: 'lima', icon: UserIcon }],
	[Icon.VIEW_GRID, { color: 'lima', icon: ViewGridIcon }],
	[Icon.VIEW_LIST, { color: 'lima', icon: ViewListIcon }],
	[Icon.WAGE_GARNISHMENT, { color: 'lima', icon: WageGarnishmentIcon }],
	[Icon.WALKING, { color: 'lima', icon: WalkingIcon }],
	[Icon.WALLET, { color: 'lima', icon: WalletIcon }],
	[Icon.WARNING_CIRCLE_FILL, { color: 'lima', icon: WarningCircleFillIcon }],
	[Icon.WARNING_CIRCLE, { color: 'lima', icon: WarningCircleIcon }],
	[Icon.WARNING_TRIANGLE, { color: 'lima', icon: WarningTriangleIcon }],
	[Icon.WARNING_TRIANGLE_BOLD, { color: 'vivid-red', icon: WarningTriangleBoldIcon }],
	[Icon.WITNESS, { color: 'lima', icon: WitnessIcon }],
	[Icon.ZIP, { color: 'lima', icon: ZipIcon }],
	[Icon.LOADING, { color: 'lima', icon: LoadingIcon }],
	[Icon.CHAT, { color: 'crayon-blue', icon: ChatIcon }],
	[Icon.CHAT_APP, { color: 'crayon-blue', icon: ChatAppIcon }],
	[Icon.MARKER, { color: 'lima', icon: MarkerIcon }],
])

export function getIconComponent(icon: Icon | null | undefined): Component {
	if (!icon) {
		return QuestionMarkIcon
	}

	return icons.get(icon)?.icon || QuestionMarkIcon
}

export function getIconColor(icon: Icon | null | undefined): DefaultThemeColor | null {
	if (!icon) {
		return null
	}

	return icons.get(icon)?.color || null
}
