<script setup lang="ts"></script>

<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="fill-current"
			d="M10.5 6V8.25H12.1575L9.5925 14.25H7.5V16.5H13.5V14.25H11.8425L14.4075 8.25H16.5V6H10.5Z"
		/>
	</svg>
</template>
