<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M6 9L12 15L18 9"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="2"
		/>
	</svg>
</template>
