<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M20.341 11.7591C21.2197 12.6378 21.2197 14.0624 20.341 14.9411C19.4623 15.8198 18.0377 15.8198 17.159 14.9411C16.2803 14.0624 16.2803 12.6378 17.159 11.7591C18.0377 10.8804 19.4623 10.8804 20.341 11.7591Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M6.6 3.8999H4.8C3.8055 3.8999 3 4.7054 3 5.6999V9.2999C3 12.2825 5.4174 14.6999 8.4 14.6999C11.3826 14.6999 13.8 12.2825 13.8 9.2999V5.6999C13.8 4.7054 12.9945 3.8999 12 3.8999H10.2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M18.7504 15.6002V15.8252C18.7504 18.6836 16.4338 21.0002 13.5754 21.0002C10.717 21.0002 8.40039 18.6836 8.40039 15.8252V14.7002"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M6.59961 3V4.8"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M10.2002 3V4.8"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_9296">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
