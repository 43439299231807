import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

import { usePermission } from '@/composables/permission.composable'
import type { Permission } from '@/constants/permission.enum.ts'
import ROUTE_NAME from '@/router/routeName'

export function permissionMiddleware(
	permissions: Permission[]
): (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => void {
	const { hasPermission } = usePermission()

	return (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
		if (hasPermission(permissions)) {
			next()
		} else {
			next({ name: ROUTE_NAME.AUTH.NO_PERMISSION })
		}
	}
}
