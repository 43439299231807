<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M18.0952 16.4287H5.14286C4.51167 16.4287 4 16.9404 4 17.5716V18.3335C4 18.9647 4.51167 19.4763 5.14286 19.4763H18.0952C18.7264 19.4763 19.2381 18.9647 19.2381 18.3335V17.5716C19.2381 16.9404 18.7264 16.4287 18.0952 16.4287Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M5.52393 16.4286L9.44012 5.99048C9.66107 5.39619 10.2325 5 10.8649 5H12.3734C13.0058 5 13.5773 5.39619 13.7982 5.99048L17.7144 16.4286"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M6.94873 12.7871H16.2897"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M8.41895 9.13721H14.8189"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_8942">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
