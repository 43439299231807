<script lang="ts" setup></script>

<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M19 8L8.99925 17.9978L3 11.9985"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="2"
		/>
	</svg>
</template>
