<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M11.3926 5.58203V7.61617"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M11.3926 4.32019C11.3926 4.14126 11.5338 4 11.7128 4H13.6056C13.7846 4 13.9258 4.14126 13.9258 4.32019V5.58211C13.9258 5.76104 13.7846 5.9023 13.6056 5.9023H11.7128C11.5338 5.9023 11.3926 5.76104 11.3926 5.58211V4.32019Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M18.7852 19.0581H4"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M16.1391 12.3342L16.0544 11.8727C15.64 9.62201 13.6812 7.9834 11.3928 7.9834C9.1044 7.9834 7.13618 9.62201 6.73124 11.8727L6.64648 12.3342"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M8.22852 12.3345V19.0584"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M11.3926 12.3345V19.0584"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M14.5566 12.3345V19.0584"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M17.7208 19.0584V13.1255C17.7208 12.6923 17.363 12.3345 16.9298 12.3345H5.85502C5.42182 12.3345 5.06396 12.6923 5.06396 13.1255V19.0584"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M15.5928 6.7876H17.6363"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M18.3049 8.35079C17.6833 8.6239 16.9393 8.51089 16.4214 8.00235C15.7528 7.33372 15.7528 6.25073 16.4214 5.58211C16.9393 5.06415 17.6739 4.96056 18.3049 5.23367"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_8871">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
