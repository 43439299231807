<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M20 9.53296V6.53296"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M13.028 18.5H5C3.343 18.5 2 17.157 2 15.5V6.53296"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M9.136 11.518L3.085 8.331C2.418 7.979 2 7.287 2 6.533V6.5C2 5.395 2.895 4.5 4 4.5H17.967C19.09 4.5 20 5.41 20 6.533V6.553C20 7.295 19.589 7.977 18.932 8.323L12.864 11.519C11.697 12.133 10.303 12.133 9.136 11.518Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M19.5 18.5L22.5 15.5L19.5 12.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M22 15.5L16 15.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
