/* eslint-disable camelcase */
import type { AxiosPromise } from 'axios'
import axios from 'axios'

import httpClient from '@/http/httpClient'
import type { User } from '@/models/user.type'
import type { AuthTokens } from '@/modules/authentication/models/authToken.type'
import { encodeQueryData } from '@/utils/encoding.util'

enum GRANT_TYPE {
	AD = 'ad',
	PASSWORD = 'password',
	REFRESH = 'refresh_token',
}

export default class AuthenticationService {
	checkStatus(): AxiosPromise<{ status: string }> {
		return axios.get(import.meta.env.VITE_BASE_URL?.toString())
	}

	loginPassword(username: string, password: string): AxiosPromise<AuthTokens> {
		const formData = encodeQueryData({
			client_id: import.meta.env.VITE_CLIENT_ID,
			client_secret: import.meta.env.VITE_CLIENT_SECRET,
			grant_type: GRANT_TYPE.PASSWORD,
			password: password,
			username: username,
		})

		const config = {
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
		}
		return httpClient.post('/auth/token', formData, config)
	}

	loginActiveDirectory(code: string, state: string): AxiosPromise<AuthTokens> {
		const formData = encodeQueryData({
			client_id: import.meta.env.VITE_CLIENT_ID,
			client_secret: import.meta.env.VITE_CLIENT_SECRET,
			code: code,
			grant_type: GRANT_TYPE.AD,
			state: state,
		})

		const config = {
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
		}
		return httpClient.post('/auth/token', formData, config)
	}

	refresh(refreshToken: string | null): AxiosPromise<AuthTokens> {
		if (!refreshToken) {
			throw new Error('No refresh token')
		}

		const formData = encodeQueryData({
			client_id: import.meta.env.VITE_CLIENT_ID,
			client_secret: import.meta.env.VITE_CLIENT_SECRET,
			grant_type: GRANT_TYPE.REFRESH,
			refresh_token: refreshToken,
		})

		const config = {
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
		}
		return axios.post(`${import.meta?.env.VITE_BASE_URL?.toString()}/auth/token`, formData, config)
	}

	getActiveDirectoryLoginUrl(): AxiosPromise<{ url: string }> {
		return httpClient.get('/auth/ad/signin')
	}

	getActiveDirectorySignOutUrl(): AxiosPromise<{ url: string }> {
		return httpClient.get('/auth/ad/signout')
	}

	getUserInfo(): AxiosPromise<User> {
		return httpClient.get(`/auth/userinfo`)
	}
}
