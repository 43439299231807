<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M19.0208 11.1116C20.3938 9.64758 20.3111 7.34814 18.8471 5.98336C17.383 4.61859 15.0836 4.69303 13.7188 6.15706L11.9984 7.99331L10.2779 6.15706C8.91316 4.68476 6.61372 4.61032 5.14969 5.97509C3.68566 7.33987 3.61122 9.64758 4.97599 11.1116L11.9984 18.6137L19.0208 11.1116Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
