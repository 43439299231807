<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 19 10"
	>
		<g opacity="0.6">
			<path
				class="stroke-current"
				d="M5.23828 4.68916L9.77955 9.2204L18 1"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="1.5"
			/>
			<path
				class="stroke-current"
				d="M1 4.68916L5.53125 9.22041L13.7617 1"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="1.5"
			/>
		</g>
	</svg>
</template>
