<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M9.56982 16.5034V16.9342C9.56982 18.0764 10.4998 19.0081 11.6398 19.0081C12.7798 19.0081 13.7098 18.0764 13.7098 16.9342V16.5034"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M13.11 4.22041C12.65 4.08015 12.16 4 11.65 4C8.89 4 6.66 6.23419 6.66 8.99937V12.3757L5.53 13.5078C5.19 13.8485 5 14.3093 5 14.7902C5 15.732 5.76 16.5034 6.71 16.5034H16.6C17.54 16.5034 18.31 15.742 18.31 14.7902C18.31 14.3093 18.12 13.8485 17.78 13.5078L16.65 12.3757V10.7727"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="fill-vivid-red"
			d="M16.4404 9C17.8211 9 18.9404 7.88071 18.9404 6.5C18.9404 5.11929 17.8211 4 16.4404 4C15.0597 4 13.9404 5.11929 13.9404 6.5C13.9404 7.88071 15.0597 9 16.4404 9Z"
		/>

		<defs>
			<clipPath id="clip0_1366_8988">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
