import * as Sentry from '@sentry/browser'
import { useLocalStorage, useStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
import { computed, nextTick, ref } from 'vue'

import type { RecentSearch } from '@/components/layout/search-bar/the-search-bar/searchItem.type'
import { useLoading } from '@/composables/loading/loading.composable'
import type { Permission } from '@/constants/permission.enum'
import { oAuthClient } from '@/http/oAuthClient'
import type { User } from '@/models/user.type'
import AuthenticationService from '@/modules/authentication/services/authentication.service'
import { formatFullName } from '@/utils/name/name.util'
import { CLEAR_IN_MERGE_VALUE } from '@/utils/pagination.util'

export const useAuthStore = defineStore('AuthStore', () => {
	const loadingState = useLoading()

	const authenticationService = new AuthenticationService()

	const recentSearches = useStorage<RecentSearch[]>('recentSearches', [])

	const authenticatedUser = ref<User | null>(null)

	const isAuthenticated = computed<boolean>(() => oAuthClient.isLoggedIn())
	const scannerIpAddress = useLocalStorage<string | null>('scannerIpAddress', null)
	const currentDepartmentUuid = useStorage<string | null>('currentDepartment', null)

	function hasPermission(permissions: Permission[] | undefined): boolean {
		if (!permissions) {
			return true
		}

		if (Number(authenticatedUser?.value?.staff?.role?.permissions?.length ?? 0) === 0) {
			return false
		}

		return (
			authenticatedUser.value?.staff?.role?.permissions.some((permission) => {
				return permissions.includes(permission)
			}) ?? false
		)
	}

	async function loginAuthorization(code: string, state: string): Promise<void> {
		try {
			loadingState.setLoadingState(true)
			await oAuthClient.loginAuthorization(code, state, 'ad')
		} finally {
			loadingState.setLoadingState(false)
		}
	}

	async function login(username: string, password: string): Promise<void> {
		try {
			loadingState.setLoadingState(true)
			await oAuthClient.login(username, password)
		} finally {
			loadingState.setLoadingState(false)
		}
	}

	async function fetchAuthenticatedUserInfo(): Promise<void> {
		if (authenticatedUser.value || loadingState.isLoading.value || !oAuthClient.isLoggedIn()) {
			return
		}

		try {
			loadingState.setLoadingState(true)
			const response = await authenticationService.getUserInfo()
			authenticatedUser.value = response.data

			Sentry.setUser({
				email: authenticatedUser.value.email,
				id: authenticatedUser.value.uuid,
				username: formatFullName(authenticatedUser.value.firstName, authenticatedUser.value.lastName),
			})
		} finally {
			loadingState.setLoadingState(false)
		}
	}

	async function logout(): Promise<void> {
		authenticatedUser.value = null
		await oAuthClient.logout()
	}

	async function setCurrentDepartment(departmentUuid: string | null): Promise<void> {
		if (!departmentUuid) {
			await nextTick(() => {
				currentDepartmentUuid.value = CLEAR_IN_MERGE_VALUE
			})
			await nextTick(() => {
				currentDepartmentUuid.value = null
			})
			return
		}

		currentDepartmentUuid.value = departmentUuid
	}

	function setScannerIpAddress(ipAddress: string | null): void {
		scannerIpAddress.value = ipAddress
	}

	function addRecentSearch(recentSearch: RecentSearch): void {
		const recentSearchesValue = recentSearches.value
		const index = recentSearchesValue.findIndex((item) => item.uuid === recentSearch.uuid)

		if (index !== -1) {
			recentSearchesValue.splice(index, 1)
		}

		recentSearchesValue.unshift(recentSearch)
		recentSearches.value = recentSearchesValue
	}

	function clearRecentSearches(): void {
		recentSearches.value = []
	}

	return {
		setScannerIpAddress,
		scannerIpAddress,
		recentSearches,
		authenticatedUser,
		currentDepartmentUuid,
		addRecentSearch,
		clearRecentSearches,
		fetchAuthenticatedUserInfo,
		hasPermission,
		isAuthenticated,
		isLoading: loadingState.isLoading,
		login,
		loginAuthorization,
		logout,
		setCurrentDepartment,
	}
})
