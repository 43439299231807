<script setup lang="ts"></script>

<template>
	<svg
		fill="none"
		viewBox="0 0 32 32"
	>
		<path
			class="fill-current"
			d="M10 21H13L15 17V11H9V17H12L10 21ZM18 21H21L23 17V11H17V17H20L18 21Z"
		/>
	</svg>
</template>
