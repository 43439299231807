<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M8.75 13.5H6.75"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M18.3799 10.29L20.4999 9.22998"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M5.62 10.29L3.5 9.22998"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M18.4498 10.6598L17.4898 6.54977C17.2798 5.64977 16.4698 5.00977 15.5398 5.00977H8.4598C7.5298 5.00977 6.7198 5.64977 6.5098 6.54977L5.5498 10.6598"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M6.5 10.5C4.84 10.5 3.5 11.84 3.5 13.5V18C3.5 18.55 3.95 19 4.5 19H6.74C7.29 19 7.74 18.55 7.74 18V16.5H16.27V18C16.27 18.55 16.72 19 17.27 19H19.51C20.06 19 20.51 18.55 20.51 18V13.5C20.51 11.84 19.17 10.5 17.51 10.5H6.5Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M15.25 13.5H17.25"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_9302">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
