<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 25 24"
	>
		<path
			class="stroke-current"
			d="M14.5 12V3.5C14.5 2.672 13.828 2 13 2C12.172 2 11.5 2.672 11.5 3.5V12"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M14.5 5.5C14.5 4.672 15.172 4 16 4C16.828 4 17.5 4.672 17.5 5.5V12"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M11.5 5.5C11.5 4.672 10.828 4 9.99999 4C9.17199 4 8.49999 4.672 8.49999 5.5V11V14L6.59899 12.099C5.99199 11.492 5.00799 11.492 4.40099 12.099C3.87699 12.623 3.79599 13.444 4.20699 14.06L7.71899 19.328C8.83199 20.997 10.705 22 12.711 22H14.5C17.814 22 20.5 19.314 20.5 16V11V7.5C20.5 6.672 19.828 6 19 6C18.172 6 17.5 6.672 17.5 7.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
