<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M9.96 12.4902H4.54C4.24177 12.4902 4 12.7323 4 13.0309V18.4577C4 18.7563 4.24177 18.9984 4.54 18.9984H9.96C10.2582 18.9984 10.5 18.7563 10.5 18.4577V13.0309C10.5 12.7323 10.2582 12.4902 9.96 12.4902Z"
			stroke-miterlimit="10"
		/>
		<path
			class="stroke-current"
			d="M18.46 4H4.54C4.24177 4 4 4.24207 4 4.54068V9.96746C4 10.2661 4.24177 10.5081 4.54 10.5081H18.46C18.7582 10.5081 19 10.2661 19 9.96746V4.54068C19 4.24207 18.7582 4 18.46 4Z"
			stroke-miterlimit="10"
		/>
		<path
			class="stroke-current"
			d="M18.46 12.4902H13.04C12.7418 12.4902 12.5 12.7323 12.5 13.0309V18.4577C12.5 18.7563 12.7418 18.9984 13.04 18.9984H18.46C18.7582 18.9984 19 18.7563 19 18.4577V13.0309C19 12.7323 18.7582 12.4902 18.46 12.4902Z"
			stroke-miterlimit="10"
		/>
		<path
			class="stroke-current"
			d="M5.62012 8.77579L7.54012 8.07492L10.1101 8.55552L12.7401 7.33399L15.2701 8.05489L17.3801 6.42285"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M9.30982 5.99219H7.31982"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M16.75 16.5151H14.75"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M16.75 14.9834H14.75"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M8.81982 15.7446C8.81982 16.5756 8.14982 17.2464 7.31982 17.2464C6.48982 17.2464 5.81982 16.5756 5.81982 15.7446C5.81982 14.9135 6.48982 14.2427 7.31982 14.2427"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_8843">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
