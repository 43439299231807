<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M14.5161 6.41797L18.3636 10.2655C18.9793 10.8826 18.9793 11.8828 18.3636 12.5L15.2836 15.58"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M18.7119 8.34042L8.89946 18.1543C8.65231 18.4015 8.34734 18.585 8.01346 18.6891L6.05502 19.3005C5.48555 19.4783 4.8785 19.1617 4.69928 18.5908C4.63279 18.3798 4.63279 18.1558 4.69928 17.9448L5.31066 15.9863C5.41184 15.651 5.59395 15.3446 5.83966 15.0945L15.6478 5.2864C16.4933 4.43943 17.865 4.43798 18.7134 5.28062C19.5589 6.12615 19.5589 7.49489 18.7134 8.34042H18.7119Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_8893">
				<rect fill="white" />
			</clipPath>
			<clipPath id="clip1_1366_8893">
				<rect
					fill="white"
					height="16"
					transform="translate(4 4)"
					width="15.9971"
				/>
			</clipPath>
		</defs>
	</svg>
</template>
