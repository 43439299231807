<script lang="ts" setup>
import { onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import AppLoadingCover from '@/components/app/loading-cover/AppLoadingCover.vue'
import AppRefreshPromptModal from '@/components/app/refresh-prompt/AppRefreshPromptModal.vue'
import { useModalContainer } from '@/composables/dynamicModal.composable'
import { useEnvironment } from '@/composables/environment.composable'
import { usePrimeVueConfig } from '@/composables/primeVueConfig.composable'
import { useRefreshServiceWorker } from '@/composables/service-worker/useRefreshServiceWorker.composable'
import AuthenticationService from '@/modules/authentication/services/authentication.service'
import dayjs from '@/plugins/dayjs.plugin'
import ROUTE_NAME from '@/router/routeName'
import { useLanguageStore } from '@/stores/language.store'
import { logInfo } from '@/utils/logger.util'

const route = useRoute()
const router = useRouter()
const primeVueConfig = usePrimeVueConfig()

const languageStore = useLanguageStore()
const authenticationService = new AuthenticationService()

const { version } = useEnvironment()
const { modals } = useModalContainer()

const refreshServiceWorker = useRefreshServiceWorker()

function onRefreshServiceWorkerButtonClick(): void {
	refreshServiceWorker.refresh()
}

function onCancelRefreshServiceWorkerButtonClick(): void {
	refreshServiceWorker.cancel()
}

async function checkStatus(): Promise<void> {
	try {
		await authenticationService.checkStatus()

		if (route.name === ROUTE_NAME.AUTH.MAINTENANCE) {
			await router.push({ name: ROUTE_NAME.DASHBOARD })
		}
	} catch {
		await router.push({ name: ROUTE_NAME.AUTH.MAINTENANCE })
	}
}

onMounted(() => {
	checkStatus()
	logInfo(`App version: ${version.value ?? 'NOT FOUND'}`)
	languageStore.initialize()
	primeVueConfig.setPrimeVueConfig()
	dayjs.locale('nl')
})
</script>

<template>
	<section class="relative h-screen w-screen overflow-hidden">
		<router-view v-slot="{ Component }">
			<transition
				mode="in-out"
				name="fade"
			>
				<AppLoadingCover v-if="!Component" />
			</transition>
			<transition
				mode="in-out"
				name="fade"
			>
				<section v-if="Component">
					<component :is="Component" />

					<template
						v-for="modal in modals"
						:key="modal.id"
					>
						<component
							:is="modal.component"
							v-bind="modal.attrs"
							:is-open="modal.isOpen"
						/>
					</template>
				</section>
			</transition>
		</router-view>
		<AppRefreshPromptModal
			:is-open="refreshServiceWorker.needRefresh.value"
			@close="onCancelRefreshServiceWorkerButtonClick"
			@success="onRefreshServiceWorkerButtonClick"
		/>
	</section>
</template>
