<script lang="ts" setup></script>

<template>
	<svg viewBox="0 0 24 24">
		<path
			class="fill-transparent stroke-current"
			d="M18 15.5V19C18 19.5304 17.7893 20.0391 17.4142 20.4142C17.0391 20.7893 16.5304 21 16 21H8C7.46957 21 6.96086 20.7893 6.58579 20.4142C6.21071 20.0391 6 19.5304 6 19V5C6 4.46957 6.21071 3.96086 6.58579 3.58579C6.96086 3.21071 7.46957 3 8 3H16C16.5304 3 17.0391 3.21071 17.4142 3.58579C17.7893 3.96086 18 4.46957 18 5V6.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="fill-transparent stroke-current"
			d="M15.5921 15.8304C14.8525 15.8304 14.1526 15.6669 13.5236 15.3754L10.5 16.0173L11.1324 12.9876C10.8391 12.357 10.6748 11.6536 10.6748 10.9122C10.6748 8.20002 12.8739 6 15.5878 6C18.3017 6 20.5 8.20002 20.5 10.9122C20.5 13.6244 18.3 15.8244 15.5878 15.8244"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="fill-current"
			d="M13.8215 10.5157C14.0749 10.7691 14.0749 11.1799 13.8215 11.4333C13.5681 11.6867 13.1573 11.6867 12.9039 11.4333C12.6505 11.1799 12.6505 10.7691 12.9039 10.5157C13.1573 10.2623 13.5681 10.2623 13.8215 10.5157Z"
		/>
		<path
			class="fill-current"
			d="M16.0759 10.5157C16.3293 10.7691 16.3293 11.1799 16.0759 11.4333C15.8225 11.6867 15.4117 11.6867 15.1583 11.4333C14.9049 11.1799 14.9049 10.7691 15.1583 10.5157C15.4117 10.2623 15.8225 10.2623 16.0759 10.5157Z"
		/>
		<path
			class="fill-current"
			d="M18.3293 10.5157C18.5827 10.7691 18.5827 11.1799 18.3293 11.4333C18.0759 11.6867 17.6651 11.6867 17.4117 11.4333C17.1583 11.1799 17.1583 10.7691 17.4117 10.5157C17.6651 10.2623 18.0759 10.2623 18.3293 10.5157Z"
		/>
		/>
	</svg>
</template>
