<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M3 21V12C3 11.7348 3.10536 11.4804 3.29289 11.2929C3.48043 11.1054 3.73478 11 4 11H7"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M11 8.00011V4.50147C11 4.2591 11.0587 4.02034 11.1712 3.80562C11.2836 3.59091 11.4464 3.40663 11.6456 3.26858C11.8448 3.13053 12.0745 3.0428 12.315 3.01291C12.5556 2.98303 12.7997 3.01187 13.0267 3.09697L20.3511 5.84364C20.5417 5.91512 20.706 6.04306 20.8219 6.21037C20.9379 6.37769 21 6.5764 21 6.77997V21.0001"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M7 21V9C7 8.73478 7.10536 8.48043 7.29289 8.29289C7.48043 8.10536 7.73478 8 8 8H15C15.2652 8 15.5196 8.10536 15.7071 8.29289C15.8946 8.48043 16 8.73478 16 9V21"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M22 21H2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M10 17.5H13"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M10 14.5H13"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M10 11.5H13"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
