<script lang="ts" setup></script>

<template>
	<svg viewBox="0 0 24 24">
		<path
			class="fill-transparent stroke-current"
			d="M14 18L18 21V18H19C20.105 18 21 17.105 21 16V10C21 8.895 20.105 8 19 8H11C9.895 8 9 8.895 9 10V16C9 17.105 9.895 18 11 18H14Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="fill-transparent stroke-current"
			d="M9 15L6 17V14H5C3.895 14 3 13.105 3 12V6C3 4.895 3.895 4 5 4H14C15.105 4 16 4.895 16 6V8"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="fill-current"
			d="M13.0303 12.7197C13.3232 13.0126 13.3232 13.4874 13.0303 13.7803C12.7374 14.0732 12.2626 14.0732 11.9697 13.7803C11.6768 13.4874 11.6768 13.0126 11.9697 12.7197C12.2626 12.4268 12.7374 12.4268 13.0303 12.7197Z"
		/>
		<path
			class="fill-current"
			d="M15.6353 12.7197C15.9282 13.0126 15.9282 13.4874 15.6353 13.7803C15.3424 14.0732 14.8675 14.0732 14.5746 13.7803C14.2818 13.4874 14.2818 13.0126 14.5746 12.7197C14.8675 12.4268 15.3424 12.4268 15.6353 12.7197Z"
		/>
		<path
			class="fill-current"
			d="M18.2403 12.7197C18.5332 13.0126 18.5332 13.4874 18.2403 13.7803C17.9474 14.0732 17.4725 14.0732 17.1796 13.7803C16.8867 13.4874 16.8867 13.0126 17.1796 12.7197C17.4725 12.4268 17.9474 12.4268 18.2403 12.7197Z"
		/>
	</svg>
</template>
