<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M13.7366 5.8001C14.8034 6.8669 14.8034 8.59652 13.7366 9.66332C12.6698 10.7301 10.9401 10.7301 9.87334 9.66332C8.80654 8.59652 8.80654 6.8669 9.87334 5.8001C10.9401 4.7333 12.6698 4.7333 13.7366 5.8001Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="fill-current"
			d="M16.7803 11.2197C17.0732 11.5126 17.0732 11.9874 16.7803 12.2803C16.4874 12.5732 16.0126 12.5732 15.7197 12.2803C15.4268 11.9874 15.4268 11.5126 15.7197 11.2197C16.0126 10.9268 16.4874 10.9268 16.7803 11.2197Z"
		/>
		<path
			class="stroke-current"
			d="M14.5209 8.01224C14.7754 7.94668 15.0197 7.85067 15.2405 7.70863C15.6003 7.47682 16.0273 7.3418 16.4878 7.3418H17.2682V9.33516C17.8286 9.84092 18.2735 10.4716 18.5373 11.1935H19.2195C19.6503 11.1935 20 11.5432 20 11.974V14.3662C20 14.797 19.6503 15.1467 19.2195 15.1467H18.1962C17.7865 15.8554 17.1949 16.4454 16.4878 16.8567V18.2686C16.4878 18.6995 16.1381 19.0491 15.7073 19.0491H14.1463C13.7155 19.0491 13.3658 18.6995 13.3658 18.2686V17.4881H10.6341V18.1656C10.6341 18.5964 10.2844 18.9461 9.85361 18.9461H8.29264C7.86181 18.9461 7.51215 18.5964 7.51215 18.1656V16.2885C6.55605 15.4316 5.95117 14.1906 5.95117 12.8052C5.95117 10.7385 7.29205 8.98785 9.14961 8.36658"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M4.61502 10.4634C4.24898 10.6765 4 11.069 4 11.5233C4 12.2031 4.55102 12.7541 5.23083 12.7541H5.95122"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M9.46338 10.4634H14.1463"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
