<script lang="ts" setup></script>
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M4 12H20"
			stroke-linecap="round"
			stroke-width="2"
		/>
		<path
			class="stroke-current"
			d="M12 20L12 4"
			stroke-linecap="round"
			stroke-width="2"
		/>
	</svg>
</template>
