<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M6.2925 8.71651V7.64242C6.28706 5.9728 7.6358 4.6159 9.30541 4.61183C10.3904 4.60911 11.3938 5.18694 11.9349 6.1278H13.871C15.1259 6.1278 16.1443 7.14615 16.1443 8.40108V8.71651"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M11.1787 12.0002L16.6634 15.0525C17.5485 15.5447 18.6648 15.2252 19.157 14.3401C19.1692 14.317 19.1828 14.2939 19.1937 14.2694C19.6328 13.3911 19.3024 12.3224 18.4445 11.8466L17.3731 11.2456"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M17.1094 8.7367C17.0483 8.72311 16.9871 8.71631 16.9245 8.71631H9.95784C9.93201 9.70747 9.12303 10.5042 8.12507 10.5042C7.12711 10.5042 6.31678 9.70747 6.29231 8.71631H5.43303C4.98027 8.71631 4.61182 9.08341 4.61182 9.53752C4.61182 9.60006 4.61861 9.66124 4.63221 9.72243L6.71651 18.753C6.80216 19.1255 7.13391 19.3893 7.51596 19.3893H14.8402C15.2223 19.3893 15.554 19.1255 15.6397 18.753L17.724 9.72243C17.826 9.28055 17.55 8.84003 17.1081 8.73806L17.1094 8.7367Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_8890">
				<rect fill="white" />
			</clipPath>
			<clipPath id="clip1_1366_8890">
				<rect
					fill="white"
					height="16"
					transform="translate(4 4)"
					width="16"
				/>
			</clipPath>
		</defs>
	</svg>
</template>
