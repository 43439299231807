<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M11.5 13.7622L12.194 17.9282C12.419 19.2812 11.376 20.5122 10.005 20.5122C8.92 20.5122 7.994 19.7282 7.816 18.6572L7 13.7622"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M13.75 13.7622H5.875C4.011 13.7622 2.5 12.2512 2.5 10.3872C2.5 8.52321 4.011 7.01221 5.875 7.01221H13.75"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M13.75 7.01187L18.751 3.67787C19.499 3.17987 20.5 3.71587 20.5 4.61487V16.1599C20.5 17.0589 19.499 17.5939 18.751 17.0959L13.75 13.7619"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M13.75 13.7598V7.00977"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_9336">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
