<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M4 19.9999V19.6766C4 18.4721 4.89747 17.435 6.14415 17.1991L8.67393 16.7199C8.88295 16.6803 9.10002 16.7209 9.27748 16.8327C10.9261 17.8745 13.0742 17.8745 14.7228 16.8327C14.9003 16.7209 15.1173 16.6803 15.3264 16.7199L17.8561 17.1991C19.1027 17.4352 20.0001 18.4722 20 19.6766V19.9999"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M14.3462 14.1052H16.4449C17.1812 14.1052 17.7782 13.5397 17.7782 12.8421V9.47367C17.7782 6.45065 15.1914 4 12.0004 4C8.80945 4 6.22266 6.45065 6.22266 9.47367V12.8421C6.22266 13.5397 6.81961 14.1052 7.55599 14.1052H9.65465"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M10.2221 7.88965H13.7777C14.7595 7.88965 15.5554 8.64369 15.5554 9.57385V11.5789C15.5554 13.4392 13.9636 14.9473 11.9999 14.9473C10.0362 14.9473 8.44434 13.4392 8.44434 11.5789V9.57385C8.44434 8.64369 9.24027 7.88965 10.2221 7.88965Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M7.55566 20H16.4445"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M15.5562 20.0003V16.7666"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M8.44434 20.0003V16.7666"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M13.8672 14.4424L14.525 16.9518"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M10.1334 14.4424L9.47559 16.9518"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
