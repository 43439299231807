<script lang="ts" setup></script>

<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<g clip-path="url(#clip0_1768_7021)">
			<path
				class="stroke-current"
				d="M17.105 12.54L19.292 10.353C20.903 8.74204 20.903 6.13004 19.292 4.51904C17.681 2.90804 15.069 2.90804 13.458 4.51904L11.271 6.70604"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				class="stroke-current"
				d="M8.25 15.561L10.125 13.686"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				class="stroke-current"
				d="M13.875 9.93604L15.75 8.06104"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				class="stroke-current"
				d="M19.0312 18.842L4.96924 4.78003"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				class="stroke-current"
				d="M6.89476 11.082L4.70776 13.269C3.09676 14.88 3.09676 17.492 4.70776 19.103C6.31876 20.714 8.93076 20.714 10.5418 19.103L12.7288 16.916"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</g>
	</svg>
</template>
