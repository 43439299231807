import type { MaybeRefOrGetter, Ref } from 'vue'

import type { CalendarViewType } from '@/components/calendar/calendarEvent.type'
import type { PaginationOptions } from '@/models/axios/paginationOptions.type'
import type { NoteFilters } from '@/models/note/noteFilter.type'
import type { AccidentIndexFilter } from '@/modules/accidents/models/index/accidentIndex.filter'
import type { AnomaliesToValidateTableFilter } from '@/modules/anomalies/models/anomaliesToValidateTableItem.model'
import type { ClaimFilter } from '@/modules/claims/models/claim.filter'
import type { CustomerFilter } from '@/modules/customers/models/customerTableFilters.type'
import type { CustomerTermsOfServiceFilter } from '@/modules/customers/models/terms-of-service/customerContractTermsOfServiceTableFilters'
import type { EmployeeDimonaDeclarationIndexFilters } from '@/modules/employees/model/dimona-declaration/index/employeeDimonaDeclarationIndexFilters.type'
import type { EmployeeFilter } from '@/modules/employees/model/employee.filter'
import type { EmployeeWageBenefitFilter } from '@/modules/employees/model/wage-benefit/index/employeeWageBenefitIndexFilters.type'
import type { MatchFilter } from '@/modules/matching-center/models/match.filter'
import type { NewsFilter } from '@/modules/news/models/newsFilters.type'
import type { StaffFilter } from '@/modules/settings/models/staff/staffTableFilters.type'

export enum QueryKey {
	ABSENCES = 'absences',
	ABSENCE_CODES = 'absence-codes',

	ACCIDENTS_INDEX = 'accidents-index',
	ACCIDENTS_COUNT = 'accidents-count',
	ACCIDENTS_DETAIL = 'accidents-detail',

	ADVANCE_AMOUNTS = 'advance-amounts',
	ADVANCE_AMOUNT_DETAIL = 'advance-amount-detail',

	ADDRESS_HISTORY = 'address-history',

	BAREMA = 'barema',
	BAREMA_CURRENT = 'barema-current',

	BENEFIT_CODE = 'benefit-code',
	BRANCHES = 'branches',

	CLAIMS_INDEX = 'claims',
	CLAIMS_COUNT = 'claims-count',

	COMPENSATIONS_ADVANCES = 'compensations-advances',
	COMPENSATIONS_DETAIL = 'compensations-detail',
	COMPENSATIONS_CALCULATED_DETAIL = 'compensations-calculated-detail',
	COMPENSATIONS_STATUS = 'compensations-status',
	COMPENSATIONS_DETAILS = 'compensations-details',
	COMPENSATIONS_OVERVIEW = 'compensations-overview',
	COMPENSATIONS_OVERVIEW_DEPARTMENT = 'compensations-overview-department',
	COMPENSATIONS_OVERVIEW_PAYROLL = 'compensations-overview-payroll',
	COMPENSATIONS_PAYMENTS = 'compensations-payments',
	COMPENSATIONS_BENEFIT_OVERVIEW = 'compensations-benefit-overview',
	COMPENSATIONS_CORRECTIONS_ADMIN = 'compensations-corrections-admin',
	COMPENSATIONS_CORRECTIONS_OPEN = 'compensations-corrections-open',
	COMPENSATIONS_IS_CONFIRMED = 'compensations-is-confirmed',
	COMPENSATIONS_SALARY_STATUS = 'compensations-salary-status',
	COMPENSATIONS_FOR_MONTH_STATUS = 'compensations-for-month-status',
	COMPENSATIONS_MONTHS = 'compensations-months',

	COUNTRIES = 'countries',

	CUSTOMERS_INDEX = 'customers-index',
	CUSTOMERS_COUNT = 'customers-count',
	CUSTOMERS_THREAD_DETAIL = 'customers-thread-detail',
	CUSTOMER_DETAIL = 'customer-details',
	CUSTOMER_TERMS_OF_SERVICES = 'customer-terms-of-services',
	CUSTOMER_ACTIVE_SUBSCRIPTION = 'customer-active-subscription',
	CUSTOMER_RECOMMENDED_SUBSCRIPTION = 'customer-recommended-subscription',
	CUSTOMER_ACTIVE_SUBSCRIPTION_INVOICES = 'customer-active-subscription-invoices',
	CUSTOMER_REGULAR_EMPLOYEES = 'customer-regular-employees',
	CUSTOMER_ABSENCES = 'customer-absences',
	CUSTOMER_PLANNING_WEEK = 'customer-planning-week',
	CUSTOMER_PLANNING_DAY = 'customer-planning-day',

	DASHBOARD_KPIS = 'dashboard-kpis',
	DEBTORS_EMPLOYEE_CASES = 'debtors-employee-cases',
	DEBTORS_OPEN_CASES = 'debtors-open-cases',
	DEBTOR_CASES_COUNT = 'debtors-cases-count',
	DEBTOR_CLOSED_CASES = 'debtors-closed-cases',
	DEBTOR_NEW_CASES = 'debtors-new-cases',
	DEBTOR_EMPLOYEE_DETAIL = 'debtor_employee_detail',
	DEBTOR_CUSTOMER_DETAIL = 'debtor_customer_detail',

	USER_DEPARTMENTS = 'user_departments',
	DEPARTMENTS = 'departments',

	DISTRICTS = 'districts',

	EMPLOYEE_DIMONA_DECLARATION_INDEX = 'employee-dimona-declaration-index',

	EMPLOYEES_INDEX = 'employees',
	EMPLOYEES_COUNT = 'employees-count',

	EMPLOYEE_ABSENCE_INDEX = 'employee-absence-index',
	EMPLOYEE_ABSENCE_DETAIL = 'employee-absence-detail',

	EMPLOYEE_ABSENCE_TODAY = 'employee-absences-today',

	EMPLOYEE_ABSENCE_ILLNESS_INDEX = 'employee-absences-illness-index',
	EMPLOYEE_ABSENCE_ILLNESS_DETAIL = 'employee-absences-illness-detail',

	EMPLOYEE_ABSENCE_PREGNANCY_INDEX = 'employee-absences-pregnancy-index',
	EMPLOYEE_ABSENCE_PREGNANCY_DETAIL = 'employee-absences-pregnancy-detail',

	EMPLOYEE_ADVANCE_PAYMENT_REJECTIONS_INDEX = 'employee-advance-payment-rejections-index',
	EMPLOYEE_CONTRACT_INDEX = 'employee-contract-index',
	EMPLOYEE_CONTRACT_DETAIL = 'employee-contract-detail',
	EMPLOYEE_CONTRACT_TIMETABLE_DETAIL = 'employee-contract-timetable-detail',
	EMPLOYEE_DETAIL = 'employee-detail',
	EMPLOYEE_ECONOMIC_UNEMPLOYMENT_PERIOD = 'employee-economic-unemployment-period',
	EMPLOYEE_ECONOMIC_UNEMPLOYMENT_ACTIVATION_DATES = 'employee-economic-unemployment-activation-dates',
	EMPLOYEE_ECONOMIC_UNEMPLOYMENT_REPLACES_FIRST = 'employee-economic-unemployment-replaces-first',
	EMPLOYEE_PERFORMANCE_OVERVIEW = 'employee-performance-overview',
	EMPLOYEE_TAX_WITHHOLDING = 'employee-tax-withholding',
	EMPLOYEE_THREAD_DETAIL = 'employee-thread-detail',
	EMPLOYEE_WAGE = 'employee-wage',

	EMPLOYEE_WAGE_BENEFIT_INDEX = 'employee-wage-benefit-index',
	EMPLOYEE_WAGE_BENEFIT_DETAIL = 'employee-wage-benefit-detail',

	EMPLOYEE_DEPARTMENTS = 'employee-departments',
	EMPLOYEE_MANDATORY_WORK = 'employee-mandatory-work',
	EMPLOYEE_DOCUMENTS = 'employee-documents',
	EMPLOYEE_REFERRAL_PREMIUMS = 'employee-referral-premiums',
	EMPLOYEE_SALARY_CONFIRMED = 'employee-salary-confirmed',
	EMPLOYEE_PLUXEE_LOGIN = 'employee-pluxee-login',
	EMPLOYEE_PLANNING_CALENDAR = 'employee-planning-calendar',
	EMPLOYEE_PLANNING_DAY = 'employee-planning-day',
	EMPLOYEE_PLANNING_WEEK = 'employee-planning-week',
	EMPLOYEE_IS_FIRST_ABSENCE_OF_THE_MONTH = 'employee-is-first-absence-of-the-month',
	EMPLOYEE_PLANNING_MONTH = 'employee-planning-month',
	EMPLOYEE_PLANNING_DETAIL = 'employee-planning-detail',

	CUSTOMER_DOCUMENTS = 'customer-documents',

	FAQ = 'faq',
	FILES = 'files',
	GLOBAL_SEARCH = 'global-search',
	INFO_BUBBLES = 'info-bubbles',
	LOGBOOK = 'logbook',
	POSSIBLE_MATCHES_FOR_CUSTOMER_INDEX = 'possible-matches-for-customer-index',
	POSSIBLE_MATCHES_FOR_EMPLOYEE_INDEX = 'possible-matches-for-employee-index',
	ACTUAL_MATCHES_FOR_CUSTOMER_INDEX = 'actual-matches-for-customer-index',
	ACTUAL_MATCHES_FOR_EMPLOYEE_INDEX = 'actual-matches-for-employee-index',
	NEWS_INDEX = 'news-index',
	NEWS_DETAIL = 'news-detail',
	NEWS_IMAGE = 'news-image',
	OFFICES = 'offices',
	OFFICE_DETAIL = 'office-detail',
	ORIGIN_COMPANY = 'origin-company',
	ORIGIN_COMPANY_DETAIL = 'origin-company-detail',
	PAPER_CHEQUES = 'paper-cheques',

	PAYROLLS = 'payrolls',
	PERFORMANCE_SHORTAGES = 'performance-shortages',

	PERMISSIONS = 'permissions',

	REGULAR_CUSTOMERS = 'regular-customers',
	REGULAR_CUSTOMERS_PLANNING = 'regular-customers-planning',

	REMITTANCE = 'remittance',
	REMITTANCE_DETAIL = 'remittance-detail',

	SCANS = 'scans',
	UNCONFIRMED_SCANS = 'unconfirmed-scans',
	PAPER_CHECKS = 'paper-checks',

	SETTINGS_SYNCHRONISATION_DIMONA_DECLARATIONS = 'settings-synchronisation-dimona-declarations',
	SETTINGS_RSZ_DECLARATIONS = 'settings-rsz-declarations',
	SETTINGS_RSZ_NOTIFICATION = 'settings-rsz-notification',
	SETTINGS_RSZ_NOTIFICATIONS_DETAIL = 'rsz-settings-notifications-detail',
	SETTINGS_RSZ_VALIDATION_BOOK = 'settings-rsz-validation-book',
	SETTINGS_TERMS_OF_SERVICE = 'settings-terms-of-service',
	SETTING_ECONOMIC_UNEMPLOYMENT_PERIOD = 'settings-economic-unemployment-period',
	SETTING_PARTENA_RECORDS = 'settings-partena-records',
	SETTING_PARTENA_WARNINGS = 'settings-partena-warnings',
	SETTINGS_PARTENA_TIME_FILES = 'settings-partena-time-files',
	SETTINGS_PARTENA_TIME_FILES_DETAIL = 'settings-partena-time-files-detail',
	SETTING_PARTENA_ERP_FILES = 'settings-partena-erp-files',
	SETTINGS_PARTENA_ERP_FILES_DETAIL = 'settings-partena-erp-files-detail',
	SETTINGS_SUBSIDIES = 'settings-subsidies',
	SETTINGS_CRON_JOB = 'settings-cron-job',
	SETTINGS_ACTIVE_JOB = 'settings-active-job',
	SETTINGS_ARCHIVED_JOB = 'settings-archived-job',
	SETTINGS_COMPENSATION_HOLIDAYS_DETAIL = 'settings-compensation-holidays-detail',

	STAFF_INDEX = 'staff_index',
	STAFF_DETAIL = 'staff_detail',

	SUBSIDIES = 'subsidies',
	SUBSIDIES_DETAIL = 'subsidies-detail',
	TODOS = 'todos',
	TODOS_FOLLOWING = 'todos-following',

	ANOMALIES_EXCESS = 'anomalies-excess',
	ANOMALIES_SHORTAGES = 'anomalies-shortages',
	ANOMALIES_TO_VALIDATE = 'anomalies-to-validate',

	CUSTOMER_SUBSCRIPTIONS = 'customer-subscriptions',
	CLAIMS_DETAIL = 'claims-detail',
	USER_ROLE = 'user-role',

	TAX_WITHHOLDING = 'tax-withholding',

	REFERRAL_PREMIUMS = 'referral-premiums',
	REFERRAL_PREMIUMS_DETAIL = 'referral-premiums-detail',
	REFERRAL_PREMIUMS_COUNT = 'referral-premiums-count',

	SUBSCRIPTIONS = 'subscriptions',

	PLANNING_REMARKS = 'planning-remarks',

	SUBSCRIPTION_INVOICES = 'subscription-invoices',

	PLUXEE_LOGIN = 'pluxee-login',

	EMPLOYEE_ABSENCE_COUNTER_INDEX = 'employee-absence-counter-index',

	CHAT_THREAD_MESSAGES = 'chat-thread-messages',

	NOTES = 'notes',
	NOTES_COUNT = 'notes_count',

	EMPLOYEE_CALLS = 'employee-calls',
}

export interface QueryKeys {
	// TODO remove MaybeRefOrGetter

	[QueryKey.ABSENCE_CODES]: void
	[QueryKey.ACCIDENTS_INDEX]: Ref<PaginationOptions<AccidentIndexFilter>>
	[QueryKey.ACCIDENTS_COUNT]: void
	[QueryKey.ACCIDENTS_DETAIL]: { accidentUuid: MaybeRefOrGetter<string> }

	[QueryKey.ANOMALIES_EXCESS]: { employeeUuid: MaybeRefOrGetter<string>; date: MaybeRefOrGetter<Date> }
	[QueryKey.ANOMALIES_TO_VALIDATE]: { paginationOptions: Ref<PaginationOptions<AnomaliesToValidateTableFilter>> }

	[QueryKey.BENEFIT_CODE]: void

	[QueryKey.CLAIMS_INDEX]: Ref<PaginationOptions<ClaimFilter>>
	[QueryKey.CLAIMS_COUNT]: void
	[QueryKey.CLAIMS_DETAIL]: { claimUuid: MaybeRefOrGetter<string> }

	[QueryKey.CUSTOMERS_INDEX]: Ref<PaginationOptions<CustomerFilter>>
	[QueryKey.CUSTOMERS_COUNT]: void
	[QueryKey.CUSTOMER_DETAIL]: { customerUuid: MaybeRefOrGetter<string> }

	[QueryKey.COMPENSATIONS_IS_CONFIRMED]: {
		employeeUuid: MaybeRefOrGetter<string>
		date: MaybeRefOrGetter<Date>
	}

	[QueryKey.POSSIBLE_MATCHES_FOR_CUSTOMER_INDEX]: Ref<PaginationOptions<MatchFilter>>
	[QueryKey.POSSIBLE_MATCHES_FOR_EMPLOYEE_INDEX]: Ref<PaginationOptions<MatchFilter>>

	[QueryKey.COMPENSATIONS_DETAIL]: {
		compensationUuid: MaybeRefOrGetter<string>
	}

	[QueryKey.COMPENSATIONS_CALCULATED_DETAIL]: {
		compensationUuid: MaybeRefOrGetter<string>
	}

	[QueryKey.EMPLOYEE_PLANNING_MONTH]: {
		employeeUuid: MaybeRefOrGetter<string>
		dateString: MaybeRefOrGetter<string>
	}

	[QueryKey.EMPLOYEE_PLANNING_DETAIL]: {
		employeeUuid: Ref<string>
		eventUuid: Ref<string>
		eventDate: Ref<string>
	}
	[QueryKey.EMPLOYEE_PLANNING_WEEK]: {
		employeeUuid: MaybeRefOrGetter<string>
		date: MaybeRefOrGetter<Date>
	}

	[QueryKey.PERMISSIONS]: void
	[QueryKey.STAFF_INDEX]: Ref<PaginationOptions<StaffFilter>>
	[QueryKey.STAFF_DETAIL]: Ref<string>

	[QueryKey.USER_ROLE]: void

	[QueryKey.EMPLOYEE_PLANNING_CALENDAR]: {
		employeeUuid: MaybeRefOrGetter<string>
		date: MaybeRefOrGetter<Date>
		calendarViewType: MaybeRefOrGetter<CalendarViewType>
	}

	[QueryKey.ANOMALIES_EXCESS]: {
		employeeUuid: MaybeRefOrGetter<string>
		date: MaybeRefOrGetter<Date>
	}

	[QueryKey.EMPLOYEES_INDEX]: Ref<PaginationOptions<EmployeeFilter>>
	[QueryKey.EMPLOYEES_COUNT]: void
	[QueryKey.EMPLOYEE_DETAIL]: {
		employeeUuid: MaybeRefOrGetter<string>
	}
	[QueryKey.EMPLOYEE_MANDATORY_WORK]: {
		employeeUuid: MaybeRefOrGetter<string>
		date: MaybeRefOrGetter<Date>
	}

	[QueryKey.EMPLOYEE_CONTRACT_INDEX]: {
		employeeUuid: MaybeRefOrGetter<string>
	}

	[QueryKey.EMPLOYEE_CONTRACT_DETAIL]: {
		employeeUuid: MaybeRefOrGetter<string>
	}

	[QueryKey.EMPLOYEE_CALLS]: {
		employeeUuid: MaybeRefOrGetter<string>
		employeeCallType?: MaybeRefOrGetter<string>
	}

	[QueryKey.EMPLOYEE_WAGE]: {
		employeeUuid: MaybeRefOrGetter<string>
	}

	[QueryKey.EMPLOYEE_DEPARTMENTS]: {
		employeeUuid: MaybeRefOrGetter<string>
	}

	[QueryKey.EMPLOYEE_ABSENCE_DETAIL]: {
		employeeUuid: MaybeRefOrGetter<string>
		absenceUuid: MaybeRefOrGetter<string>
	}

	[QueryKey.EMPLOYEE_ABSENCE_INDEX]: {
		employeeUuid: MaybeRefOrGetter<string>
		paginationOptions: Ref<PaginationOptions<AccidentIndexFilter>>
	}

	[QueryKey.EMPLOYEE_ABSENCE_ILLNESS_INDEX]: {
		employeeUuid: MaybeRefOrGetter<string>
		paginationOptions: Ref<PaginationOptions<AccidentIndexFilter>>
	}

	[QueryKey.EMPLOYEE_ABSENCE_ILLNESS_DETAIL]: {
		employeeUuid: MaybeRefOrGetter<string>
		absenceUuid: MaybeRefOrGetter<string>
	}

	[QueryKey.EMPLOYEE_ABSENCE_PREGNANCY_INDEX]: {
		employeeUuid: MaybeRefOrGetter<string>
		paginationOptions: Ref<PaginationOptions<AccidentIndexFilter>>
	}

	[QueryKey.EMPLOYEE_ABSENCE_PREGNANCY_DETAIL]: {
		employeeUuid: MaybeRefOrGetter<string>
		pregnancyUuid: MaybeRefOrGetter<string>
	}

	[QueryKey.EMPLOYEE_ABSENCE_COUNTER_INDEX]: {
		employeeUuid: MaybeRefOrGetter<string>
	}

	[QueryKey.EMPLOYEE_WAGE_BENEFIT_INDEX]: {
		employeeUuid: MaybeRefOrGetter<string>
		paginationOptions: Ref<PaginationOptions<EmployeeWageBenefitFilter>>
	}

	[QueryKey.EMPLOYEE_WAGE_BENEFIT_DETAIL]: {
		employeeUuid: MaybeRefOrGetter<string>
		benefitCodeUuid: MaybeRefOrGetter<string>
	}

	[QueryKey.EMPLOYEE_ADVANCE_PAYMENT_REJECTIONS_INDEX]: {
		employeeUuid: MaybeRefOrGetter<string>
	}

	[QueryKey.EMPLOYEE_TAX_WITHHOLDING]: {
		employeeUuid: MaybeRefOrGetter<string>
	}

	[QueryKey.EMPLOYEE_DIMONA_DECLARATION_INDEX]: {
		contractUuid: MaybeRefOrGetter<string>
		paginationOptions: Ref<PaginationOptions<EmployeeDimonaDeclarationIndexFilters>>
	}

	[QueryKey.COMPENSATIONS_SALARY_STATUS]: {
		employeeUuid: MaybeRefOrGetter<string>
		today: MaybeRefOrGetter<Date>
	}

	[QueryKey.CUSTOMER_ABSENCES]: {
		customerUuid: MaybeRefOrGetter<string>
		date: MaybeRefOrGetter<Date>
	}

	[QueryKey.CUSTOMER_PLANNING_WEEK]: {
		customerUuid: MaybeRefOrGetter<string>
		date: MaybeRefOrGetter<Date>
	}

	[QueryKey.EMPLOYEE_PLUXEE_LOGIN]: {
		employeeUuid: MaybeRefOrGetter<string>
	}

	[QueryKey.CUSTOMER_TERMS_OF_SERVICES]: {
		paginationOptions: Ref<PaginationOptions<CustomerTermsOfServiceFilter>>
		customerUuid: MaybeRefOrGetter<string>
	}

	[QueryKey.NOTES]: {
		paginationOptions: Ref<PaginationOptions<NoteFilters>>
		entityUuid: MaybeRefOrGetter<string>
		entityType: MaybeRefOrGetter<string>
	}

	[QueryKey.NOTES_COUNT]: {
		entityUuid: MaybeRefOrGetter<string>
		entityType: MaybeRefOrGetter<string>
	}

	[QueryKey.NEWS_INDEX]: {
		paginationOptions: Ref<PaginationOptions<NewsFilter>>
	}

	[QueryKey.NEWS_DETAIL]: {
		newsArticleUuid: MaybeRefOrGetter<string>
	}
}
