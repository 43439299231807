<script setup lang="ts">
import type { Component } from 'vue'
import { computed } from 'vue'

import { StyleBuilder } from '@/builders/style.builder'
import type { Icon } from '@/models/icon.enum'
import { getIconComponent } from '@/models/icon.enum'
import type { DefaultThemeColor } from '@/themes/default/colors/defaultThemeColors.type'

const props = defineProps<{
	icon: Icon
	size?: 'lg' | 'md' | 'sm' | 'xl' | 'xs'
	color?: DefaultThemeColor
}>()

const component = computed<Component | null>(() => {
	return getIconComponent(props.icon) ?? null
})

const styleClass = computed<string>(() => {
	return new StyleBuilder()
		.add('shrink-0')
		.addConditional(props.size === 'xs', 'h-3 w-3')
		.addConditional(props.size === 'sm', 'h-4 w-4')
		.addConditional(props.size === 'md', 'h-5 w-5')
		.addConditional(props.size === 'lg', 'h-6 w-6')
		.addConditional(props.size === 'xl', 'h-10 w-10')
		.addConditional(Boolean(props.color), `text-${props.color}`)
		.build()
})

const strokeWidth = computed<number>(() => {
	return props.size === 'sm' ? 1.5 : 1
})
</script>

<template>
	<Component
		:is="component"
		v-if="component"
		:class="styleClass"
		:stroke-width="strokeWidth"
	/>
</template>
