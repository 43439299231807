<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M12 13.1199V9.37988"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="fill-current stroke-current"
			d="M11.999 16.125C11.861 16.125 11.749 16.237 11.75 16.375C11.75 16.513 11.862 16.625 12 16.625C12.138 16.625 12.25 16.513 12.25 16.375C12.25 16.237 12.138 16.125 11.999 16.125Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M14.0288 4.13919L21.6878 17.5432C22.5778 19.1012 21.4528 21.0402 19.6588 21.0402H4.34076C2.54576 21.0402 1.42076 19.1012 2.31176 17.5432L9.97076 4.13919C10.8678 2.56819 13.1318 2.56819 14.0288 4.13919Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_9178">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
