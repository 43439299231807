<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M18.395 8.28983C18.9696 8.86442 18.9696 9.79602 18.395 10.3706C17.8204 10.9452 16.8888 10.9452 16.3142 10.3706C15.7397 9.79602 15.7397 8.86442 16.3142 8.28983C16.8888 7.71524 17.8204 7.71524 18.395 8.28983Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M13.6019 5.66356C14.4867 6.54831 14.4867 7.98278 13.6019 8.86753C12.7172 9.75228 11.2827 9.75228 10.3979 8.86753C9.51319 7.98278 9.51319 6.54832 10.3979 5.66356C11.2827 4.77881 12.7172 4.77881 13.6019 5.66356Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M7.6875 8.28983C8.26209 8.86442 8.26209 9.79602 7.6875 10.3706C7.11291 10.9452 6.18132 10.9452 5.60673 10.3706C5.03213 9.79602 5.03213 8.86442 5.60673 8.28983C6.18132 7.71524 7.11291 7.71524 7.6875 8.28983Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M19.9999 18.5573V14.3852C19.9999 13.3807 19.1861 12.5669 18.1817 12.5669H17.5991"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M4 18.5573V14.3852C4 13.3807 4.81385 12.5669 5.81826 12.5669H6.40083"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M15.8836 18.5567V14.0172C15.8836 12.6114 14.7439 11.4717 13.338 11.4717H10.6623C9.25638 11.4717 8.1167 12.6114 8.1167 14.0172V18.5567"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
