<script setup lang="ts"></script>
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="fill-current"
			d="M12 19.25C11.31 19.25 10.75 18.69 10.75 18C10.75 17.67 10.87 17.36 11.11 17.12C11.35 16.88 11.66 16.75 12 16.75C12.69 16.75 13.25 17.31 13.25 18C13.25 18.69 12.69 19.25 12 19.25Z"
		/>
		<path
			class="fill-current"
			d="M12 13.25C11.31 13.25 10.75 12.69 10.75 12C10.75 11.67 10.87 11.36 11.11 11.12C11.35 10.88 11.66 10.75 12 10.75C12.69 10.75 13.25 11.31 13.25 12C13.25 12.69 12.69 13.25 12 13.25Z"
		/>
		<path
			class="fill-current"
			d="M12 7.25C11.31 7.25 10.75 6.69 10.75 6C10.75 5.67 10.87 5.36 11.11 5.12C11.35 4.88 11.66 4.75 12 4.75C12.69 4.75 13.25 5.31 13.25 6C13.25 6.69 12.69 7.25 12 7.25Z"
		/>
	</svg>
</template>
