<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M12 13.5V12C13.1046 12 14 11.1046 14 10C14 8.89543 13.1046 8 12 8C10.8954 8 10 8.89543 10 10"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<circle
			class="fill-current"
			cx="12"
			cy="15.75"
			r="0.75"
		/>
		<circle
			class="fill-current"
			cx="12"
			cy="15.75"
			r="0.75"
		/>
		<path
			class="stroke-current"
			d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
