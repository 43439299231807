<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M17.2057 7.47051L15.1636 5.42842C14.0358 4.30055 12.2073 4.30055 11.0795 5.42842L10.164 6.34393C10.0228 6.48508 10.0228 6.71298 10.164 6.85413C10.2313 6.92146 10.3232 6.96031 10.4191 6.96031H10.9914C11.3747 6.96031 11.7412 7.11311 12.0118 7.38375L12.6101 7.982V9.00369L14.142 10.5356L14.9306 10.3012L15.6738 11.0432V12.0636L16.1128 12.5026C16.4353 12.8237 16.9558 12.8237 17.2782 12.5026L19.1753 10.6042C19.4964 10.2831 19.4977 9.76251 19.1766 9.44137C19.1766 9.44137 19.1753 9.44137 19.1753 9.44008L18.7376 9.00239H17.7159L16.9739 8.25911L17.2057 7.4718V7.47051Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M5.12506 18.0889C5.84892 18.8127 7.02211 18.8114 7.74597 18.0889C7.78223 18.0526 7.81719 18.0151 7.85086 17.9749L14.1442 10.5343L12.6123 9.00244L5.22477 15.376C4.45041 16.0442 4.36494 17.2148 5.03312 17.9892C5.0629 18.0241 5.09398 18.0565 5.12635 18.0889H5.12506Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_8902">
				<rect fill="white" />
			</clipPath>
			<clipPath id="clip1_1366_8902">
				<rect
					fill="white"
					height="15.214"
					transform="translate(4 4)"
					width="16"
				/>
			</clipPath>
		</defs>
	</svg>
</template>
