<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M7 6V14C7 14.7956 7.31607 15.5587 7.87868 16.1213C8.44129 16.6839 9.20435 17 10 17H18C18.7956 17 19.5587 16.6839 20.1213 16.1213C20.6839 15.5587 21 14.7956 21 14V6C21 5.20435 20.6839 4.44129 20.1213 3.87868C19.5587 3.31607 18.7956 3 18 3H10C9.20435 3 8.44129 3.31607 7.87868 3.87868C7.31607 4.44129 7 5.20435 7 6Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M17 17V18C17 18.7956 16.6839 19.5587 16.1213 20.1213C15.5587 20.6839 14.7956 21 14 21H6C5.20435 21 4.44129 20.6839 3.87868 20.1213C3.31607 19.5587 3 18.7956 3 18V10C3 9.20435 3.31607 8.44129 3.87868 7.87868C4.44129 7.31607 5.20435 7 6 7H7"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M14.001 12V8"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			class="stroke-current"
			d="M12 9.99902H16"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>

		<defs>
			<clipPath id="clip0_1366_9119">
				<rect fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
