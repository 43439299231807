<script lang="ts" setup />
<template>
	<svg
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			class="stroke-current"
			d="M8.40662 19.0176C7.78817 19.3405 7.02512 19.1008 6.70231 18.4824C6.57317 18.235 6.52959 17.9518 6.57839 17.677L7.25966 13.7721L4.39141 11.0234C3.88655 10.542 3.86758 9.74241 4.34904 9.23756C4.54554 9.03151 4.80588 8.89789 5.08784 8.85837L9.0702 8.2891L10.8681 4.70254C11.1779 4.07746 11.9358 3.82186 12.5608 4.13165C12.8085 4.25437 13.009 4.45492 13.1317 4.70254L14.9296 8.2891L18.912 8.85837C19.6029 8.95521 20.0844 9.59377 19.9876 10.2846C19.9481 10.5666 19.8145 10.827 19.6084 11.0234L16.7402 13.7721L17.4215 17.6778C17.5434 18.3647 17.0855 19.0204 16.3986 19.1424C16.1238 19.1912 15.8406 19.1476 15.5932 19.0185L11.9999 17.1616L8.40662 19.0176Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
